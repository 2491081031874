import { useState } from 'react';
import Mixpanel from '../../../../../../../shared/utils/mixpanel/utils';
import MixpanelEvents from '../../../../../../../shared/utils/mixpanel/events';
import { Button, IconButton } from '@raptormaps/button';
import { TextInput } from '@raptormaps/text-input';
import { Icon } from '@raptormaps/icon';
import './DownloadModal.css';

function DownloadModal({ handleDownload, droneType, disabled }) {
  const [modal, setModal] = useState<boolean>(false);
  const [filename, setFilename] = useState<string>('');

  const handleClickDownload = async () => {
    await handleDownload(droneType, filename);
    toggleModal();
  };

  const handleFileName = e => {
    setFilename(e.target.value);
  };

  // sets the modal as visible or not
  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="download-modal-button-container">
        <Button
          className="modal-trigger"
          variant="primary"
          flex
          onClick={toggleModal}
          disabled={disabled}
        >
          Download Mission
        </Button>
      </div>

      {modal ? (
        <div
          className="modal"
          onKeyDownCapture={e => {
            if (e.key === 'Enter') {
              handleClickDownload();
            }
          }}
        >
          <div onClick={toggleModal} className="overlay"></div>
          <div id="download-modal" className="modal-content">
            <div className="modal-heading">
              <h3>Download Mission</h3>
              <IconButton
                size="medium"
                onClick={() => {
                  toggleModal();
                  Mixpanel.track(MixpanelEvents.PlanDownloadMissionModal);
                }}
                type={'button'}
                variant="tertiary"
                aria-label="close"
                icon="Xmark"
              />
            </div>
            <div className="modal-buttons">
              <p role="presentation" className="sub-heading"></p>
              <div className="download-form">
                <TextInput
                  inputSize="medium"
                  inputStyle="white-box"
                  inputType="text"
                  onChange={e => handleFileName(e)}
                  placeholder="solar_farm_x_block_y_section_z"
                  helperText={droneType === 'Manned Airplane' ? '.KML' : '.KMZ'}
                  value={filename ?? ''}
                  required
                />
                <div className="modal-directions">
                  <h4>
                    <Icon
                      className="icon-label-icon"
                      icon="CircleInfo"
                      size={'large'}
                    />{' '}
                    Utilizing Two Essential Files
                  </h4>
                  <p>
                    Next you will be prompted to save two files. One is to load
                    into your drone and the second is a .json file that saves
                    your mission parameters in case you want to re-load it into
                    Raptor Flight later.
                  </p>
                </div>

                <div className="modal-controls">
                  <Button
                    size="small"
                    onClick={toggleModal}
                    type={'button'}
                    variant="tertiary"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={filename.length < 1 || disabled}
                    size="small"
                    onClick={handleClickDownload}
                  >
                    Download
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DownloadModal;
