import { missionFileVersion } from '@/shared/constants/missionsConstants';
import {
  CalibrationObject,
  IntervalometerObject,
  UserUnitType,
  DroneType,
  FlightModeType,
  CameraType,
  OverlapModeType,
  WaypointTurnModes,
  SpeedControlModeType,
  CameraAngleMode,
} from '@/shared/types/missions.d';

import { FLIGHT_MODE } from './MissionPlanningSidebar/components/MissionParameters/missionParametersConstants';

export const VERSION = missionFileVersion;
export const METERS_TO_FEET = 3.28084;
export const MS_TO_MPH = 2.23694; // m/s to mph
const RGB_WIDE = 'RGB Wide';
const THERMAL = 'Thermal';

export const DEFAULT_FLIGHT_MISSION_FILE = {
  mission: {
    flightMode: 'Standard' as FlightModeType, // FlightMode.standard
    drone: 'M3T' as DroneType, //DroneType.m3t,
    altitude: 41.5, // meters
    safeTakeoffAltitude: 50, // meters
    altitudeOffset: 0, // meters - added v2.1.0 optional
    altitudeOffsetBool: false, // added v2.1.0 optional
    cameraAngle: -90, // -180 to 180 deg (with 0 being horizon, -90 being nadir)
    cameraAngleMode: CameraAngleMode.Manual, // manual or follow_tracker
    cameraInterval: 2.0, // seconds
    flightAngle: 90, // 0 to 360 deg (heading)
    flightMap: null, // polygon or perimeter
    flightSpeed: 5.71, // meters/second
    frontOverlap: 0.7,
    frontSpacing: 11.419663384522305,
    mapCenter: null,
    mapZoom: 15, // 0 to 23?
    overlapMode: 'Overlap' as OverlapModeType, // OverlapType.overlap
    sensor: {
      fieldOfViewHeight: 40.68, // currently fov vertical
      fieldOfViewWidth: 49.27437, // currently fov horizontal
      name: THERMAL as CameraType, // CameraType.thermal, // currently CameraType
    },
    sideOverlap: 0.2,
    sideSpacing: 24.614818476019778,
    solarFarm: null,
    speedControlMode: 'Camera Interval' as SpeedControlModeType, // SpeedControlModeType.flight,
    takeOffPointCenter: null, //
    terrainFollowBool: false,
    userUnits: 'Metric' as UserUnitType, // UnitType.metric,
  },
  version: VERSION,
  waypoints: null,
};

export const DEFAULT_CALIBRATION: CalibrationObject = {
  active: false,
  rhumbBearing: 0,
  rhumbDistance: 0,
  nonCalibratedFlightPath: null,
};

export const DEFAULT_INTERVALOMETER: IntervalometerObject = {
  intervalometerBool: false,
  waypointReductionBool: false,
  actionTriggerParam: 0,
};

export type DroneCameraTypeDefaults = {
  [key in DroneType]: CameraTypeDefaults;
};

interface DroneCameraDefaultFOV {
  fieldOfViewHorizontal: number;
  fieldOfViewVertical: number;
}
type CameraTypeDefaults = {
  [key in CameraType]: Partial<DroneCameraDefaultFOV>;
};

export const DRONE_CAMERA_DEFAULTS: DroneCameraTypeDefaults = {
  M3T: {
    Thermal: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    'RGB Wide': {
      fieldOfViewHorizontal: 71.619,
      fieldOfViewVertical: 56.7227,
    },
  },
  M30T: {
    Thermal: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    'RGB Wide': {
      fieldOfViewHorizontal: 71.61972,
      fieldOfViewVertical: 56.72282,
    },
  },
  Custom: {
    Thermal: {},
    'RGB Wide': {},
  },
  'Manned Airplane': {
    Thermal: {
      fieldOfViewHorizontal: 17.46,
      fieldOfViewVertical: 14.01,
    },
    'RGB Wide': {
      fieldOfViewHorizontal: 23.86,
      fieldOfViewVertical: 16.04,
    },
  },

  'M30T Dock': {
    Thermal: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    'RGB Wide': {
      fieldOfViewHorizontal: 71.61972,
      fieldOfViewVertical: 56.7227,
    },
  },

  'M3TD Dock': {
    Thermal: {
      fieldOfViewHorizontal: 49.27437,
      fieldOfViewVertical: 40.68,
    },
    'RGB Wide': {
      fieldOfViewHorizontal: 69.63177281,
      fieldOfViewVertical: 55.09057208,
    },
  },
  'Husky Observer': {
    Thermal: {
      fieldOfViewHorizontal: 17.46,
      fieldOfViewVertical: 14.01,
    },
    'RGB Wide': {
      fieldOfViewHorizontal: 23.86,
      fieldOfViewVertical: 16.04,
    },
  },
};

type FlightModeDefaults = {
  [key in FlightModeType]: {
    altitude?: number;
    cameraAngle?: number;
    cameraAngleMode: CameraAngleMode;
    cameraInterval?: number;
    cameraType?: CameraType;
    drone?: DroneType;
    DRONE_CAMERA_DEFAULTS?: DroneCameraTypeDefaults;
    flightAngle?: number;
    flightSpeed?: number;
    frontOverlap?: number;
    sideOverlap?: number;
    speedControlMode?: SpeedControlModeType;
  };
};

export const FLIGHT_MODE_DEFAULTS: FlightModeDefaults = {
  Perimeter: {
    altitude: 50,
    cameraAngle: -45,
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: RGB_WIDE,
    flightSpeed: 5.5,
    frontOverlap: 0.7,
    speedControlMode: 'Flight Speed',
  },
  Oblique: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: THERMAL,
    frontOverlap: 0.8,
    sideOverlap: 0.2,
    altitude: 122,
    cameraAngle: -25,
    speedControlMode: 'Flight Speed',
    flightSpeed: 13.5,
  },
  Standard: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: THERMAL,
    frontOverlap: 0.7,
    sideOverlap: 0.2,
    altitude: 41.5,
    cameraAngle: -90,
    speedControlMode: 'Camera Interval',
    cameraInterval: 2,
  },
  Comprehensive: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: THERMAL,
    frontOverlap: 0.7,
    sideOverlap: 0.2,
    altitude: 22.5,
    cameraAngle: -90,
    speedControlMode: 'Camera Interval',
    cameraInterval: 2,
  },
  CABLines: {
    cameraAngleMode: CameraAngleMode.Manual,
    drone: 'M3T',
    cameraType: RGB_WIDE,
    ...DRONE_CAMERA_DEFAULTS['M3T'][RGB_WIDE],
    speedControlMode: 'Camera Interval',
    frontOverlap: 0.5,
    altitude: 4.9,
    flightAngle: 90,
    cameraInterval: 2,
    cameraAngle: -40,
  },
  IIMode: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: RGB_WIDE,
    drone: 'M3T',
    ...DRONE_CAMERA_DEFAULTS['M3T'][RGB_WIDE],
    speedControlMode: 'Camera Interval',
    frontOverlap: 0.7,
    sideOverlap: 0.7,
    altitude: 41.5,
    cameraInterval: 2,
    cameraAngle: -90,
  },
  Wiring: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: RGB_WIDE,
    drone: 'M3T',
    ...DRONE_CAMERA_DEFAULTS['M3T'][RGB_WIDE],
    frontOverlap: 0.35,
    sideOverlap: 0.11,
    altitude: 6.15,
    flightAngle: 90,
    flightSpeed: 3.2,
    cameraAngle: -45,
    speedControlMode: 'Flight Speed',
  },
  Custom: {
    cameraAngleMode: CameraAngleMode.Manual,
  },
  Overview: {
    cameraAngleMode: CameraAngleMode.Manual,
  },
  Airplane: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: THERMAL,
    ...DRONE_CAMERA_DEFAULTS['Manned Airplane'][THERMAL],
    frontOverlap: 0.7,
    sideOverlap: 0.2,
    altitude: 457.2,
    cameraAngle: -90,
    speedControlMode: 'Camera Interval',
    cameraInterval: 2,
  },
  'Ground Robot': {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: THERMAL,
    ...DRONE_CAMERA_DEFAULTS['Husky Observer'][THERMAL],
    frontOverlap: 0.7,
    sideOverlap: 0.2,
    altitude: 457.2,
    flightSpeed: 1.0,
    cameraAngle: -90,
    speedControlMode: 'Flight Speed',
  },
  [FLIGHT_MODE.ORTHO]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: RGB_WIDE,
    frontOverlap: 0.7,
    sideOverlap: 0.7,
    altitude: 91.44,
    cameraAngle: -90,
    speedControlMode: 'Camera Interval',
    cameraInterval: 2,
  },
  [FLIGHT_MODE.SQUARE_ORBITAL]: {
    cameraAngleMode: CameraAngleMode.Manual,
    cameraType: RGB_WIDE,
    frontOverlap: 0.2,
    sideOverlap: 0.7,
    altitude: 15.24,
    cameraAngle: -30,
    speedControlMode: 'Camera Interval',
    cameraInterval: 2,
  },
};

type FlightModeToWaypointTurnMode = {
  [key in FlightModeType]: WaypointTurnModes;
};
export const FLIGHT_MODE_TO_WAYPOINT_TURN_MODE: FlightModeToWaypointTurnMode = {
  Standard: 'coordinateTurn',
  Comprehensive: 'coordinateTurn',
  Overview: 'coordinateTurn',
  Oblique: 'coordinateTurn',
  Perimeter: 'coordinateTurn',
  Wiring: 'coordinateTurn',
  CABLines: 'coordinateTurn',
  IIMode: 'toPointAndStopWithContinuityCurvature',
  Custom: 'coordinateTurn',
  Airplane: 'coordinateTurn',
  'Ground Robot': 'coordinateTurn',
  [FLIGHT_MODE.ORTHO]: 'coordinateTurn',
  [FLIGHT_MODE.SQUARE_ORBITAL]: 'toPointAndPassWithContinuityCurvature',
};

export const NUMBER_INPUT_STEP = 0.00001;

export const PERCENT_INPUT_STEP = 1;
