// React
import { useState } from 'react';

// Mapbox
import mapboxgl from 'mapbox-gl';

// Mixpanel
import Mixpanel from '../../utils/mixpanel/utils';
import MixpanelEvents from '../../utils/mixpanel/events';

// RaptorUi
import { Slider } from '@raptormaps/slider';
import { IconButton, IconButtonProps } from '@raptormaps/button';
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';

// Components
import AsBuiltController from './AsBuiltController';

// Styled Components
import { SectionCollapsible } from '../../../pages/MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/MissionParametersSection.styles';

// Hooks
import { useGetAsBuilt } from '../../hooks/useAsBuilt';

// Customer Api Types
import { TileMap } from '@raptormaps/customer-farm-api-client-ts';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';

// Constants
import * as mapboxFarms from '../Map/farms';
import { AS_BUILT_SOURCE_ID } from '../Map/sources/TilesetSources';

interface BackgroundSectionProps {
  map: mapboxgl.Map;
  slider?: number;
  setSlider?: React.Dispatch<React.SetStateAction<number>>;
  solarFarm: SolarFarmResponse;
  useDroneIcon?: boolean;
}

const BackgroundSection = ({
  map,
  slider,
  setSlider,
  solarFarm,
  useDroneIcon = true,
}: BackgroundSectionProps) => {
  const hasCustomTileSets = mapboxFarms?.getSolarFarmById[solarFarm?.id];

  const [icon, setIcon] = useState<IconButtonProps['icon']>('Eye');
  const { data: asBuilts } = useGetAsBuilt(solarFarm?.id);

  const handleAddRemoveDroneMarker = () => {
    Mixpanel.track(MixpanelEvents.PlanWaypointIconToggle);
    if (map.getPaintProperty('waypoints', 'icon-opacity') == 1) {
      map.setPaintProperty('waypoints', 'icon-opacity', 0);
      setIcon('EyeSlash');
    } else {
      map.setPaintProperty('waypoints', 'icon-opacity', 1);
      setIcon('Eye');
    }
  };

  return (
    <SectionCollapsible
      title="Background"
      id="background-section"
      iconColor="neutral_900"
      variant="ghost"
    >
      <Stack>
        {useDroneIcon ? (
          <Row align="center" justify="space-between">
            <Text htmlFor="as-build-opactiy">Drone Icons</Text>
            <IconButton
              aria-label="toggle drone icons"
              icon={icon}
              size="medium"
              variant="secondary"
              onClick={handleAddRemoveDroneMarker}
            />
          </Row>
        ) : (
          <></>
        )}

        {/* If there are tiles sets coming from farm builder and there are no custom as_builts hosted on */}
        {asBuilts?.length && !hasCustomTileSets?.sources[AS_BUILT_SOURCE_ID] ? (
          asBuilts.map((asBuilt: TileMap) => (
            <AsBuiltController map={map} key={asBuilt.id} asBuilt={asBuilt} />
          ))
        ) : (
          <Stack gutter="xl">
            <Row align="center" justify="space-between">
              <Text variant="label_large" htmlFor="as-build-opactiy">
                As-Built Opacity
              </Text>
              {slider}%
            </Row>
            <Slider
              value={[slider]}
              max={100}
              min={0}
              name="as-built-opacity"
              onChange={e => {
                setSlider(e[0]);
              }}
              step={1}
              stepsBetweenThumbs={0}
            />
          </Stack>
        )}
      </Stack>
    </SectionCollapsible>
  );
};

export default BackgroundSection;
