import { useEffect, Dispatch } from 'react';

// Map
import mapboxgl from 'mapbox-gl';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  MapboxStyles,
} from '../../../shared/constants/mapConstants';
import { initDigitalTwinSources } from '../../../shared/components/Map/sources';
import { useRenderAsBuilts } from '../../../shared/hooks/useAsBuilt';
import { useEquipmentSourcesOnMap } from '../../../shared/hooks/useEquipment';

// Styles
import './SiteOverviewMap.css';

// Types
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';

// Constants
import { MAPBOX_ACCESS_TOKEN } from '../../../shared/constants/mapConstants';
import { initDigitalTwinLayers } from '../../../shared/components/Map/layers';
import { initEvents } from '../../../shared/components/Map/EventListeners/InitEvents';
import {
  initTerrainSource,
  initNavControl,
} from '../../../shared/components/Map/utils';

interface SiteOverviewMapProps {
  map?: mapboxgl.Map;
  solarFarm?: SolarFarmResponse;
  setMap: Dispatch<mapboxgl.Map>;
  activeRowId: number;
  setActiveRowId: Dispatch<number>;
}

const SiteOverviewMap = ({
  map,
  solarFarm,
  setMap,
  activeRowId,
  setActiveRowId,
}: SiteOverviewMapProps) => {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

  useEquipmentSourcesOnMap(map, solarFarm, activeRowId);
  useRenderAsBuilts(map, solarFarm);

  useEffect(() => {
    if (map) return;

    const center = solarFarm
      ? { lat: solarFarm.latitude, lng: solarFarm.longitude }
      : DEFAULT_MAP_CENTER;

    const newMap = new mapboxgl.Map({
      container: 'map-container',
      style: MapboxStyles.StreetsV12,
      center,
      zoom: DEFAULT_ZOOM,
    });

    initNavControl(newMap);

    newMap.on('load', () => {
      initDigitalTwinSources(newMap);
      initDigitalTwinLayers(newMap);
      initEvents(newMap, setActiveRowId, null /* draw tool */);
      setMap(newMap);
    });

    newMap.on('style.load', () => {
      initTerrainSource(newMap);
    });

    return () => {
      newMap.remove();
      setMap(null);
    };
  }, []);

  useEffect(() => {
    if (!map || !solarFarm) return;
    map.setCenter([solarFarm.longitude, solarFarm.latitude]);
  }, [map, solarFarm]);

  return (
    <div className="map">
      <div id="map-container" className="map-container" />
    </div>
  );
};

export default SiteOverviewMap;
