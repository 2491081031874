import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { RaptorUIProvider } from '@raptormaps/raptor-ui-provider';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppContextProvider from './shared/context/AppContext';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbookInstance } from './shared/utils/GrowthbookUtils';
import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<App />} />),
);

root.render(
  <React.StrictMode>
    <GrowthBookProvider growthbook={growthbookInstance}>
      <Auth0Provider
        domain={window.REACT_APP_AUTH0_DOMAIN}
        clientId={window.REACT_APP_AUTH0_CLIENTID}
        useRefreshTokens={false}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: window.REACT_APP_AUTH0_CONFIG.audience,
          scope: '',
        }}
      >
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <RaptorUIProvider>
              <RouterProvider router={router} />
            </RaptorUIProvider>
          </AppContextProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </GrowthBookProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
