//external dependancies
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  MissionTasksApi,
  MissionTaskOverviewResponse,
  ResponseError,
} from '@raptormaps/raptor-flight-client-ts';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';

export const GET_MISSION_TASKS_PAGINATION_LIMIT = 20;

export const useGetMissionTasks = (
  solarFarmId?: number,
  nameSearchTerm = '',
  limit?: number,
  offset?: number,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionTasksApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  const query = useQuery({
    queryKey: [
      'mission_tasks',
      orgId,
      solarFarmId,
      nameSearchTerm,
      offset,
      limit,
    ],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      return await api.getMissionTasksWithMissionsBySolarFarmIdV1MissionTasksGet(
        {
          solarFarmId,
          orgId,
          limit: limit ? limit : GET_MISSION_TASKS_PAGINATION_LIMIT,
          offset: offset ? offset : 0,
          nameSearchTerm: nameSearchTerm ? nameSearchTerm : undefined,
        },
      );
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const cachedQueries = queryClient.getQueriesData<
    Array<MissionTaskOverviewResponse>
  >(['mission_tasks', orgId, solarFarmId, nameSearchTerm]);

  const missionTasks = cachedQueries?.reduce((acc, queryData) => {
    if (!queryData) return acc;
    const [key, data] = queryData;
    const offsetKey = key[4] as number;

    if (!data || offsetKey > offset) return acc;
    return [...acc, ...data];
  }, []);

  return {
    ...query,
    data: {
      missionTasks,
      total: query?.data?.length,
    },
  };
};

export const useDeleteMissionTask = (solarFarmId?: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionTasksApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<number, ResponseError, number>({
    mutationFn: async (missionTaskId: number) => {
      return await api.deleteMissionTaskByIdV1MissionTasksIdDelete({
        id: missionTaskId,
        solarFarmId,
        orgId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mission_tasks', orgId, solarFarmId],
      });
    },
  });
};
