import {
  useState,
  MutableRefObject,
  useRef,
  useEffect,
  useCallback,
} from 'react';

// Hooks
import useDolby from '../../shared/hooks/useDolby';

// Styled Components
import { Stack } from '@raptormaps/layout';
import { useToast } from '@raptormaps/toast';
import CameraControls from './CameraControls';
import { LiveStreamBackButtonContainer } from './LiveStream.styled';
import LiveStreamModal from './LiveStreamModal';
import { ConfirmationModal } from '@/shared/components/ConfirmationModal/ConfirmationModal';
import { Button } from '@raptormaps/button';

// Router
import { useNavigate, useBlocker } from 'react-router-dom';

// Mock Data
import {
  useStartLiveStream,
  useStopLiveStream,
  useGetStreamingLenses,
  useChangeLens,
  useChangeVideoQuality,
} from '../../shared/hooks/useLiveStreamMock';
import {
  CameraLens,
  ChangeVideoQualityRequest,
  LiveStreamStatus,
  StartLiveStreamResponse,
  VideoLensType,
  VideoQuality,
  parseVideoLensType,
  VideoPlayerStatus,
} from '../../shared/constants/mockDeviceData';

const TOAST_DURATION = 3000;
const ERROR_TOAST_DURATION = 6000;

const LiveStream = () => {
  const [currentLens, setCurrentLens] = useState<VideoLensType | null>(null);
  const [currentVideoQuality, setCurrentVideoQuality] =
    useState<VideoQuality | null>(null);
  const [videoPlayerStatus, setVideoPlayerStatus] = useState<VideoPlayerStatus>(
    VideoPlayerStatus.loading,
  );

  // Hooks
  const { useSubscribeToDolbyStream, useCloseDolbyStream } = useDolby(true);

  // Refs
  const videoRef: MutableRefObject<HTMLVideoElement> = useRef(null);
  const inactiveStreamToastTriggeredRef = useRef(false);

  // Toast
  const toast = useToast();

  // Router
  const navigate = useNavigate();
  const shouldBlock = useCallback(() => {
    return true;
  }, []);
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    // Persists loading state between sucessful dolby connection and video playing
    if (videoRef?.current) {
      if (videoRef.current.getAttribute('listener') !== 'true ') {
        videoRef.current.addEventListener('playing', () => {
          setVideoPlayerStatus(VideoPlayerStatus.playing);
        });
      }
    } else {
      setVideoPlayerStatus(VideoPlayerStatus.stopped);
    }

    return () => {
      if (videoRef?.current) {
        videoRef.current.removeEventListener('playing', () => {
          setVideoPlayerStatus(VideoPlayerStatus.stopped);
        });
      }
    };
  }, [videoRef]);

  useEffect(() => {
    // Enforces that the toast is only triggered once
    if (inactiveStreamToastTriggeredRef.current) {
      const toastTriggeredTimeout = setTimeout(() => {
        inactiveStreamToastTriggeredRef.current = false;
      }, TOAST_DURATION);
      return () => {
        clearTimeout(toastTriggeredTimeout);
      };
    }
  }, [inactiveStreamToastTriggeredRef.current]);

  // Mutations
  const stopLivestreamMutation = useStopLiveStream();
  const changeLensMutation = useChangeLens(LiveStreamStatus.Success);
  const changeVideoQualityMutation = useChangeVideoQuality(
    LiveStreamStatus.Success,
  );
  const closeDolbyStreamMutation = useCloseDolbyStream();

  const handleStreamConnectionError = () => {
    toast.error(
      'Failed to connect to live stream. Stream will continue attempting to reconnect in the background.',
      {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      },
    );
  };

  const onStartLiveStreamSuccess = (
    livestreamResponse: StartLiveStreamResponse,
  ) => {
    const initialCameraLens = parseVideoLensType(livestreamResponse.videoId);
    setCurrentLens(initialCameraLens);
    setCurrentVideoQuality(livestreamResponse.videoQuality);
  };

  const onLensChange = async (lens: CameraLens) => {
    if (lens.videoLensType === currentLens) return;
    try {
      const updatedLens: CameraLens =
        await changeLensMutation.mutateAsync(lens);
      setCurrentLens(updatedLens.videoLensType);
    } catch (err) {
      toast.error('Error changing lens. Please try again.', {
        duration: TOAST_DURATION,
        dismissable: true,
      });
    }
  };

  const onVideoQualityChange = async (
    qualityRequest: ChangeVideoQualityRequest,
  ) => {
    if (qualityRequest.videoQuality === currentVideoQuality) return;
    try {
      await changeVideoQualityMutation.mutateAsync(qualityRequest);
      setCurrentVideoQuality(qualityRequest.videoQuality);
    } catch (error) {
      toast.error('Error changing video quality. Please try again.', {
        duration: TOAST_DURATION,
        dismissable: true,
      });
    }
  };

  const {
    data: livestreamResponse,
    isLoading: liveStreamLoading,
    isError: startLiveStreamError,
  } = useStartLiveStream(
    LiveStreamStatus.Success,
    onStartLiveStreamSuccess,
    handleStreamConnectionError,
  );

  const { data: availableLenses, isLoading: lensesLoading } =
    useGetStreamingLenses(LiveStreamStatus.Success, !!livestreamResponse);

  const handleInactiveDolbyStream = () => {
    if (inactiveStreamToastTriggeredRef.current) return;
    toast.error('Error: The live stream was unexpectedly interrupted.', {
      duration: TOAST_DURATION,
      dismissable: true,
    });
    inactiveStreamToastTriggeredRef.current = true;
  };

  const {
    isLoading: dolbySubscribeToStreamLoading,
    isError: dolbySubscribeToStreamError,
  } = useSubscribeToDolbyStream(
    videoRef.current,
    !!livestreamResponse && !!videoRef.current,
    handleInactiveDolbyStream,
    handleStreamConnectionError,
  );

  const handleGoBack = async () => {
    try {
      await closeDolbyStreamMutation.mutate();
      await stopLivestreamMutation.mutate(LiveStreamStatus.Success);
    } catch (error) {
      toast.error(
        'Error stopping live stream. Live stream connection could not be severed.',
      );
    }
  };

  const shouldDisplayLoadingModal = (): boolean => {
    if (liveStreamLoading || dolbySubscribeToStreamLoading) {
      return true;
    }

    if (dolbySubscribeToStreamError || startLiveStreamError) {
      return false;
    }

    if (videoPlayerStatus === VideoPlayerStatus.loading) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Stack
        justify={'space-around'}
        style={{ width: '100%', marginTop: -2 }}
        id="live-stream-container"
      >
        <LiveStreamBackButtonContainer>
          <Button
            variant="secondary"
            icon={'ArrowLeft'}
            data-testid={'live-stream-back-button'}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </LiveStreamBackButtonContainer>
        {livestreamResponse && availableLenses && (
          <CameraControls
            lensesLoading={lensesLoading}
            availableLenses={availableLenses}
            onLensChange={onLensChange}
            currentLens={currentLens}
            videoId={livestreamResponse?.videoId ?? ''}
            onVideoQualityChange={onVideoQualityChange}
            currentVideoQuality={currentVideoQuality}
            lensIsChanging={changeLensMutation.isLoading}
            videoQualityIsChanging={changeVideoQualityMutation.isLoading}
          />
        )}
        <video
          ref={videoRef}
          autoPlay
          playsInline
          width="100%"
          height="100%"
          muted
        />
      </Stack>

      <ConfirmationModal
        open={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        handleCancel={() => blocker.reset()}
        handleConfirm={() => {
          handleGoBack();
          blocker.proceed();
        }}
        title="End Live Stream Connection?"
        description="Are you sure you want to terminate the live stream connection? You will need to restart the connection to view the live stream again."
        confirmText="End Live Stream"
        closeText="Cancel"
      />
      <LiveStreamModal open={shouldDisplayLoadingModal()} />
    </>
  );
};

export default LiveStream;
