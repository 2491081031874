import { useState, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import { debounce } from 'lodash';
import * as turf from '@turf/helpers';
import { FeatureCollection, Point } from '@turf/helpers';
import { Button } from '@raptormaps/button';
import { SolarFarm } from '../../shared/types/missions';
import SolarSiteCard from './components/SolarSiteCard';
import { SiteListSidebar, StyledSolarSiteSearch } from './SolarSiteList.styles';
import Map from '../../shared/components/Map/Map';
import { useNavigate, createSearchParams } from 'react-router-dom';
import {
  useSolarFarms,
  GET_SOLAR_FARMS_PAGINATION_LIMIT,
} from '../../shared/hooks/useSolarFarms';

const SiteList = () => {
  const navigate = useNavigate();
  const [map, setMap] = useState<mapboxgl.Map>(null);
  const [solarFarmQuery, setSolarFarmQuery] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const { solarFarms, isLoading: solarFarmsLoading } = useSolarFarms(
    solarFarmQuery,
    offset,
  );

  const handleChangeSolarFarm = (solarFarmId?: number) => {
    const updatedSearchParams = solarFarmId
      ? { solar_farm_id: solarFarmId.toString() }
      : {};

    navigate({
      pathname: `/plan`,
      search: createSearchParams(updatedSearchParams).toString(),
    });
  };

  const handleInputChange = debounce((query: string) => {
    setSolarFarmQuery(query);
    setOffset(0);
  }, 1000);

  const renderSolarFarmArray = () => {
    const filteredSolarFarms = solarFarmQuery
      ? solarFarms?.filter(solarFarm =>
          solarFarm.name.toLowerCase().includes(solarFarmQuery?.toLowerCase()),
        )
      : solarFarms;

    if (solarFarmsLoading && !offset) {
      return [{ name: 'Fetching results...', id: -1 }];
    } else if (filteredSolarFarms?.length > 0) {
      return filteredSolarFarms.map((solarSite: SolarFarm) => solarSite);
    } else {
      return [{ name: 'No results found.', id: -1 }];
    }
  };

  const markersGeoJson: FeatureCollection<Point> | undefined = useMemo(() => {
    if (!solarFarms?.length || location.pathname !== '/') return null;
    else {
      const features = solarFarms.map((solarFarm: SolarFarm) => {
        const point = turf.point([solarFarm.longitude, solarFarm.latitude]);
        point.properties = {
          name: solarFarm.name,
          id: solarFarm.id,
        };
        return point;
      });
      return turf.featureCollection(features);
    }
  }, [solarFarms]);

  return (
    <>
      <SiteListSidebar>
        <h2> Solar Sites </h2>
        <StyledSolarSiteSearch
          id={'solarFarmSearch'}
          className="solar-farm-search"
          inputSize="medium"
          inputStyle="grey-box"
          placeholder="Search"
          onChange={e => handleInputChange(e.target.value)}
        />

        {renderSolarFarmArray().map((solarFarm: SolarFarm) => (
          <SolarSiteCard
            key={solarFarm.id}
            solarFarm={solarFarm}
            handleSelect={(option: SolarFarm) =>
              handleChangeSolarFarm(option?.id ? option.id : null)
            }
            isLoading={solarFarmsLoading}
          />
        ))}
        {!(solarFarms?.length % 50) && !solarFarmsLoading && (
          <Button
            variant={'secondary'}
            style={{ margin: '5px' }}
            onClick={() => {
              setOffset(offset + GET_SOLAR_FARMS_PAGINATION_LIMIT);
            }}
          >
            {' '}
            Load More{' '}
          </Button>
        )}
      </SiteListSidebar>

      <Map
        map={map}
        setMap={setMap}
        markersGeoJson={markersGeoJson}
        handleClickPopup={handleChangeSolarFarm}
      />
    </>
  );
};

export default SiteList;
