import { FLIGHT_MODE } from '@/pages/MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/missionParametersConstants';
import {
  FlightModeType,
  SpeedControlModeType,
  DroneType,
  OverlapModeType,
  CameraType,
  UserUnitType,
  CameraAngleMode,
} from '../types/missions.d';

// update each time there is a new version
export const missionFileVersion = '3.0.0';

export const droneTypes: DroneType[] = [
  'M3T',
  'M30T',
  'Manned Airplane',
  'M30T Dock',
  'M3TD Dock',
  'Husky Observer',
];
export const flightModes: FlightModeType[] = [
  'Standard',
  'Comprehensive',
  'Oblique',
  'Perimeter',
  'Wiring',
  'CABLines',
  'IIMode',
  'Custom',
  'Airplane',
  FLIGHT_MODE.GROUND_ROBOT,
  FLIGHT_MODE.ORTHO,
  FLIGHT_MODE.SQUARE_ORBITAL,
];
export const overlapModes: OverlapModeType[] = ['Overlap', 'Spacing'];
export const sensorTypes: CameraType[] = ['RGB Wide', 'Thermal'];
export const SPEED_CONTROLS: SpeedControlModeType[] = [
  'Camera Interval',
  'Flight Speed',
];

export const USER_UNITS: UserUnitType[] = ['Metric', 'Imperial'];

// Display name dictionaries
export const DISPLAY_NAME_TO_DRONE_TYPE: { [key: string]: DroneType } = {
  'DJI - M3T': 'M3T',
  'DJI - M30T': 'M30T',
  'Manned Airplane': 'Manned Airplane',
  'DJI - M30T Dock': 'M30T Dock',
  'DJI - M3TD Dock': 'M3TD Dock',
  'Husky Observer': 'Husky Observer',
};

export const DRONE_TYPE_TO_DISPLAY_NAME: {
  [key in DroneType]: string;
} = {
  M3T: 'DJI - M3T',
  M30T: 'DJI - M30T',
  Custom: 'Custom',
  'Manned Airplane': 'Manned Airplane',
  'M30T Dock': 'DJI - M30T Dock',
  'M3TD Dock': 'DJI - M3TD Dock',
  'Husky Observer': 'Husky Observer',
};

export const CAMERA_ANGLE_MODE_TO_DISPLAY_NAME: {
  [key in CameraAngleMode]: string;
} = {
  [CameraAngleMode.Manual]: 'Manual',
  [CameraAngleMode.FollowTracker]: 'Follow Tracker',
};

export const DISPLAY_NAME_TO_FLIGHT_MODE: { [key: string]: FlightModeType } = {
  Standard: 'Standard',
  Comprehensive: 'Comprehensive',
  Oblique: 'Oblique',
  Perimeter: 'Perimeter',
  Wiring: 'Wiring',
  CABLines: 'CABLines',
  Instant: 'IIMode',
  Custom: 'Custom',
  Airplane: 'Airplane',
  Overview: 'Overview',
  'Ground Robot': FLIGHT_MODE.GROUND_ROBOT,
  Ortho: FLIGHT_MODE.ORTHO,
  'Square Orbital': FLIGHT_MODE.SQUARE_ORBITAL,
};

export const FLIGHT_MODE_TO_DISPLAY_NAME: {
  [key in FlightModeType]: string;
} = {
  Standard: 'Standard',
  Comprehensive: 'Comprehensive',
  Oblique: 'Oblique',
  Perimeter: 'Perimeter',
  Wiring: 'Wiring',
  CABLines: 'CABLines',
  IIMode: 'Instant',
  Custom: 'Custom',
  Airplane: 'Airplane',
  Overview: 'Overview',
  [FLIGHT_MODE.GROUND_ROBOT]: 'Ground Robot',
  [FLIGHT_MODE.ORTHO]: 'Ortho',
  [FLIGHT_MODE.SQUARE_ORBITAL]: 'Square Orbital',
};

export const CAMERA_TYPE_TO_DISPLAY_NAME: {
  [key in CameraType]: string;
} = {
  'RGB Wide': 'RGB Wide',
  Thermal: 'IR',
};

export const DEFAULT_SPEED_SETTINGS = {
  Standard: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[0],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  Comprehensive: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[0],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  Oblique: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[1],
    },
    {
      // sets the default speed based on the above control mode
      name: 'flightSpeed',
      value: 13.5,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'cameraInterval',
      value: 2.608,
    },
  ],
  Perimeter: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[1],
    },
    {
      // sets the default speed based on the above control mode
      name: 'flightSpeed',
      value: 5.5,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'cameraInterval',
      value: 3.935,
    },
  ],
  Wiring: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[1],
    },
    {
      // sets the default speed based on the above control mode
      name: 'flightSpeed',
      value: 3.2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'cameraInterval',
      value: 1.803,
    },
  ],
  IIMode: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[0],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  CABLines: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[0],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  Custom: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[0],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 5.71,
    },
  ],
  'Ground Robot': [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[1],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 1.0,
    },
  ],
  [FLIGHT_MODE.ORTHO]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[0],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
  ],
  [FLIGHT_MODE.SQUARE_ORBITAL]: [
    {
      //sets the default speed control mode
      name: 'speedControlMode',
      value: SPEED_CONTROLS[0],
    },
    {
      // sets the default speed based on the above control mode
      name: 'cameraInterval',
      value: 2,
    },
    {
      // sets the controlled speed based on the above control mode
      name: 'flightSpeed',
      value: 2.097,
    },
  ],
};

export const ACCEPTED_ERRORS = ['missionName', 'terrainFollowBool'];
