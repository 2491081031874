// External Dependancies
import styled from 'styled-components';
// raptor UI
import { Row } from '@raptormaps/layout';
import { Pill } from '@raptormaps/pill';
import { Table } from '@raptormaps/table';

// Internal Dependancies
import { MISSION_TASK_STATUS } from './missionTasksTableConstants';

export const MissionTasksTableContainer = styled(Row)`
  height: 100vh;
  width: 93vw;
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  > button,
  nav {
    display: flex;
    justify-content: flex-start;
  }

  .breadcrumb {
    margin-left: -32px;
    margin-bottom: -15px;
  }
`;

export const MissionTaskStatusPill = styled(Pill)`
  ${props =>
    props.taskStatus === MISSION_TASK_STATUS.InProgress &&
    `
    animation: blinker 1s step-start infinite;

    @keyframes blinker {
    50% {
      opacity: 0.5;
    }`}
`;

export const MissionTaskTable = styled(Table)`
  height: 75vh;
  width: 100%;
  margin-bottom: 5vh;
  overflow: auto;

  input {
    width: 200px !important;
  }

  th > div {
    display: flex;
    justify-content: flex start;
  }

  td {
    text-align: left;
  }

  // Execute Time, Status, Mission Type columns
  tbody tr > td:first-child,
  tbody tr > td:nth-child(2),
  tbody tr > td:nth-child(6) {
    width: 90px;
    min-width: 90px;
    white-space: nowrap;
  }

  // Task Name column
  tbody tr > td:nth-child(3) {
    max-width: calc(25vw - 270px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // Drone and Dock columns
  tbody tr > td:nth-child(4),
  tbody tr > td:nth-child(5) {
    max-width: 5vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // Mission Name column
  tbody tr > td:nth-child(7) {
    max-width: 0px; // As best I can tell there just need to be a max width attr to allow the cascading rule to take effect

    > div {
      max-width: 100%;
      margin-right: 15px;

      > span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  // This bolds all the dd/mm/yyyy dates
  tr:has(:nth-child(2):empty) {
    font-weight: 600;
    cursor: disabled;
  }
`;

export const FetchingMessage = styled(Row)`
  margin-top: 5vh;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
`;
