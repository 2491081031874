import { GrowthBook } from '@growthbook/growthbook-react';

// GrowthBook instance
export const growthbookInstance = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: window.REACT_APP_GROWTHBOOK_API_KEY,
  decryptionKey: window.REACT_APP_GROWTHBOOK_DECRYPT_KEY,
  enableDevMode: true,
});

export const GrowthbookFlags = {
  SAFE_TAKEOFF_ALTITUDE: 'safe-takeoff-altitude',
  DOCK_CONT_OP: 'dock-cont-op',
  LIVE_STREAM: 'robotics-live-stream',
  CAMERA_SELECT_WITH_ZOOM: 'show-camera-select-with-zoom',
  ORTHO_V1: 'robotics-ortho-v1',
  MISSION_TASK: 'robotics-mission-tasks',
  ROBOTICS_HUSKY_OBSERVER: 'robotics-husky-observer',
  SQUARE_ORBITAL: 'robotics-square-orbitals-mode',
};
