import styled from 'styled-components';

// RaptorUI
import { Text } from '@raptormaps/text';
import { IconButton } from '@raptormaps/button';

export const InputHelperText = styled(Text)`
  color: ${props => props.color};
  margin-top: -15px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0.25px;
  font-weight: 500;
`;

export const IconButtonAdornment = styled(IconButton)`
  height: 32px;
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: -24px;
  margin-left: -24px;
  margin-right: -55px;
  border-left: none;
  z-index: 1;
  border-radius: 0;
`;
