import {
  ROWS_SOURCE_ID,
  CAB_LINES_SOURCE_ID,
  CAB_COMBINERS_SOURCE_ID,
  INVERTERS_SOURCE_ID,
  INVERTER_BLOCKS_SOURCE_ID,
  INVERTER_BLOCKS_LABEL_SOURCE_ID,
  FENCE_LINES_SOURCE_ID,
  AS_BUILT_SOURCE_ID,
  ROAD_SOURCE_ID,
} from '../sources/TilesetSources';
export interface TilesetLayerSpecification {
  sourceId: string;
  vectorLayer: string;
  type: string;
  paintProps: object;
  layoutPaintProps?: object;
  layout?: object;
}

const getTilesetRowLayer = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: {
      'line-color': '#0a93f5',
    },
    layoutPaintProps: {
      'text-color': '#fc8803',
    },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const getTilesetCablineLayer = (
  sourceId: string,
  vectorLayer: string,
  label = 'cab_name',
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: { 'line-color': '#fc8803' },
    layoutPaintProps: { 'text-color': '#0a93f5' },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const getTilesetFenceLinesLayer = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: { 'line-color': '#ceff1a' },
    layoutPaintProps: { 'text-color': '#ceff1a' },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const getTilesetCabCombinerLayer = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'circle',
    paintProps: { 'circle-color': 'pink' },
    layoutPaintProps: { 'text-color': '#0a93f5' },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const getTilesetInverterPinsLayer = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'circle',
    paintProps: {
      'circle-color': '#fc8803',
    },
    layoutPaintProps: {
      'text-color': '#fc8803',
    },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'top-left',
      'text-justify': 'left',
    },
  };
};
const getTilesetInverterBlocksLayer = (
  sourceId: string,
  vectorLayer: string,
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: {
      'line-color': '#c517ff',
      'line-width': 2,
    },
  };
};
const getTilesetInverterBlocksLabelLayer = (
  sourceId: string,
  vectorLayer: string,
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: {
      'line-color': '#3e424b',
    },
  };
};
const getTilesetAsBuiltLayer = (
  sourceId: string,
  vectorLayer: string,
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'raster',
    paintProps: {
      'raster-opacity': 0.25,
    },
  };
};
const getTilesetRoadsLayer = (
  sourceId: string,
  vectorLayer: string,
): TilesetLayerSpecification => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: {
      'line-color': '#964b00',
    },
  };
};

export const tilesetLayerFunctions = {
  [ROWS_SOURCE_ID]: getTilesetRowLayer,
  [CAB_LINES_SOURCE_ID]: getTilesetCablineLayer,
  [CAB_COMBINERS_SOURCE_ID]: getTilesetCabCombinerLayer,
  [INVERTERS_SOURCE_ID]: getTilesetInverterPinsLayer,
  [INVERTER_BLOCKS_SOURCE_ID]: getTilesetInverterBlocksLayer,
  [INVERTER_BLOCKS_LABEL_SOURCE_ID]: getTilesetInverterBlocksLabelLayer,
  [FENCE_LINES_SOURCE_ID]: getTilesetFenceLinesLayer,
  [AS_BUILT_SOURCE_ID]: getTilesetAsBuiltLayer,
  [ROAD_SOURCE_ID]: getTilesetRoadsLayer,
};
