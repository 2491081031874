import { Modal, ModalContent } from '@raptormaps/modal';
import { ThreeCircles } from 'react-loader-spinner';
import theme from '@raptormaps/theme';
import { Stack, Row } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';

interface LiveStreamModalProps {
  open: boolean;
}
const LiveStreamModal = ({ open }: LiveStreamModalProps) => {
  return (
    <Modal open={open}>
      <ModalContent>
        <Stack justify="center" align="center" style={{ width: '100%' }}>
          <Row justify="center" align="center">
            <ThreeCircles
              height="40"
              width="40"
              color={theme.colors.primary_400}
              visible={true}
              ariaLabel="loading spinner"
            />
          </Row>

          <Row justify="center" align="center">
            <Stack justify="center" align="center">
              <Text align="center" data-testid="live-stream-loading-modal">
                Please do not navigate away from this page.
              </Text>
            </Stack>
          </Row>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default LiveStreamModal;
