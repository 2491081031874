// Mapbox
import mapboxgl from 'mapbox-gl';

// RaptorUI
import { Stack } from '@raptormaps/layout';

// Components
import { SectionCollapsible } from '../../../pages/MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/MissionParametersSection.styles';
import EquipmentSlider from './EquipmentSlider';

// Mapbox Layers
import RowLayers from '../Map/layers/GeospatialRowLayers';
import CombinerLayers from '../Map/layers/CombinerLayers';
import InverterLayers from '../Map/layers/InverterLayers';
import ModuleLayers from '../Map/layers/ModuleLayers';
import PyranometerLayers from '../Map/layers/PyranometerLayers';
import StringLayers from '../Map/layers/StringLayers';

// Hooks
import {
  useGetEquipmentModulesAndStrings,
  useGetEquipmentRows,
  useGetEquipmentCombiners,
  useGetEquipmentInverters,
  useGetEquipmentPyranometers,
} from '../../hooks/useEquipment';

// Types
import { EquipmentLayerData } from './digitalTwin.d';

// Customer Api Types
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';

// Constants
import * as mapboxFarms from '../Map/farms';

interface DigitalTwinSectionProps {
  map: mapboxgl.Map;
  solarFarm: SolarFarmResponse;
  activeRowId: number;
}

const DigitalTwinSection = ({
  map,
  solarFarm,
  activeRowId,
}: DigitalTwinSectionProps) => {
  const hasCustomTileSets = !!mapboxFarms?.getSolarFarmById[solarFarm?.id];

  const { data: modulesAndStrings } = useGetEquipmentModulesAndStrings(
    solarFarm?.id,
    activeRowId,
  );
  const { data: geospatialRows } = useGetEquipmentRows(solarFarm?.id);
  const { data: combiners } = useGetEquipmentCombiners(solarFarm?.id);
  const { data: inverters } = useGetEquipmentInverters(solarFarm?.id);
  const { data: pyranometers } = useGetEquipmentPyranometers(solarFarm?.id);

  const sliders: EquipmentLayerData[] = [
    { layers: ModuleLayers, name: 'Module', data: modulesAndStrings?.modules },
    { layers: StringLayers, name: 'String', data: modulesAndStrings?.strings },
    { layers: RowLayers, name: 'Row', data: geospatialRows },
    { layers: CombinerLayers, name: 'Combiner', data: combiners },
    { layers: InverterLayers, name: 'Inverter', data: inverters },
    { layers: PyranometerLayers, name: 'Pyranometer', data: pyranometers },
  ];
  return (
    <SectionCollapsible
      title="Digital Twin"
      id="DigitalTwin-section"
      iconColor="neutral_900"
      variant="ghost"
    >
      <Stack>
        {sliders.map((equipmentLayer: EquipmentLayerData) => (
          <EquipmentSlider
            map={map}
            key={equipmentLayer.layers[0].id}
            equipmentLayerData={equipmentLayer}
            disabled={
              hasCustomTileSets ||
              !equipmentLayer.data ||
              equipmentLayer.data?.features.length <= 0
            }
            disabledTooltipMessage={
              hasCustomTileSets
                ? `Custom tilesets available for this solar farm. Equipment visibility is disabled.`
                : equipmentLayer.name === 'String' ||
                    (equipmentLayer.name === 'Module' && activeRowId === null)
                  ? 'Select a row to enable string and module visibility controls.'
                  : `No ${equipmentLayer.name.toLowerCase()} data found for this solar site.`
            }
          />
        ))}
      </Stack>
    </SectionCollapsible>
  );
};

export default DigitalTwinSection;
