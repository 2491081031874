import { useEffect, useState, useRef, Dispatch } from 'react';
import mapboxgl from 'mapbox-gl';
import Mixpanel from '../../utils/mixpanel/utils';
import MixpanelEvents from '../../utils/mixpanel/events';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import bbox from '@turf/bbox';

import './Map.css';
import * as mapboxFarms from './farms';
import { PointType } from '../../../shared/types/missions.d';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';
import { FeatureCollection, Feature, Polygon, Point } from '@turf/helpers';
import MapHeader from './MapHeader';
import { initTerrainSource, initDrawTool } from './utils';
import { initLayers } from './layers';
import { initImages } from './images';
import { initSources, handleUpdateFlightSource } from './sources';
import { initEvents } from './EventListeners/InitEvents';
import { useEquipmentSourcesOnMap } from '../../hooks/useEquipment';
import { useRenderAsBuilts } from '../../hooks/useAsBuilt';
import { updateSourceUrls } from './utils';
import {
  calculateCenter,
  calculateZoom,
  addSolarFarmMarkers,
} from '../../../pages/SiteList/siteList.utils';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  MAPBOX_ACCESS_TOKEN,
  MapboxStyles,
} from '../../constants/mapConstants';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

interface MapProps {
  map?: mapboxgl.Map;
  solarFarm?: SolarFarmResponse;
  polygon?: Feature<Polygon>;
  slider?: number;
  zoom?: number;
  center?: PointType;
  markerLngLat?: PointType;
  waypoints?: FeatureCollection;
  flightPath?: Feature;
  nonCalibratedFlightPath?: Feature;
  activeRowId?: number;
  markersGeoJson?: FeatureCollection<Point>;
  setMap: Dispatch<mapboxgl.Map>;
  setSlider?: Dispatch<number>;
  setPolygon?: Dispatch<Feature<Polygon>>;
  setMarkerLngLat?: Dispatch<PointType>;
  setActiveRowId?: Dispatch<number>;
  handleClickPopup?: (arg: number) => void;
}

const Map = ({
  map,
  solarFarm,
  waypoints,
  flightPath,
  polygon,
  center,
  zoom,
  slider,
  markerLngLat,
  nonCalibratedFlightPath,
  activeRowId,
  markersGeoJson,
  setMap,
  setSlider,
  setPolygon,
  setMarkerLngLat,
  setActiveRowId,
  handleClickPopup,
}: MapProps) => {
  const marker = useRef<mapboxgl.Marker>(null);
  const [drawTool, setDrawTool] = useState(null);

  useEquipmentSourcesOnMap(map, solarFarm, activeRowId);
  useRenderAsBuilts(map, solarFarm);

  useEffect(() => {
    if (map) return; // initialize map only once
    const solarFarmCenter = solarFarm
      ? { lat: solarFarm.latitude, lng: solarFarm.longitude }
      : null;
    const initialCenter = center || solarFarmCenter || DEFAULT_MAP_CENTER;

    const newMap: mapboxgl.Map = new mapboxgl.Map({
      container: 'map-container',
      style: MapboxStyles.StreetsV12,
      center: [initialCenter.lng, initialCenter.lat],
      zoom: zoom || DEFAULT_ZOOM,
    });

    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    newMap.addControl(nav, 'bottom-right');

    // Set up the draw tool
    const draw = initDrawTool(
      newMap,
      polygon ? 'simple_select' : 'draw_polygon',
      onDrawChange,
      onDrawDelete,
    );
    setDrawTool(draw);

    newMap.on('load', () => {
      initSources(newMap);
      initLayers(newMap);
      initImages(newMap);
      initEvents(newMap, setActiveRowId, draw);
      setMap(newMap);
    });

    newMap.on('style.load', () => {
      initTerrainSource(newMap);
    });

    return () => {
      newMap.remove();
      setMap(null);
    };
  }, []);

  // Add markers and adjust zoom level for the site list page
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  useEffect(() => {
    if (!map || !markersGeoJson) return;
    addSolarFarmMarkers(map, markersGeoJson, markersRef, handleClickPopup);
    map.fitBounds(bbox(markersGeoJson), { padding: 40 });
    drawTool.changeMode('simple_select');
  }, [map, markersGeoJson]);

  // Handle overlay change
  useEffect(() => {
    if (!map || !solarFarm) return;
    handleCurrentOverlayChange(String(solarFarm.id));
  }, [solarFarm, map]);

  // Manage overlay opacity for as-builts that are hosted by mapbox
  useEffect(() => {
    if (!map) return;

    if (map?.getLayer('raptor-as-built')) {
      // Adjust the layers opacity for as-built-overlay
      map.setPaintProperty('raptor-as-built', 'raster-opacity', slider / 100);
    }
  }, [slider]);

  // Manage polygon
  useEffect(() => {
    if (!drawTool || !map) return;
    drawTool.deleteAll();
    if (polygon) {
      drawTool.changeMode('simple_select');
      drawTool.add(polygon);
    } else {
      drawTool.changeMode('draw_polygon');
    }
  }, [drawTool, polygon]);

  // Fly to the solar farm when it is loaded
  useEffect(() => {
    if (!map) return;
    if (center && zoom) {
      map.setZoom(zoom);
      map.setCenter([center.lng, center.lat]);
      return;
    } else if (markersGeoJson) {
      const boundingBox = bbox(markersGeoJson);
      map.setZoom(calculateZoom(boundingBox));
      map.setCenter(calculateCenter(boundingBox));
    } else if (solarFarm) {
      // centers on lat lng saved in uploaded mission
      map.setZoom(15);
      map.setCenter([solarFarm.longitude, solarFarm.latitude]);
    } else {
      map.setZoom(DEFAULT_ZOOM);
      map.setCenter(DEFAULT_MAP_CENTER);
    }
  }, [map, solarFarm, center, zoom, markersGeoJson]);

  // Manage the marker on the map
  useEffect(() => {
    if (!map) return;

    // Marker exists and we are updating the markerLngLat
    if (marker.current && markerLngLat) return;

    // Clear marker if markerLngLat is null
    if (marker.current && !markerLngLat) {
      marker.current.remove();
      marker.current = null;
    } else if (!marker.current && markerLngLat) {
      // Create marker if markerLngLat is not null and no marker exists
      const takeoffMarker = new mapboxgl.Marker({ draggable: true });
      takeoffMarker.setLngLat([markerLngLat.lng, markerLngLat.lat]);
      takeoffMarker.addTo(map);
      marker.current = takeoffMarker;

      const onDragEnd = () => {
        setMarkerLngLat(takeoffMarker.getLngLat());
      };
      takeoffMarker.on('dragend', onDragEnd);
    }
  }, [markerLngLat]);

  // Manage the flight path and waypoints
  useEffect(() => {
    if (!map) return;
    handleUpdateFlightSource(map, 'flightPath', flightPath);
    handleUpdateFlightSource(map, 'waypoints', waypoints);
  }, [waypoints, flightPath]);

  // Manage the non-calibrated flight path
  useEffect(() => {
    if (!map) return;

    handleUpdateFlightSource(
      map,
      'nonCalibratedFlightPath',
      nonCalibratedFlightPath,
    );
  }, [map, nonCalibratedFlightPath]);

  const onDrawChange = ({ features }: MapboxDraw.DrawCreateEvent) => {
    Mixpanel.track(MixpanelEvents.PlanPolygonDrawChange);
    if (setPolygon) {
      setPolygon(features[0]);
    }
  };

  const onDrawDelete = () => {
    Mixpanel.track(MixpanelEvents.PlanPolygonDrawDelete);
    if (setPolygon) {
      setPolygon(null);
    }
  };

  const handleCurrentOverlayChange = (id: string) => {
    setSlider(25);
    if (mapboxFarms.getSolarFarmById[id]) {
      updateSourceUrls(map, mapboxFarms.getSolarFarmById[id]);
    }
  };

  return (
    <div className="map">
      {map && solarFarm && <MapHeader map={map} />}
      <div id="map-container" className="map-container" />
    </div>
  );
};

export default Map;
