import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { tilesetLayerFunctions } from './layers/TilesetLayers';
import { SolarFarmMapBox } from './farms';
import { addTilesetLayer } from './layers';
import {
  SourceId,
  ROWS_SOURCE_ID,
  ROWS_LABEL_SOURCE_ID,
  CAB_LINES_SOURCE_ID,
  CAB_LINES_LABEL_SOURCE_ID,
  CAB_COMBINERS_SOURCE_ID,
  CAB_COMBINERSS_LABEL_SOURCE_ID,
  INVERTERS_SOURCE_ID,
  INVERTERS_LABEL_SOURCE_ID,
  INVERTER_BLOCKS_SOURCE_ID,
  INVERTER_BLOCKS_LABEL_SOURCE_ID,
  FENCE_LINES_SOURCE_ID,
  AS_BUILT_SOURCE_ID,
  ROAD_SOURCE_ID,
} from './sources/TilesetSources';
import { FeatureCollection, Geometries } from '@turf/helpers';
import { MapboxStyles } from '../../constants/mapConstants';

export const initDrawTool = (
  map: mapboxgl.Map,
  defaultMode: string,
  onDrawChange: ({ features }: MapboxDraw.DrawCreateEvent) => void,
  onDrawDelete: () => void,
) => {
  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true,
    },
    defaultMode,
  });
  map.addControl(draw);
  map.on('draw.create', onDrawChange);
  map.on('draw.delete', onDrawDelete);
  map.on('draw.update', onDrawChange);
  return draw;
};

const setUrlAndWaitForLoad = (
  map: mapboxgl.Map,
  sourceId: SourceId,
  tilesetUrl: string,
) => {
  if (map.getLayer(sourceId)) {
    map.removeLayer(sourceId);
  }
  return new Promise(resolve => {
    // update or add mapbox sources
    if (map.getSource(sourceId)) {
      map.getSource(sourceId).setUrl(tilesetUrl);
    } else {
      if (sourceId === 'raptor-as-built') {
        map.addSource(sourceId, {
          type: 'raster',
          url: tilesetUrl,
        });
      } else {
        map.addSource(sourceId, {
          type: 'vector',
          url: tilesetUrl,
        });
      }
    }
    map.once('idle', resolve);
  });
};

export const updateSourceUrls = async (
  map: mapboxgl.Map,
  solarSite: SolarFarmMapBox,
) => {
  const sourceIds: SourceId[] = [
    AS_BUILT_SOURCE_ID,
    ROWS_SOURCE_ID,
    ROWS_LABEL_SOURCE_ID,
    CAB_LINES_SOURCE_ID,
    CAB_LINES_LABEL_SOURCE_ID,
    CAB_COMBINERS_SOURCE_ID,
    CAB_COMBINERSS_LABEL_SOURCE_ID,
    INVERTERS_SOURCE_ID,
    INVERTERS_LABEL_SOURCE_ID,
    INVERTER_BLOCKS_SOURCE_ID,
    INVERTER_BLOCKS_LABEL_SOURCE_ID,
    FENCE_LINES_SOURCE_ID,
    ROAD_SOURCE_ID,
  ];

  await Promise.all(
    sourceIds.map(sourceId => {
      if (map.getLayer(sourceId)) {
        // the layers need to be removed from the map or else this will cause an error
        map.removeLayer(sourceId);
      }
      if (solarSite.sources[sourceId]) {
        return setUrlAndWaitForLoad(
          map,
          sourceId,
          solarSite.sources[sourceId].tilesetUrl,
        );
      }
    }),
  );
  sourceIds.forEach(sourceId => {
    if (solarSite.sources[sourceId]) {
      const vectorLayerStyle = tilesetLayerFunctions[sourceId](
        sourceId,
        solarSite.sources[sourceId].vectorLayer,
        solarSite.sources[sourceId].label
          ? solarSite.sources[sourceId].label
          : undefined,
      );
      addTilesetLayer(map, vectorLayerStyle, sourceId);

      map.moveLayer(sourceId, 'continent-label');

      if (
        sourceId != AS_BUILT_SOURCE_ID &&
        sourceId != INVERTER_BLOCKS_SOURCE_ID
      ) {
        map.moveLayer(`${sourceId}-label`, 'continent-label');
      }
    }
  });
};

/**
 * convert GeoJSON polygons to GeoJSON points obj
 * use this to place the text label of each row.
 * @param geoJSON - {json} - a geoJson object
 * @data - new geoJSON data for points
 */
export const GeoJSONPolygonToPoint = (
  geoJSON: FeatureCollection<Geometries>,
) => {
  const data = [];
  geoJSON?.features.map(item => {
    data.push({
      id: item?.id,
      geometry: {
        type: 'Point',
        coordinates: item?.geometry?.coordinates[0][0],
      },
      properties: {
        ...item?.properties,
      },
    });
  });
  return {
    features: data,
    type: 'FeatureCollection',
  } as FeatureCollection<Geometries>;
};

export const getFarmTileMapUrl = (tileMap, solarFarm, orgId) => {
  /** Builds url to pass to tile map source for map (specifically to access asbilts on Solar Assets Map)
   * arg - tileMap
   * ret - url (str)
   */
  const farmUUID = solarFarm.uuid;

  const id = tileMap.id.toString();
  // url parameters
  const params = `/${id}/{z}/{x}/{y}.png`;

  const route = sessionStorage.tileServerUrl + '/api/v1/ortho';

  // build url
  const url = `${route}${params}?farm_uuid=${farmUUID}&org_id=${orgId}&authorization_token=${sessionStorage.accessToken}`;
  return url;
};

export const initTerrainSource = (map: mapboxgl.Map) => {
  map.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: MapboxStyles.TerrainV1,
    tileSize: 512,
    maxzoom: 14,
  });
  // add the DEM source as a terrain layer with exaggerated height
  map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
};

export const initNavControl = (map: mapboxgl.Map) => {
  const nav = new mapboxgl.NavigationControl({
    visualizePitch: true,
  });
  map.addControl(nav, 'bottom-right');
};
