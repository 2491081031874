import { PointType } from '../types/missions';

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoicmFwdG9ybWFwcyIsImEiOiJjbGRqcWpiY3IxdHZ3M3dyOG1mN2tjdzdyIn0.zZxI14U9Pr11-eoRdXVOtQ';
export const DEFAULT_ZOOM = 15;
export const DEFAULT_MAP_CENTER: PointType = { lat: 0, lng: 0 };

export enum MapboxStyles {
  TerrainV1 = 'mapbox://mapbox.mapbox-terrain-dem-v1',
  TerrainV2 = 'mapbox://mapbox.mapbox-terrain-v2',
  StreetsV12 = 'mapbox://styles/mapbox/satellite-streets-v12',
}
