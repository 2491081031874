import { createContext, Component } from 'react';
export const MapContext = createContext(null);
import {
  MapContextProviderProps,
  MapContextValue,
} from '../../../shared/types/missions';

class MapContextProvider extends Component<
  MapContextProviderProps,
  MapContextValue
> {
  state: MapContextValue = {
    zoom: 15,
    center: {
      lng: 0,
      lat: 0,
    },
    currentLayers: [],
    sliderValue: 25,
    initialized: false,
  };

  setInitialized = (value: boolean) => {
    this.setState({ initialized: value });
  };

  setSlider = value => {
    this.setState({ sliderValue: value });
  };
  setZoom = value => {
    this.setState({ zoom: value });
  };
  setLngAndLat = (lng, lat) => {
    this.setState({ center: { ...this.state.center, lng: lng, lat: lat } });
  };

  render() {
    return (
      <MapContext.Provider
        value={{
          ...this.state,
          setSlider: this.setSlider,
          setZoom: this.setZoom,
          setLngAndLat: this.setLngAndLat,
          setInitialized: this.setInitialized,
        }}
      >
        {this.props.children}
      </MapContext.Provider>
    );
  }
}

export default MapContextProvider;
