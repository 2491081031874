import { useState } from 'react';
import { Director, View, BroadcastEvent } from '@millicast/sdk';
import { useMutation, useQuery } from 'react-query';

export enum DolbyBroadcastEvents {
  Active = 'active', // Fires when the live stream is, or has started broadcasting
  Inactive = 'inactive', // Fires when the stream has stopped broadcasting, but is still available.
  Stopped = 'stopped', // Fires when the stream has stopped for a given reason
}

const useDolby = (generateNewView = false) => {
  const [dolbyView, setDolbyView] = useState<View | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const dolbyTokenGenerator = async () => {
    const subscriberConnection = await Director.getSubscriber({
      streamName: window.DOLBY_DOCK_1_AUBIN_FARMS_STREAM_NAME,
      streamAccountId: window.DOLBY_LIVE_STREAM_ACCOUNT_ID,
      subscriberToken: window.DOLBY_DOCK_1_AUBIN_FARMS_SUBSCRIBE_TOKEN,
    });
    return subscriberConnection;
  };

  // Enforce only on instance of Dolby View
  if (!dolbyView && generateNewView) {
    setDolbyView(
      new View(
        window.DOLBY_DOCK_1_AUBIN_FARMS_STREAM_NAME,
        dolbyTokenGenerator,
        null,
        true /* auto reconnect */,
      ),
    );
  }

  const closeDolbyStream = async () => {
    if (dolbyView) {
      dolbyView.removeAllListeners();
      await dolbyView.stop();
      setDolbyView(null);
      setIsSubscribed(false);
    }
  };

  const useCloseDolbyStream = () => {
    return useMutation({
      mutationKey: 'closeDolbyStream',
      mutationFn: async () => {
        return await closeDolbyStream();
      },
    });
  };

  const useSubscribeToDolbyStream = (
    videoRef: HTMLVideoElement,
    enabled,
    onInactiveStream: () => void,
    onError: () => void,
  ) => {
    const connectToDolbyStream = async () => {
      if (!dolbyView) throw new Error('Dolby View is not available');

      if (dolbyView.isActive && isSubscribed) return;

      await dolbyView.on('track', event => {
        if (videoRef) {
          videoRef.srcObject = event.streams[0];
          videoRef.hidden = false;
          videoRef.autoplay = true;
        }
      });

      await dolbyView.on('broadcastEvent', (event: BroadcastEvent) => {
        if (event.name === DolbyBroadcastEvents.Inactive) {
          onInactiveStream();
        }
      });

      await dolbyView.connect({
        events: [
          DolbyBroadcastEvents.Active,
          DolbyBroadcastEvents.Inactive,
          DolbyBroadcastEvents.Stopped,
        ],
      });
    };

    return useQuery({
      queryKey: 'useSubscribeToDolbyStream',
      queryFn: async () => {
        return await connectToDolbyStream();
      },
      enabled,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onError,
    });
  };

  return {
    useSubscribeToDolbyStream,
    useCloseDolbyStream,
    closeDolbyStream,
  };
};

export default useDolby;
