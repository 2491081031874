import { ContentBlock } from '@raptormaps/content-block';
import img from '../../assets/denied_page.png';
import './Denied.css';

const Denied = () => {
  return (
    <div className="denied">
      <ContentBlock
        className="denied-border"
        borders
        columns={1}
        padding="large"
        variant="white"
      >
        <img className="denied-image" src={img} alt="A person flying a drone" />
        <h3>
          The page you are looking for cannot be found or you do not have access
        </h3>
        <p>
          Please contact you org admin or contact{' '}
          <a href={'mailto:sales@raptormaps.com'}>sales@raptormaps.com</a> to
          learn more
        </p>
      </ContentBlock>
    </div>
  );
};

export default Denied;
