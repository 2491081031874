import {
  DataCollectionType,
  DataCollectionTypeToInspectionType,
} from './missionParametersSection.d';

export const SETTINGS_OPTIONS = ['Basic', 'Advanced'];

export const DATA_COLLECTION_OPTIONS: DataCollectionType[] = ['Area', 'Linear'];

export enum FLIGHT_MODE {
  II_MODE = 'IIMode',
  AIRPLANE = 'Airplane',
  STANDARD = 'Standard',
  COMPREHENSIVE = 'Comprehensive',
  OBLIQUE = 'Oblique',
  PERIMETER = 'Perimeter',
  WIRING = 'Wiring',
  CUSTOM = 'Custom',
  CAB_LINES = 'CABLines',
  ORTHO = 'Ortho',
  GROUND_ROBOT = 'Ground Robot',
  SQUARE_ORBITAL = 'SquareOrbital',
}

export const DATA_COLLECTION_TYPE_TO_INSPECTION_TYPE: DataCollectionTypeToInspectionType =
  {
    Area: [
      'Standard',
      'Comprehensive',
      'Oblique',
      'Perimeter',
      'Wiring',
      'IIMode',
      'Custom',
      FLIGHT_MODE.ORTHO,
      FLIGHT_MODE.SQUARE_ORBITAL,
    ],
    Linear: ['CABLines'],
  };
