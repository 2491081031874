export const ROWS_SOURCE_ID = 'raptor-pv-rows';
export const ROWS_LABEL_SOURCE_ID = 'raptor-pv-rows-label';
export const CAB_LINES_SOURCE_ID = 'raptor-cab-lines';
export const CAB_LINES_LABEL_SOURCE_ID = 'raptor-cab-lines-label';
export const CAB_COMBINERS_SOURCE_ID = 'raptor-cab-combiners';
export const CAB_COMBINERSS_LABEL_SOURCE_ID = 'raptor-cab-combiners-label';
export const INVERTERS_SOURCE_ID = 'raptor-inverter-pins';
export const INVERTERS_LABEL_SOURCE_ID = 'raptor-inverter-pins-label';
export const INVERTER_BLOCKS_SOURCE_ID = 'raptor-pv-inverter-blocks';
export const INVERTER_BLOCKS_LABEL_SOURCE_ID =
  'raptor-pv-inverter-blocks-label';
export const FENCE_LINES_SOURCE_ID = 'raptor-fence-lines';
export const AS_BUILT_SOURCE_ID = 'raptor-as-built';
export const ROAD_SOURCE_ID = 'raptor-roads';

export type SourceId =
  | typeof ROWS_SOURCE_ID
  | typeof ROWS_LABEL_SOURCE_ID
  | typeof CAB_LINES_SOURCE_ID
  | typeof CAB_LINES_LABEL_SOURCE_ID
  | typeof CAB_COMBINERS_SOURCE_ID
  | typeof CAB_COMBINERSS_LABEL_SOURCE_ID
  | typeof INVERTERS_SOURCE_ID
  | typeof INVERTERS_LABEL_SOURCE_ID
  | typeof INVERTER_BLOCKS_SOURCE_ID
  | typeof INVERTER_BLOCKS_LABEL_SOURCE_ID
  | typeof FENCE_LINES_SOURCE_ID
  | typeof AS_BUILT_SOURCE_ID
  | typeof ROAD_SOURCE_ID;
