// Raptor UI
import { TextInput } from '@raptormaps/text-input';
import { Checkbox } from '@raptormaps/checkbox';
import { CheckboxVariant } from '@raptormaps/checkbox/build/Checkbox.interface';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { Row, Stack } from '@raptormaps/layout';
import theme from '@raptormaps/theme';
import { Tooltip } from '@raptormaps/tooltip';

// Styled components
import {
  InlineIconToggle,
  SectionCollapsible,
} from '../MissionParameters/MissionParametersSection.styles';

// Types
import { CalibrationObject } from '@/shared/types/missions';

// Mixpanel
import Mixpanel from '@/shared/utils/mixpanel/utils';
import MixpanelEvents from '@/shared/utils/mixpanel/events';

interface CalibrationSectionProps {
  calibration: CalibrationObject;
  setCalibration: React.Dispatch<React.SetStateAction<CalibrationObject>>;
  handleCalculateWaypoints: () => void;
}
const CalibrationSection = ({
  calibration,
  setCalibration,
  handleCalculateWaypoints,
}: CalibrationSectionProps) => {
  const handleInputScroll = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  return (
    <SectionCollapsible
      iconColor="neutral_900"
      title="Calibration"
      variant="ghost"
    >
      <Stack>
        <InlineIconToggle>
          <Text variant={'paragraph_medium_bold'}>
            <Icon icon="Enlarge" size={'large'} />
            Activate Calibration
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip="Calibration shifts flight location based on a bearing (degrees) and distance (km). Grey lines show the original planned path, while red lines indicate the adjusted flight path."
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Calibration Information"
                icon="CircleInfo"
                size={'medium'}
              />
            </Tooltip>
          </Text>
          <Checkbox
            name="calibrationBool"
            checked={calibration.active}
            onChange={e => {
              Mixpanel.track(MixpanelEvents.PlanCalibrationToggled);
              setCalibration({
                ...calibration,
                active: e.target.checked,
                nonCalibratedFlightPath: null,
              });
            }}
            variant={CheckboxVariant.TOGGLE}
          />
        </InlineIconToggle>
        <Row
          align="center"
          gutter="none"
          justify="space-between"
          style={{ width: '92.5%' }}
        >
          <Text
            style={calibration.active ? null : { color: theme.colors.grey_300 }}
            htmlFor="calibrationBearing"
            variant="label_large"
          >
            {' '}
            Bearing{' '}
          </Text>
          <TextInput
            name="calibrationBearing"
            inputSize="small"
            inputStyle="white-box"
            inputType="number"
            onBlur={handleCalculateWaypoints}
            onWheel={handleInputScroll}
            disabled={!calibration.active}
            value={calibration.rhumbBearing}
            onChange={e =>
              setCalibration({
                ...calibration,
                rhumbBearing: e.target.value
                  ? parseFloat(e.target.value)
                  : null,
              })
            }
            style={
              calibration.active
                ? null
                : {
                    color: theme.colors.grey_300,
                    borderColor: theme.colors.neutral_300,
                  }
            }
          />
        </Row>
        <Row
          align="center"
          gutter="none"
          justify="space-between"
          style={{ width: '92.5%' }}
        >
          <Text
            style={calibration.active ? null : { color: theme.colors.grey_300 }}
            htmlFor="calibrationDistance"
            variant="label_large"
          >
            {' '}
            Distance{' '}
          </Text>
          <TextInput
            name="calibrationDistance"
            inputSize="small"
            inputStyle="white-box"
            inputType="number"
            onBlur={handleCalculateWaypoints}
            onWheel={handleInputScroll}
            disabled={!calibration.active}
            value={calibration.rhumbDistance}
            onChange={e =>
              setCalibration({
                ...calibration,
                rhumbDistance: e.target.value
                  ? parseFloat(e.target.value)
                  : null,
              })
            }
            style={
              calibration.active
                ? null
                : {
                    color: theme.colors.grey_300,
                    borderColor: theme.colors.neutral_300,
                  }
            }
          />
        </Row>
      </Stack>
    </SectionCollapsible>
  );
};

export default CalibrationSection;
