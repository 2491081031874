import { MapSource } from './BaseSources';

export const FlightPath: MapSource = {
  id: 'flightPath',
  source: {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [
          [-122.483696, 37.833818],
          [-122.483482, 37.833174],
          [-122.483396, 37.8327],
        ],
      },
    },
  },
};

export const NonCalibratedFlightPath: MapSource = {
  id: 'nonCalibratedFlightPath',
  source: {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [
          [-122.483696, 37.833818],
          [-122.483482, 37.833174],
          [-122.483396, 37.8327],
        ],
      },
    },
  },
};

export const Waypoints: MapSource = {
  id: 'waypoints',
  source: {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  },
};

export default [FlightPath, NonCalibratedFlightPath, Waypoints];
