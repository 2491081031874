import { useEffect, useContext, Dispatch } from 'react';
import Mixpanel from '../../../shared/utils/mixpanel/utils';
import MixpanelEvents from '../../../shared/utils/mixpanel/events';
import mapboxgl from 'mapbox-gl';
import { MapContext } from './MapContext';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { updateSourceUrls } from '../../../shared/utils/sources';
import { initSources } from '../../../shared/components/Map/sources';
import { initLayers } from '../../../shared/components/Map/layers';
import { initEvents } from '../../../shared/components/Map/EventListeners/InitEvents';
import './CalibrationMap.css';
import * as mapboxFarms from '../../../shared/components/Map/farms';
import { Feature, Polygon } from '@turf/helpers';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';
import { initImages } from '../../../shared/components/Map/images';
import { useEquipmentSourcesOnMap } from '../../../shared/hooks/useEquipment';
import { useRenderAsBuilts } from '../../../shared/hooks/useAsBuilt';
import { MapboxStyles } from '../../../shared/constants/mapConstants';
mapboxgl.accessToken =
  'pk.eyJ1IjoicmFwdG9ybWFwcyIsImEiOiJjbGRqcWpiY3IxdHZ3M3dyOG1mN2tjdzdyIn0.zZxI14U9Pr11-eoRdXVOtQ';

interface CalibrationMapProps {
  activeSolarFarm: SolarFarmResponse;
  map: mapboxgl.Map | null;
  drawTool?: MapboxDraw;
  setDrawTool: Dispatch<MapboxDraw>;
  polygon?: Feature<Polygon>;
  setPolygon?: Dispatch<Feature<Polygon>>;
  solarFarm: SolarFarmResponse;
  activeRowId: number;
  setActiveRowId: Dispatch<number>;
  setMap: Dispatch<mapboxgl.Map>;
}

const CalibrationMap = ({
  activeSolarFarm,
  drawTool,
  setDrawTool,
  map,
  polygon,
  setPolygon,
  solarFarm,
  activeRowId,
  setActiveRowId,
  setMap,
}: CalibrationMapProps) => {
  const {
    center,
    zoom,
    initialized,
    sliderValue,
    setSlider,
    setZoom,
    setLngAndLat,
    setInitialized,
  } = useContext(MapContext);

  useEquipmentSourcesOnMap(map, solarFarm, activeRowId);
  useRenderAsBuilts(map, solarFarm);
  useEffect(() => {
    // update coordinates for lat/lon when map is moved
    if (!map) return; // wait for map to initialize
    map?.on('move', () => {
      setLngAndLat(
        map.getCenter().lng.toFixed(8),
        map.getCenter().lat.toFixed(8),
      );
      setZoom(map.getZoom().toFixed(2));
    });
  }, [map]);

  useEffect(() => {
    if (map) return; // initialize map only once

    const newMap = new mapboxgl.Map({
      container: 'map-container',
      style: MapboxStyles.StreetsV12,
      center: activeSolarFarm
        ? [activeSolarFarm.longitude, activeSolarFarm.latitude]
        : [center.lng, center.lat],
      zoom: zoom,
    });

    if (activeSolarFarm)
      setLngAndLat(activeSolarFarm.longitude, activeSolarFarm.latitude);

    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    newMap.addControl(nav, 'bottom-right');

    newMap.on('load', () => {
      initSources(newMap);
      initLayers(newMap);
      initImages(newMap);
      initEvents(newMap, setActiveRowId, drawTool);
      setInitialized(true);
      setMap(newMap);
    });

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: polygon ? 'simple_select' : 'draw_polygon',
    });

    newMap.addControl(draw);

    setDrawTool(draw);

    newMap.on('draw.create', onDrawChange);
    newMap.on('draw.delete', onDrawDelete);
    newMap.on('draw.update', onDrawChange);

    newMap.on('style.load', () => {
      newMap.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: MapboxStyles.TerrainV1,
        tileSize: 512,
        maxzoom: 14,
      });
      // add the DEM source as a terrain layer with exaggerated height
      newMap.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
    });

    return () => {
      newMap.remove();
      setMap(null);
    };
  }, []);

  useEffect(() => {
    if (!map || !initialized) return;
    handleCurrentOverlayChange(String(activeSolarFarm?.id));
  }, [activeSolarFarm?.id, map, initialized]);

  useEffect(() => {
    if (map?.getLayer('raptor-as-built')) {
      // Adjust the layers opacity for as-built-overlay
      map.setPaintProperty(
        'raptor-as-built',
        'raster-opacity',
        sliderValue / 100,
      );
    }
  }, [sliderValue]);

  useEffect(() => {
    if (!polygon || !drawTool) return;
    drawTool.changeMode('simple_select');
    drawTool.add(polygon);
  }, [drawTool, polygon]);

  const onDrawChange = ({ features }: MapboxDraw.DrawCreateEvent) => {
    Mixpanel.track(MixpanelEvents.PlanPolygonDrawChange);
    if (setPolygon) {
      setPolygon(features[0]);
    }
  };

  const onDrawDelete = () => {
    Mixpanel.track(MixpanelEvents.PlanPolygonDrawDelete);
    if (setPolygon) {
      setPolygon(null);
    }
  };

  const handleCurrentOverlayChange = (id: string) => {
    setSlider(25);
    if (mapboxFarms.getSolarFarmById[id]) {
      updateSourceUrls(map, mapboxFarms.getSolarFarmById[id]);
    }
  };

  return (
    <div className="map">
      <div className="sidebar">
        Longitude: {center.lng} | Latitude: {center.lat} | Zoom: {zoom}
      </div>
      <div id="map-container" className="map-container" />
    </div>
  );
};

export default CalibrationMap;
