import { FarmsApi } from '@raptormaps/customer-farm-api-client-ts';
import {
  SolarFarmsApi,
  ResponseError,
} from '@raptormaps/raptor-flight-client-ts';
import { useApiLegacy } from './useApiLegacy';
import { useApi } from './useApi';
import { useQuery, useQueryClient } from 'react-query';
import { useAppContext } from '../context/AppContext';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';

export const GET_SOLAR_FARMS_PAGINATION_LIMIT = 50;
export const ONE_HOUR = 60 * 60 * 1000;

export const useSolarFarms = (search?: string, offset = 0) => {
  const { user } = useAppContext();
  const orgId = user?.latest_org_id;
  const customerApi = useApiLegacy(FarmsApi, {
    basePath: window.REACT_APP_CUSTOMER_API_ENDPOINT,
  });
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['solarFarms', user?.latest_org_id, search, offset],
    queryFn: () => {
      return customerApi.getDataCollectionSolarFarms({
        orgId: orgId,
        ...(search ? { name: search } : {}),
        limit: GET_SOLAR_FARMS_PAGINATION_LIMIT,
        offset,
      });
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const cachedQueries = queryClient.getQueriesData<SolarFarmResponse[]>([
    'solarFarms',
    user?.latest_org_id,
    search,
  ]);

  const solarFarms = cachedQueries?.reduce((acc, queryData) => {
    if (!queryData) return acc;
    const [key, data] = queryData;
    const offsetKey = key[3] as number;

    if (!data || offsetKey > offset) return acc;
    return [...acc, ...data];
  }, []);

  return {
    ...query,
    solarFarms,
  };
};

export const useSolarFarmById = (solarFarmId?: number) => {
  const { user } = useAppContext();

  const solarFarmsApi = useApi(SolarFarmsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['solarFarmsById', user?.latest_org_id, solarFarmId],
    queryFn: () => {
      if (!solarFarmId) return null;
      return solarFarmsApi.getSolarFarmByIdV1FarmsGet({
        orgId: user?.latest_org_id,
        solarFarmId,
      });
    },
    staleTime: ONE_HOUR,
    retry(failureCount, error: ResponseError) {
      if ([403, 404].includes(error.response.status)) return false;
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
