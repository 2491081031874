import { Map } from 'mapbox-gl';
import { SolarFarmMapBox } from '../components/Map/farms';

export type SourceId =
  | 'raptor-pv-rows'
  | 'raptor-pv-rows-label'
  | 'raptor-cab-lines'
  | 'raptor-cab-lines-label'
  | 'raptor-cab-combiners'
  | 'raptor-cab-combiners-label'
  | 'raptor-inverter-pins'
  | 'raptor-inverter-pins-label'
  | 'raptor-pv-inverter-blocks'
  | 'raptor-fence-lines'
  | 'raptor-as-built';

interface LayerStyleType {
  sourceId: string;
  vectorLayer: string;
  type: string;
  paintProps: object;
  layoutPaintProps?: object;
  layout?: object;
}

const layerStylePvRow = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): LayerStyleType => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: {
      'line-color': '#0a93f5',
    },
    layoutPaintProps: {
      'text-color': '#fc8803',
    },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const layerStyleCabline = (
  sourceId: string,
  vectorLayer: string,
  label = 'cab_name',
): LayerStyleType => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: { 'line-color': '#fc8803' },
    layoutPaintProps: { 'text-color': '#0a93f5' },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const layerStyleFenceLines = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): LayerStyleType => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: { 'line-color': '#ceff1a' },
    layoutPaintProps: { 'text-color': '#ceff1a' },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const layerStyleCabCombiner = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): LayerStyleType => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'circle',
    paintProps: { 'circle-color': 'pink' },
    layoutPaintProps: { 'text-color': '#0a93f5' },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'center',
      'text-justify': 'auto',
    },
  };
};
const layerStyleInverterPins = (
  sourceId: string,
  vectorLayer: string,
  label = 'object_name',
): LayerStyleType => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'circle',
    paintProps: {
      'circle-color': '#fc8803',
    },
    layoutPaintProps: {
      'text-color': '#fc8803',
    },
    layout: {
      'text-field': `{${label}}`,
      'text-anchor': 'top-left',
      'text-justify': 'left',
    },
  };
};
const layerStyleInverterBlocks = (
  sourceId: string,
  vectorLayer: string,
): LayerStyleType => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'line',
    paintProps: {
      'line-color': '#c517ff',
      'line-width': 2,
    },
  };
};
const layerAsBuilt = (
  sourceId: string,
  vectorLayer: string,
): LayerStyleType => {
  return {
    sourceId: sourceId,
    vectorLayer: vectorLayer,
    type: 'raster',
    paintProps: {
      'raster-opacity': 0.25,
    },
  };
};

const layerStyleFunctions = {
  'raptor-pv-rows': layerStylePvRow,
  'raptor-cab-lines': layerStyleCabline,
  'raptor-cab-combiners': layerStyleCabCombiner,
  'raptor-inverter-pins': layerStyleInverterPins,
  'raptor-pv-inverter-blocks': layerStyleInverterBlocks,
  'raptor-fence-lines': layerStyleFenceLines,
  'raptor-as-built': layerAsBuilt,
};

const addLayers = (
  map: Map,
  layerStyle: LayerStyleType,
  sourceId: SourceId,
) => {
  const mapLayer = {
    id: layerStyle.sourceId,
    type: layerStyle.type,
    source: sourceId,
    'source-layer': layerStyle.vectorLayer,
    paint: layerStyle.paintProps,
  };
  map.addLayer(mapLayer);
  if (layerStyle.layout) {
    const mapLayerLabels = {
      id: `${layerStyle.sourceId}-label`,
      type: 'symbol',
      source: layerStyle.sourceId,
      'source-layer': layerStyle.vectorLayer,
      paint: layerStyle.layoutPaintProps,
      layout: layerStyle.layout,
    };
    map.addLayer(mapLayerLabels);
  }
};

const setUrlAndWaitForLoad = (
  map: Map,
  sourceId: SourceId,
  tilesetUrl: string,
) => {
  if (map.getLayer(sourceId)) {
    map.removeLayer(sourceId);
  }
  return new Promise(resolve => {
    // update or add mapbox sources
    if (map.getSource(sourceId)) {
      map.getSource(sourceId).setUrl(tilesetUrl);
    } else {
      if (sourceId === 'raptor-as-built') {
        map.addSource(sourceId, {
          type: 'raster',
          url: tilesetUrl,
        });
      } else {
        map.addSource(sourceId, {
          type: 'vector',
          url: tilesetUrl,
        });
      }
    }
    map.once('idle', resolve);
  });
};

export const updateSourceUrls = async (
  map: Map,
  solarSite: SolarFarmMapBox,
) => {
  const sourceIds: SourceId[] = [
    'raptor-pv-rows',
    'raptor-pv-rows-label',
    'raptor-cab-lines',
    'raptor-cab-lines-label',
    'raptor-cab-combiners',
    'raptor-cab-combiners-label',
    'raptor-inverter-pins',
    'raptor-inverter-pins-label',
    'raptor-pv-inverter-blocks',
    'raptor-fence-lines',
    'raptor-as-built',
  ];

  await Promise.all(
    sourceIds.map(sourceId => {
      if (map.getLayer(sourceId)) {
        // the layers need to be removed from the map or else this will cause an error
        map.removeLayer(sourceId);
      }
      if (solarSite.sources[sourceId]) {
        return setUrlAndWaitForLoad(
          map,
          sourceId,
          solarSite.sources[sourceId].tilesetUrl,
        );
      }
    }),
  );
  sourceIds.forEach(sourceId => {
    if (solarSite.sources[sourceId]) {
      const vectorLayerStyle = layerStyleFunctions[sourceId](
        sourceId,
        solarSite.sources[sourceId].vectorLayer,
        solarSite.sources[sourceId].label
          ? solarSite.sources[sourceId].label
          : undefined,
      );
      addLayers(map, vectorLayerStyle, sourceId);

      map.moveLayer(sourceId, 'continent-label');

      if (
        sourceId != 'raptor-as-built' &&
        sourceId != 'raptor-pv-inverter-blocks'
      ) {
        map.moveLayer(`${sourceId}-label`, 'continent-label');
      }
    }
  });
};
