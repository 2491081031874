// React
import { useState, useEffect } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

// Formik
import { FormikProps } from 'formik';

// Raptor UI
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import { Button } from '@raptormaps/button';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { Tabs } from '@raptormaps/tabs';
import { Row } from '@raptormaps/layout';
import { Tooltip } from '@raptormaps/tooltip';
import { Stack } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

// Components
import FollowTracker from './components/FollowTracker/FollowTracker';
import DataCollection from './components/DataCollection/DataCollection';
import WaypointReductionSection from '../WaypointReduction/WaypointReduction';
import AdvancedSettingsSection from './components/AdvancedSettingsSection/AdvancedSettingsSection';
import SpecialFeaturesSection from './components/SpecialFeaturesSection/SpecialFeaturesSection';
import MissionNameInput from './components/MissionName/MissionNameInput';

// Styled Components
import {
  DeviceDropdownButton,
  MissionParametersContainer,
  QuarterWidthTextInput,
  FormLabel,
  MinMax,
  FullWidthGridSection,
  ParagraphWithTabs,
  FullWidthTextAlignLeftDiv,
} from './MissionParametersSection.styles';

// Constants
import {
  sensorTypes,
  flightModes,
  DRONE_TYPE_TO_DISPLAY_NAME,
  CAMERA_TYPE_TO_DISPLAY_NAME,
  droneTypes,
  USER_UNITS,
  CAMERA_ANGLE_MODE_TO_DISPLAY_NAME,
} from '@/shared/constants/missionsConstants';
import {
  METERS_TO_FEET,
  FLIGHT_MODE_DEFAULTS,
  DRONE_CAMERA_DEFAULTS,
  NUMBER_INPUT_STEP,
} from '../../../constants';
import { SETTINGS_OPTIONS, FLIGHT_MODE } from './missionParametersConstants';
import {
  droneFormParameters,
  mannedFormParameters,
} from '../../../FormParameters';

// Types
import {
  DroneType,
  CameraType,
  FlightModeType,
  FlightPlanningFormikValues,
  IntervalometerObject,
  PointType,
  CameraAngleMode,
} from '@/shared/types/missions';
import { Settings } from './missionParametersSection.d';

interface MissionParametersSectionProps {
  handleCalculateWaypoints: () => void;
  formik: FormikProps<FlightPlanningFormikValues>;
  flightMode: FlightModeType;
  setFlightMode: (mode: FlightModeType) => void;
  startTime: string;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  intervalometer: IntervalometerObject;
  setIntervalometer: React.Dispatch<React.SetStateAction<IntervalometerObject>>;
  saveAsOptionIsDisabled: boolean;
  handleCreateMission: (missionName?: string | null) => void;
  solarFarmId: number;
  markerLngLat: PointType;
  handleAddTakeoffpoint: () => void;
  handleRemoveTakeoffpoint: () => void;
  cameraAngleModeOptions: CameraAngleMode[];
  isContinuousOperationsActive: boolean;
}

const MissionParametersSection = ({
  formik,
  handleCalculateWaypoints,
  flightMode,
  setFlightMode,
  handleCreateMission,
  saveAsOptionIsDisabled,
  startTime,
  setStartTime,
  intervalometer,
  setIntervalometer,
  solarFarmId,
  markerLngLat,
  handleAddTakeoffpoint,
  handleRemoveTakeoffpoint,
  cameraAngleModeOptions,
  isContinuousOperationsActive,
}: MissionParametersSectionProps) => {
  const showCameraSelectWithZoom = useFeatureIsOn(
    GrowthbookFlags.CAMERA_SELECT_WITH_ZOOM,
  );
  const showOrthoV1 = useFeatureIsOn(GrowthbookFlags.ORTHO_V1);
  const showSquareOrbit = useFeatureIsOn(GrowthbookFlags.SQUARE_ORBITAL);

  // adds the option to select ground robot mode
  const showGroundRobotMode = useFeatureIsOn(
    GrowthbookFlags.ROBOTICS_HUSKY_OBSERVER,
  );

  const [settings, setSetting] = useState<Settings>('Basic');

  const { values, handleChange, setValues, errors } = formik;

  const formParameters =
    flightMode === 'Airplane' ? mannedFormParameters : droneFormParameters;

  let flightModeOptions: string[];
  if (values.drone === 'Manned Airplane') {
    flightModeOptions = ['Airplane'];
  } else if (values.drone === 'Husky Observer') {
    flightModeOptions = [FLIGHT_MODE.GROUND_ROBOT];
  } else {
    flightModeOptions = flightModes.filter(
      mode =>
        mode !== 'Airplane' &&
        (showOrthoV1 || mode !== FLIGHT_MODE.ORTHO) &&
        (showGroundRobotMode || mode !== FLIGHT_MODE.GROUND_ROBOT) &&
        (showSquareOrbit || mode !== FLIGHT_MODE.SQUARE_ORBITAL),
    );
  }

  // This handles the conversion of a decimal that we store in flightApi and our Json to a percentage for the user to see
  const [displayFrontOverlap, setDisplayFrontOverlap] = useState<number>(
    values.frontOverlap * 100,
  );
  const [displaySideOverlap, setDisplaySideOverlap] = useState<number>(
    values.sideOverlap * 100,
  );

  useEffect(() => {
    /* 
      The first time a mission is loaded this use effect ensure the display values are accurate
    */
    setDisplayFrontOverlap(values.frontOverlap * 100);
    setDisplaySideOverlap(values.sideOverlap * 100);
  }, [formik.initialValues]);

  // This handles the converstion back to a decimal for the formik values
  const useHandleChangeEffect = (name, value, handleChange) => {
    useEffect(() => {
      handleChange({
        target: { name, value: value / 100 },
      });
    }, [name, value, handleChange]);
  };
  useHandleChangeEffect('frontOverlap', displayFrontOverlap, handleChange);
  useHandleChangeEffect('sideOverlap', displaySideOverlap, handleChange);

  const handleDroneCameraTypeUpdated = (
    drone: DroneType,
    cameraType: CameraType,
  ) => {
    const newValues = {
      ...values,
      drone,
      cameraType,
      ...DRONE_CAMERA_DEFAULTS[drone][cameraType],
    };
    if (drone === values.drone) {
      setValues({
        ...newValues,
      });
    } else if (drone === 'Manned Airplane') {
      // if the drone is changed to a manned aircraft, set the flight mode to airplane and apply defaults
      handleRemoveTakeoffpoint();
      setFlightMode('Airplane');
      setValues({
        ...newValues,
        ...FLIGHT_MODE_DEFAULTS['Airplane'],
        terrainFollowBool: false,
        safeTakeoffAltitudeBool: false,
      });
    } else if (values.drone === 'Manned Airplane') {
      // if the drone is changed from a manned aircraft
      if (drone === 'Husky Observer') {
        // if drone switched to is a ground robot, apply ground robot defaults
        handleRemoveTakeoffpoint();
        setFlightMode(FLIGHT_MODE.GROUND_ROBOT);
        setValues({
          ...newValues,
          ...FLIGHT_MODE_DEFAULTS[FLIGHT_MODE.GROUND_ROBOT],
          terrainFollowBool: false,
          safeTakeoffAltitudeBool: false,
        });
      } else {
        // if switching to other drone type, set the flight mode to standard and apply defaults
        setFlightMode('Standard');
        setValues({
          ...newValues,
          ...FLIGHT_MODE_DEFAULTS['Standard'],
          terrainFollowBool: true,
          safeTakeoffAltitudeBool: true,
        });
      }
    } else if (drone === 'Husky Observer') {
      // if the drone is changed to a ground robot, set ground robot defaults
      handleRemoveTakeoffpoint();
      setFlightMode(FLIGHT_MODE.GROUND_ROBOT);
      setValues({
        ...newValues,
        ...FLIGHT_MODE_DEFAULTS[FLIGHT_MODE.GROUND_ROBOT],
        terrainFollowBool: false,
        safeTakeoffAltitudeBool: false,
      });
    } else if (values.drone === 'Husky Observer') {
      // if the drone is changed from a ground robot, change defaults to standard
      setFlightMode('Standard');
      setValues({
        ...newValues,
        ...FLIGHT_MODE_DEFAULTS['Standard'],
        terrainFollowBool: true,
        safeTakeoffAltitudeBool: true,
      });
    } else {
      // if changing from one drone to another, keep the flight mode the same and apply defaults
      setValues({
        ...newValues,
      });
    }
  };

  const handleInputScroll = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  // when the overlaps are updated outside of this form the useEffect will update the state values to match
  useEffect(() => {
    setDisplayFrontOverlap(Math.round(values.frontOverlap * 100));
    setDisplaySideOverlap(Math.round(values.sideOverlap * 100));
  }, [flightMode]);

  const handleFlightModeUpdated = (mode: FlightModeType) => {
    setFlightMode(mode);
    setValues({
      ...values,
      ...FLIGHT_MODE_DEFAULTS[mode],
    });
    /* 
      update the display values for the cameraController -> this is incase the user selects the same flightMode as is already selected  as a means of just resetting the form values 
    */
    if (FLIGHT_MODE_DEFAULTS[mode]?.frontOverlap) {
      setDisplayFrontOverlap(
        Math.round(FLIGHT_MODE_DEFAULTS[mode].frontOverlap * 100),
      );
    }
    if (FLIGHT_MODE_DEFAULTS[mode]?.sideOverlap) {
      setDisplaySideOverlap(
        Math.round(FLIGHT_MODE_DEFAULTS[mode].sideOverlap * 100),
      );
    }
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleChange(e);
      handleCalculateWaypoints();
    }
  };

  const droneTypeOptions: DropdownOption[] = droneTypes
    .filter(drone => {
      return !(!showGroundRobotMode && drone === 'Husky Observer');
    })
    .map(drone => ({
      onSelect: () =>
        handleDroneCameraTypeUpdated(drone as DroneType, values.cameraType),
      value: DRONE_TYPE_TO_DISPLAY_NAME[drone],
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    }));

  return (
    <MissionParametersContainer
      align="start"
      className="mission-parameters-container"
    >
      <MissionNameInput
        formik={formik}
        handleCreateMission={handleCreateMission}
        saveAsOptionIsDisabled={saveAsOptionIsDisabled}
        solarFarmId={solarFarmId}
      />
      <FullWidthTextAlignLeftDiv>
        <FormLabel variant="label_small">Device</FormLabel>
        <DropdownMenu
          menuContentProps={{
            align: 'start',
            sideOffset: 0,
            side: 'bottom',
            style: {
              maxHeight: '70vh',
              width: '450px',
              minWidth: '100px',
              marginTop: 0,
            },
          }}
          items={droneTypeOptions}
          modal={false}
        >
          <DeviceDropdownButton
            size="small"
            variant="secondary"
            id="drone-type-dropdown"
          >
            {DRONE_TYPE_TO_DISPLAY_NAME[values.drone as DroneType]}
          </DeviceDropdownButton>
        </DropdownMenu>
      </FullWidthTextAlignLeftDiv>
      <FullWidthGridSection>
        <DataCollection
          flightMode={flightMode}
          flightModeOptions={flightModeOptions}
          handleClick={handleFlightModeUpdated}
        />
        <QuarterWidthTextInput
          gridColumn={3}
          label={
            values.userUnits === 'Metric'
              ? 'Altitude (meters)'
              : 'Altitude (feet)'
          }
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={
            values.userUnits === 'Metric'
              ? values.altitude
              : values.altitude * METERS_TO_FEET
          }
          disabled={values.userUnits === USER_UNITS[1]}
          onWheel={handleInputScroll}
          onBlur={handleCalculateWaypoints}
          step={NUMBER_INPUT_STEP}
          min={formParameters.altitudeInput.min}
          max={formParameters.altitudeInput.max}
          onChange={handleChange}
          onKeyDownCapture={handleEnterPress}
          name="altitude"
          helperText={errors.altitude}
          helperTextStyle={'left: -224px;'}
          className={`${errors.altitude ? 'is-invalid' : ''}`}
        />
        <MinMax gridColumn={4}>
          <Text variant="paragraph_small">
            Min: {formParameters.altitudeInput.min}
          </Text>
          <Text variant="paragraph_small">
            Max: {formParameters.altitudeInput.max}
          </Text>
        </MinMax>
      </FullWidthGridSection>

      {flightMode !== 'Airplane' && flightMode !== FLIGHT_MODE.GROUND_ROBOT && (
        <SpecialFeaturesSection
          formik={formik}
          formParameters={formParameters}
          markerLngLat={markerLngLat}
          handleAddTakeoffpoint={handleAddTakeoffpoint}
          handleRemoveTakeoffpoint={handleRemoveTakeoffpoint}
          handleInputScroll={handleInputScroll}
          handleCalculateWaypoints={handleCalculateWaypoints}
          handleEnterPress={handleEnterPress}
        />
      )}

      <ParagraphWithTabs>
        <Text
          style={{ display: 'flex', gap: '1em' }}
          variant={'paragraph_small'}
        >
          Course and Camera Angle
        </Text>
        {cameraAngleModeOptions.length > 1 && (
          <Tabs
            handleClick={e => {
              handleChange({
                target: {
                  name: 'cameraAngleMode',
                  value: cameraAngleModeOptions[e],
                },
              });
            }}
            items={cameraAngleModeOptions.map(
              option => CAMERA_ANGLE_MODE_TO_DISPLAY_NAME[option],
            )}
            activeIndex={cameraAngleModeOptions.indexOf(values.cameraAngleMode)}
          />
        )}
      </ParagraphWithTabs>

      {!isContinuousOperationsActive ? (
        <FullWidthGridSection
          style={
            errors.flightAngle && errors.cameraAngle
              ? { marginBottom: '20px' }
              : null
          }
        >
          <Row
            style={{
              gridColumn: '1 / span 2',
              gridRow: '1',
              marginBottom: '-10px',
            }}
          >
            <Text htmlFor="flightAngle" variant="paragraph_small">
              Course Angle (deg){' '}
            </Text>
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip="Course Angle controls the drone's pointing direction relative to north: 0° for north, 90° for east, 180° for south, and 270° for west."
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Course Angle Information"
                icon="CircleInfo"
                size={'medium'}
              />
            </Tooltip>
          </Row>
          <QuarterWidthTextInput
            inputSize="small"
            inputStyle="white-box"
            inputType="number"
            value={values.flightAngle}
            onWheel={handleInputScroll}
            onBlur={handleCalculateWaypoints}
            step={NUMBER_INPUT_STEP}
            min={formParameters.flightAngleInput.min}
            max={formParameters.flightAngleInput.max}
            onChange={handleChange}
            onKeyDownCapture={handleEnterPress}
            gridRow={2}
            name="flightAngle"
            helperText={errors.flightAngle}
            helperTextStyle={'top: 30px;'}
            className={`${errors.flightAngle ? 'is-invalid' : ''}`}
          />
          <MinMax gridColumn={2} marginTop="0px">
            <Text variant="paragraph_small">
              Min: {formParameters.flightAngleInput.min}
            </Text>
            <Text variant="paragraph_small">
              Max: {formParameters.flightAngleInput.max}
            </Text>
          </MinMax>
          <Row
            style={{
              gridColumn: '3 / span 2',
              gridRow: '1',
              marginBottom: '-10px',
            }}
          >
            <Text htmlFor="cameraAngle" variant="paragraph_small">
              Camera Angle (deg){' '}
            </Text>
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip={
                <Stack gutter={'none'}>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    Camera Angle controls the gimbal pitch: -90° pointing
                    straight down (nadir) and 0° parallel to the ground.
                  </Text>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    ATTN: Camera gimbal pitch range may vary depending on device
                    and payload capabilities
                  </Text>
                </Stack>
              }
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Camera Angle Information"
                icon="CircleInfo"
                size={'medium'}
              />
            </Tooltip>
          </Row>
          <QuarterWidthTextInput
            inputSize="small"
            inputStyle="white-box"
            inputType="number"
            value={values.cameraAngle}
            onWheel={handleInputScroll}
            onBlur={handleCalculateWaypoints}
            step={NUMBER_INPUT_STEP}
            min={formParameters.pitchAngleInput.min}
            max={formParameters.pitchAngleInput.max}
            onChange={handleChange}
            onKeyDownCapture={handleEnterPress}
            name="cameraAngle"
            helperText={errors.cameraAngle}
            helperTextStyle={`left: -223px; ${
              errors.flightAngle && errors.cameraAngle ? null : 'top: 30px;'
            }`}
            className={`${errors.cameraAngle ? 'is-invalid' : ''}`}
          />
          <MinMax gridColumn={4} marginTop="0px">
            <Text variant="paragraph_small">
              Min: {formParameters.pitchAngleInput.min}
            </Text>
            <Text variant="paragraph_small">
              Max: {formParameters.pitchAngleInput.max}
            </Text>
          </MinMax>
        </FullWidthGridSection>
      ) : (
        <FollowTracker
          startTime={startTime}
          setStartTime={setStartTime}
          formik={formik}
          handleInputScroll={handleInputScroll}
          handleEnterPress={handleEnterPress}
          handleCalculateWaypoints={handleCalculateWaypoints}
          formParameters={formParameters}
        />
      )}
      <ParagraphWithTabs>
        <Text
          style={{ display: 'flex', gap: '1em' }}
          variant={'paragraph_small'}
        >
          Settings
        </Text>
        <Tabs
          handleClick={e => setSetting(SETTINGS_OPTIONS[e] as Settings)}
          items={SETTINGS_OPTIONS}
          activeIndex={SETTINGS_OPTIONS.indexOf(settings)}
        />
      </ParagraphWithTabs>

      {settings === SETTINGS_OPTIONS[1] && (
        <AdvancedSettingsSection
          formik={formik}
          flightMode={flightMode}
          setDisplayFrontOverlap={setDisplayFrontOverlap}
          setDisplaySideOverlap={setDisplaySideOverlap}
          displayFrontOverlap={displayFrontOverlap}
          displaySideOverlap={displaySideOverlap}
          handleCalculateWaypoints={handleCalculateWaypoints}
          formParameters={formParameters}
          handleInputScroll={handleInputScroll}
          handleEnterPress={handleEnterPress}
          handleDroneCameraTypeUpdated={handleDroneCameraTypeUpdated}
        />
      )}
      {settings === SETTINGS_OPTIONS[0] && showCameraSelectWithZoom && (
        <>
          <Text style={{ marginBottom: '-10px' }} variant={'paragraph_small'}>
            Camera Type
          </Text>

          <Row align="center" justify="space-between" style={{ width: '100%' }}>
            {sensorTypes.map(cameraType => (
              <Button
                key={cameraType}
                size="small"
                variant={
                  values.cameraType === cameraType ? 'primary' : 'secondary'
                }
                style={
                  values.cameraType === cameraType
                    ? { width: '45%', cursor: 'not-allowed' }
                    : { width: '45%' }
                }
                onClick={() =>
                  handleDroneCameraTypeUpdated(
                    values.drone,
                    cameraType as CameraType,
                  )
                }
              >
                {CAMERA_TYPE_TO_DISPLAY_NAME[cameraType]}
              </Button>
            ))}
          </Row>
        </>
      )}
      {flightMode !== FLIGHT_MODE.II_MODE &&
        flightMode !== FLIGHT_MODE.AIRPLANE &&
        flightMode !== FLIGHT_MODE.SQUARE_ORBITAL &&
        flightMode != FLIGHT_MODE.GROUND_ROBOT && (
          <WaypointReductionSection
            intervalometer={intervalometer}
            setIntervalometer={setIntervalometer}
          />
        )}
    </MissionParametersContainer>
  );
};

export default MissionParametersSection;
