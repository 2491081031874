// External Dependancies
import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams, createSearchParams } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { FeatureCollection, Feature, Polygon, Point } from '@turf/helpers';
import { FormikProps } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Mixpanel from '@/shared/utils/mixpanel/utils';
import MixpanelEvents from '@/shared/utils/mixpanel/events';
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Stack } from '@raptormaps/layout';
import theme from '@raptormaps/theme';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

// types
import {
  FlightPlanningFormikValues,
  PointType,
  CalibrationObject,
  IntervalometerObject,
  ActionTriggerType,
  FlightModeType,
  CameraAngleMode,
} from '@/shared/types/missions.d';
import { MissionResponse } from '@raptormaps/raptor-flight-client-ts';

// constants
import {
  FLIGHT_MODE_TO_WAYPOINT_TURN_MODE,
  DEFAULT_INTERVALOMETER,
} from '../constants';
import { FLIGHT_MODE } from './components/MissionParameters/missionParametersConstants';

// components
import MissionParametersSection from './components/MissionParameters/MissionParametersSection';
import DownloadModal from './components/DownloadModal/DownloadModal';
import WaypointsWarningBanner from './components/DownloadModal/WaypointsWarningBanner';
import BackgroundSection from '@/shared/components/Background/Background';
import DigitalTwinSection from '@/shared/components/DigitalTwin/DigitalTwin';
import CalibrationSection from './components/Calibration/CalibrationSection';
import MissionStatsSection from './components/MissionStats';

// hooks & context & utils &css
import { useCreateMission, useUpdateMission } from '@/shared/hooks/useMissions';
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';
import { parseFlightApiErrorDetails } from '@/shared/utils/utils';
import {
  clickDownloadKML,
  clickDownloadDjiKmz,
  clickDownloadDjiWpml,
  createFlightPathKML,
  createWaypointKML,
  createWPML,
  downloadJSON,
} from '../../../utils/flightPlanningUtils';
import { getFieldOfViewSpacing } from '../../../utils/utils';
import { generateMissionInput } from '../utils/missionApiUtils';
import './MissionPlanningSidebar.css';

// Add UTC functionality to dayjs
dayjs.extend(utc);
interface MissionPlanningSidebarProps {
  map?: mapboxgl.Map;
  waypoints: FeatureCollection<Point>;
  polygon: Feature<Polygon>;
  markerLngLat?: PointType;
  flightPathLine: Feature;
  calibration: CalibrationObject;
  formik: FormikProps<FlightPlanningFormikValues>;
  formModified: boolean;
  solarFarmId: number;
  slider: number;
  flightMode: FlightModeType;
  activeRowId: number;
  cameraAngleModeOptions: CameraAngleMode[];
  isContinuousOperationsActive: boolean;
  setSlider: React.Dispatch<React.SetStateAction<number>>;
  handleAddTakeoffpoint: (coordinates?: PointType) => void;
  handleRemoveTakeoffpoint: () => void;
  handleCalculateWaypoints: () => void;
  setCalibration: React.Dispatch<React.SetStateAction<CalibrationObject>>;
  setFlightMode: (flightMode: FlightModeType) => void;
}

export const MissionPlanningSidebar = ({
  map,
  waypoints,
  polygon,
  markerLngLat,
  handleRemoveTakeoffpoint,
  handleAddTakeoffpoint,
  flightPathLine,
  handleCalculateWaypoints,
  calibration,
  setCalibration,
  formik,
  formModified,
  solarFarmId,
  activeRowId,
  flightMode,
  slider,
  setSlider,
  setFlightMode,
  cameraAngleModeOptions,
  isContinuousOperationsActive,
}: MissionPlanningSidebarProps) => {
  const { values, setValues, errors } = formik;
  const navigate = useNavigate();

  // Parse missionId from URL params
  const { missionId } = useParams();
  const parsedMissionId = parseInt(missionId) ? parseInt(missionId) : null;

  const [intervalometer, setIntervalometer] = useState<IntervalometerObject>(
    DEFAULT_INTERVALOMETER,
  );
  const [continuousOperationStartTime, setContinuousOperationStartTime] =
    useState(dayjs.utc().format('YYYY-MM-DDTHH:mm:ss'));
  const { data: solarFarm, isLoading: isLoadingSolarFarm } =
    useSolarFarmById(solarFarmId);

  const createMission = useCreateMission(solarFarm?.id);
  const updateMission = useUpdateMission(parsedMissionId, solarFarm?.id);

  const showSafeTakeoffAltitude = useFeatureIsOn(
    GrowthbookFlags.SAFE_TAKEOFF_ALTITUDE,
  );

  const handleCreateMission = async (
    missionName?: string,
  ): Promise<MissionResponse> => {
    Mixpanel.track(MixpanelEvents.PlanSaveMission);

    if (!solarFarm?.id) {
      console.error(
        `You must select a solar farm in order to save a mission. Not saving progress.`,
      );
      return;
    }

    const safeTakeoffAltitude =
      showSafeTakeoffAltitude && values.safeTakeoffAltitudeBool
        ? values.safeTakeoffAltitude
        : null;

    const input = {
      values,
      safeTakeoffAltitude,
      center: map?.getCenter(),
      markerLngLat: markerLngLat ? [markerLngLat.lng, markerLngLat.lat] : null,
      zoom: map?.getZoom(),
      waypoints,
      polygon,
      flightMode,
    };
    if (missionName) input['missionName'] = missionName;
    const mission = generateMissionInput(input);

    try {
      return await createMission.mutateAsync(mission, {
        onSuccess: result => {
          navigate({
            pathname: `/plan/${result.id}`,
            search: createSearchParams({
              solar_farm_id: result.solarFarmId,
            }).toString(),
          });
          toast.success('Mission Sucessfully Saved', {
            duration: 10000,
          });
        },
      });
    } catch (err) {
      const apiError = await parseFlightApiErrorDetails(
        err,
        'Error saving mission. Please try again.',
      );
      toast.error(apiError, {
        duration: 10000,
      });
      console.error(err);
    }
  };

  const handleUpdateMission = async (): Promise<MissionResponse> => {
    Mixpanel.track(MixpanelEvents.PlanSaveMission);

    if (!solarFarm?.id) {
      console.error(
        `You must select a solar farm in order to save a mission. Not saving progress.`,
      );
      return;
    }

    const safeTakeoffAltitude =
      showSafeTakeoffAltitude && values.safeTakeoffAltitudeBool
        ? values.safeTakeoffAltitude
        : null;

    const input = generateMissionInput({
      values,
      safeTakeoffAltitude,
      center: map?.getCenter(),
      markerLngLat: markerLngLat ? [markerLngLat.lng, markerLngLat.lat] : null,
      zoom: map?.getZoom(),
      waypoints,
      polygon,
      flightMode,
    });
    try {
      return await updateMission.mutateAsync(input);
    } catch (err) {
      toast.error('Error saving mission progress. Please try again.', {
        duration: 10000,
      });
      console.error(err);
    }
  };

  const handleDownload = async (drone, filename) => {
    if (polygon === null) {
      alert(`Draw a Polygon first.`);
      return;
    }

    const safeTakeoffAltitude =
      values.safeTakeoffAltitudeBool || !showSafeTakeoffAltitude
        ? values.safeTakeoffAltitude
        : null;
    const altitudeOffsetObject = {
      offset: values.altitudeOffset,
      active: values.altitudeOffsetBool,
    };
    const intervalModeObject = {
      active: intervalometer.intervalometerBool,
      actionTriggerType: 'multipleDistance' as ActionTriggerType,
      actionTriggerParam: intervalometer.actionTriggerParam,
    };
    // Save mission
    let savedMission: MissionResponse;
    if (
      flightMode != FLIGHT_MODE.GROUND_ROBOT &&
      flightMode != FLIGHT_MODE.SQUARE_ORBITAL
    ) {
      savedMission = parsedMissionId
        ? await handleUpdateMission()
        : await handleCreateMission();
    }

    let kml;
    if (drone === 'Manned Airplane' || drone === 'Husky Observer') {
      kml = await createFlightPathKML(
        flightPathLine,
        values.altitude,
        solarFarm?.id ? solarFarm.id : null,
        missionId ?? savedMission?.id ?? null,
        values.missionName,
        filename,
        polygon,
      );
    } else {
      // generate KML
      kml = await createWaypointKML(
        map,
        polygon,
        flightMode,
        values.altitude,
        values.flightAngle,
        values.flightSpeed,
        values.drone,
        values.terrainFollowBool,
        values.cameraAngle,
        altitudeOffsetObject,
        FLIGHT_MODE_TO_WAYPOINT_TURN_MODE[flightMode],
        waypoints,
        intervalModeObject,
        intervalometer.waypointReductionBool,
        isContinuousOperationsActive,
        values.safeTakeoffAltitudeBool,
        safeTakeoffAltitude,
        continuousOperationStartTime,
      );
    }
    if (drone === 'M3T' || drone === 'M30T' || drone === 'Custom') {
      if (kml === undefined) {
        console.error('KML is undefined. KML: ', kml);
        alert(
          `Flight files could not be generated, please try different parameters.`,
        );
        return;
      }
      // Download the KMZ
      clickDownloadDjiKmz(kml, filename);
    } else if (drone == 'M30T Dock' || drone === 'M3TD Dock') {
      const wpml = createWPML(values.drone, safeTakeoffAltitude);

      if (kml === undefined || wpml === undefined) {
        console.error('KML or WPML undefined. KML: ', kml, 'WPML: ', wpml);
        alert(
          `Flight files could not be generated, please try different parameters.`,
        );
        return;
      }
      // Download the KML and WPML
      clickDownloadDjiWpml(kml, wpml, filename);
    } else if (drone === 'Manned Airplane' || drone === 'Husky Observer') {
      if (kml === undefined) {
        console.error('KML is undefined. KML: ', kml);
        alert(
          `Flight files could not be generated, please try different parameters.`,
        );
        return;
      }
      // Download the KMZ
      clickDownloadKML(kml, filename);
    }
    // Track various mission parms when mission is downloaded
    Mixpanel.track(MixpanelEvents.PlanDownloadMission, {
      drone: values.drone,
      terrain_follow_bool: values.terrainFollowBool,
      flight_mode: flightMode,
      camera_type: values.cameraType,
      speed_control_mode: values.speedControlMode,
      altitude_offset_bool: values.altitudeOffsetBool,
      safe_takeoff_altitude_bool: values.safeTakeoffAltitudeBool,
      intervalometer_bool: intervalometer.intervalometerBool,
      waypoint_reduction_bool: intervalometer.waypointReductionBool,
      calibration_bool: calibration.active,
    });

    // download the JSON
    downloadJSON(
      values.missionName,
      polygon,
      values.altitude,
      altitudeOffsetObject,
      values.flightAngle,
      values.fieldOfViewHorizontal,
      values.fieldOfViewVertical,
      values.frontOverlap,
      markerLngLat ? [markerLngLat.lng, markerLngLat.lat] : null,
      values.flightSpeed,
      values.drone,
      values.terrainFollowBool,
      values.cameraAngle,
      values.sideOverlap,
      map?.getZoom(),
      map?.getCenter(),
      flightMode,
      values.cameraType,
      values.speedControlMode,
      values.cameraInterval,
      solarFarm?.id ? solarFarm.id : null,
      solarFarm?.name ? solarFarm.name : '',
      values.userUnits,
      values.cameraAngleMode,
      waypoints,
      filename,
      getFieldOfViewSpacing(
        values.altitude,
        values.fieldOfViewHorizontal,
        values.frontOverlap,
      ),
      getFieldOfViewSpacing(
        values.altitude,
        values.fieldOfViewVertical,
        values.sideOverlap,
      ),
      safeTakeoffAltitude,
    );
  };

  const calculateCameraIntervalAndFlightSpeed = async () => {
    const calculatedCameraInterval = parseFloat(
      (
        getFieldOfViewSpacing(
          values.altitude,
          values.fieldOfViewHorizontal,
          values.frontOverlap,
        ) / Number(values.flightSpeed)
      ).toFixed(3),
    );

    const calculatedFlightSpeed = parseFloat(
      (
        getFieldOfViewSpacing(
          values.altitude,
          values.fieldOfViewHorizontal,
          values.frontOverlap,
        ) / values.cameraInterval
      ).toFixed(3),
    );

    if (
      values.speedControlMode === 'Flight Speed' &&
      calculatedCameraInterval != values.cameraInterval
    ) {
      setValues({
        ...values,
        cameraInterval: calculatedCameraInterval,
      });
    } else if (
      values.speedControlMode === 'Camera Interval' &&
      calculatedFlightSpeed != values.flightSpeed
    ) {
      setValues({
        ...values,
        flightSpeed: calculatedFlightSpeed,
      });
    }
  };

  // recalculate non-controlling flight and camera interval if controlling changes
  useEffect(() => {
    calculateCameraIntervalAndFlightSpeed();
  }, [
    values.altitude,
    values.fieldOfViewHorizontal,
    values.frontOverlap,
    values.flightSpeed,
    values.cameraInterval,
    values.speedControlMode,
  ]);

  useEffect(() => {
    // Updates the front spacing used as the distance param for distance interval mode
    if (intervalometer.intervalometerBool) {
      const actionTriggerParam =
        Math.floor(
          getFieldOfViewSpacing(
            values.altitude,
            values.fieldOfViewHorizontal,
            values.frontOverlap,
          ) * 1e2,
        ) / 1e2;
      setIntervalometer({
        ...intervalometer,
        actionTriggerParam,
      });
    }
  }, [
    intervalometer.intervalometerBool,
    values.altitude,
    values.fieldOfViewHorizontal,
    values.frontOverlap,
  ]);

  useEffect(() => {
    setIntervalometer(DEFAULT_INTERVALOMETER);
  }, [flightMode]);

  return (
    <Stack className="flight-planning-sidebar">
      {isLoadingSolarFarm ? (
        <ThreeCircles
          height="40"
          width="40"
          color={theme.colors.primary_400}
          visible={true}
          ariaLabel="loading spinner"
        />
      ) : (
        <Text variant="h3" align="left">
          Mission Builder: {solarFarm?.name}
        </Text>
      )}
      <MissionParametersSection
        formik={formik}
        handleCalculateWaypoints={handleCalculateWaypoints}
        flightMode={flightMode}
        setFlightMode={setFlightMode}
        handleCreateMission={handleCreateMission}
        saveAsOptionIsDisabled={
          Object.keys(errors).length > 0 ||
          !solarFarm ||
          !polygon ||
          createMission.isLoading ||
          updateMission.isLoading ||
          flightMode == FLIGHT_MODE.GROUND_ROBOT ||
          flightMode == FLIGHT_MODE.SQUARE_ORBITAL
        }
        cameraAngleModeOptions={cameraAngleModeOptions}
        startTime={continuousOperationStartTime}
        setStartTime={setContinuousOperationStartTime}
        intervalometer={intervalometer}
        setIntervalometer={setIntervalometer}
        markerLngLat={markerLngLat}
        handleAddTakeoffpoint={handleAddTakeoffpoint}
        handleRemoveTakeoffpoint={handleRemoveTakeoffpoint}
        solarFarmId={solarFarmId}
        isContinuousOperationsActive={isContinuousOperationsActive}
      />

      <CalibrationSection
        calibration={calibration}
        setCalibration={setCalibration}
        handleCalculateWaypoints={handleCalculateWaypoints}
      />
      <MissionStatsSection
        formik={formik}
        flightPathLine={flightPathLine}
        flightMode={flightMode}
      />
      <BackgroundSection
        map={map}
        slider={slider}
        setSlider={setSlider}
        solarFarm={solarFarm}
      />
      <DigitalTwinSection
        map={map}
        solarFarm={solarFarm}
        activeRowId={activeRowId}
      />

      {values.terrainFollowBool && (
        <WaypointsWarningBanner
          waypoints={waypoints}
          altitude={values.altitude}
        />
      )}
      <div className="save-progress-container">
        {createMission.isLoading || updateMission.isLoading ? (
          <ThreeCircles
            height="35"
            width="35"
            color={theme.colors.primary_400}
            visible={true}
            ariaLabel="loading spinner"
          />
        ) : (
          <Button
            flex
            variant="tertiary"
            onClick={() =>
              parsedMissionId ? handleUpdateMission() : handleCreateMission()
            }
            disabled={
              Object.keys(errors).length > 0 ||
              !solarFarm ||
              !formModified ||
              !polygon ||
              createMission.isLoading ||
              updateMission.isLoading ||
              flightMode == FLIGHT_MODE.GROUND_ROBOT ||
              flightMode == FLIGHT_MODE.SQUARE_ORBITAL
            }
          >
            {parsedMissionId ? 'Save Progress' : 'Save To Library'}
          </Button>
        )}
      </div>
      <DownloadModal
        disabled={
          Object.keys(errors).length > 0 ||
          !solarFarm ||
          !polygon ||
          createMission.isLoading ||
          updateMission.isLoading
        }
        handleDownload={handleDownload}
        droneType={values.drone}
      />
      <Toaster />
    </Stack>
  );
};

export default MissionPlanningSidebar;
