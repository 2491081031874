import styled from 'styled-components';
import { Stack, Row } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

export const SiteOverviewContainer = styled(Stack)`
  overflow: hidden;
`;

export const Divider = styled(Row)`
  height: 1px;
  background-color: ${theme.colors.grey_300};
  border-bottom: 2px solid ${theme.colors.grey_300};
`;

export const CardPadding = styled.div`
  margin-bottom: 1rem;
`;

export const SidebarBottomPadding = styled.div`
  margin-bottom: 0.5rem;
`;

export const CollapsibleTopPadding = styled.div`
  margin: 0;
`;
