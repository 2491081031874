//external dependancies
import { useQuery, useQueryClient } from 'react-query';
import {
  DevicesApi,
  DeviceTypeFilter,
  DeviceResponse,
} from '@raptormaps/raptor-flight-client-ts';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';

export const GET_DEVICES_PAGINATION_LIMIT = 500;

export const useGetDevices = (
  solarFarmId?: number,
  deviceType?: DeviceTypeFilter,
  limit = GET_DEVICES_PAGINATION_LIMIT,
  offset?: number,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(DevicesApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  const cachedData = queryClient.getQueryData<DeviceResponse[]>([
    'devices',
    orgId,
    solarFarmId,
    deviceType,
    offset,
    limit,
  ]);

  const query = useQuery({
    queryKey: ['devices', orgId, solarFarmId, deviceType, offset, limit],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      return await api.getDevicesBySolarFarmIdV1DevicesGet({
        solarFarmId,
        orgId,
        deviceType: deviceType ? deviceType : undefined,
        limit: limit ? limit : GET_DEVICES_PAGINATION_LIMIT,
        offset: offset ? offset : 0,
      });
    },
    initialData: cachedData,
    enabled: !cachedData,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const cachedQueries = queryClient.getQueriesData<Array<DeviceResponse>>([
    'devices',
    orgId,
    solarFarmId,
  ]);

  const solarFarmDevices = cachedQueries?.reduce((acc, queryData) => {
    if (!queryData) return acc;
    const [key, data] = queryData;
    const offsetKey = key[4] as number;

    if (!data || offsetKey > offset) return acc;
    return [...acc, ...data];
  }, []);

  return {
    ...query,
    data: {
      solarFarmDevices,
      total: query?.data?.length,
    },
  };
};
