import { MissionResponse } from '@raptormaps/raptor-flight-client-ts';

import {
  MissionInput,
  FeatureCollectionModelInput,
} from '@raptormaps/raptor-flight-client-ts';
import { VERSION } from '../constants';

import {
  DroneType,
  FlightModeType,
  CameraType,
  SpeedControlModeType,
  OverlapModeType,
  UserUnitType,
  CameraAngleMode,
  FlightPlanningFormikValues,
  MissionFileType,
  PointType,
} from '@/shared/types/missions.d';

import { getFieldOfViewSpacing } from '../../../utils/utils';
import {
  featureCollection,
  point,
  FeatureCollection,
  Polygon,
  Feature,
} from '@turf/helpers';
import { FLIGHT_MODE } from '../MissionPlanningSidebar/components/MissionParameters/missionParametersConstants';

type FlightModeTypeApi =
  | 'standard'
  | 'comprehensive'
  | 'overview'
  | 'oblique'
  | 'perimeter'
  | 'wiring'
  | 'cablines'
  | 'custom'
  | 'iimode'
  | 'airplane'
  | 'ground robot'
  | 'orthomosaic'
  | 'squareorbital';

type DroneTypeApi =
  | 'm3t'
  | 'm30t'
  | 'm3td_dock'
  | 'custom'
  | 'manned_airplane'
  | 'm30t_dock'
  | 'husky observer';

type SpeedControlModeTypeApi = 'shutter' | 'flight';

type CameraTypeApi = 'rgb' | 'thermal';

type OverlapModeTypeApi = 'overlap' | 'spacing';

type UserUnitsTypeApi = 'imperial' | 'metric';

export const DRONE_TYPE_TO_API_TYPE: { [key in DroneType]: DroneTypeApi } = {
  M3T: 'm3t',
  M30T: 'm30t',
  Custom: 'custom',
  'Manned Airplane': 'manned_airplane',
  'M30T Dock': 'm30t_dock',
  'M3TD Dock': 'm3td_dock',
  'Husky Observer': 'husky observer',
};

export const FLIGHT_MODE_TO_API_TYPE: {
  [key in FlightModeType]: FlightModeTypeApi;
} = {
  Standard: 'standard',
  Comprehensive: 'comprehensive',
  Overview: 'overview',
  Oblique: 'oblique',
  Perimeter: 'perimeter',
  Wiring: 'wiring',
  CABLines: 'cablines',
  Custom: 'custom',
  IIMode: 'iimode',
  Airplane: 'airplane',
  'Ground Robot': 'airplane',
  [FLIGHT_MODE.ORTHO]: 'orthomosaic',
  [FLIGHT_MODE.SQUARE_ORBITAL]: 'squareorbital',
};

export const SPEED_CONTROL_MODE_TO_API_TYPE: {
  [key in SpeedControlModeType]: SpeedControlModeTypeApi;
} = {
  'Camera Interval': 'shutter',
  'Flight Speed': 'flight',
};

export const CAMERA_TYPE_TO_API_TYPE: {
  [key in CameraType]: CameraTypeApi;
} = {
  'RGB Wide': 'rgb',
  Thermal: 'thermal',
};

export const UNITS_TYPE_TO_API_TYPE: {
  [key in UserUnitType]: UserUnitsTypeApi;
} = {
  Imperial: 'imperial',
  Metric: 'metric',
};

export const API_TYPE_TO_DRONE_TYPE: { [key in DroneTypeApi]: DroneType } = {
  m3t: 'M3T',
  m30t: 'M30T',
  custom: 'Custom',
  manned_airplane: 'Manned Airplane',
  m30t_dock: 'M30T Dock',
  m3td_dock: 'M3TD Dock',
  'husky observer': 'Husky Observer',
};

export const API_TYPE_TO_FLIGHT_MODE: {
  [key in FlightModeTypeApi]: FlightModeType;
} = {
  standard: 'Standard',
  comprehensive: 'Comprehensive',
  overview: 'Overview',
  oblique: 'Oblique',
  perimeter: 'Perimeter',
  wiring: 'Wiring',
  cablines: 'CABLines',
  custom: 'Custom',
  iimode: 'IIMode',
  airplane: 'Airplane',
  'ground robot': FLIGHT_MODE.GROUND_ROBOT,
  orthomosaic: FLIGHT_MODE.ORTHO,
  squareorbital: FLIGHT_MODE.SQUARE_ORBITAL,
};

export const API_TYPE_TO_SPEED_CONTROL_MODE: {
  [key in SpeedControlModeTypeApi]: SpeedControlModeType;
} = {
  shutter: 'Camera Interval',
  flight: 'Flight Speed',
};

export const API_TYPE_TO_CAMERA_TYPE: {
  [key in CameraTypeApi]: CameraType;
} = {
  rgb: 'RGB Wide',
  thermal: 'Thermal',
};

export const API_TYPE_TO_OVERLAP_TYPE: {
  [key in OverlapModeTypeApi]: OverlapModeType;
} = {
  overlap: 'Overlap',
  spacing: 'Spacing',
};

export const API_TYPE_TO_UNIT_TYPE: {
  [key in UserUnitsTypeApi]: UserUnitType;
} = {
  imperial: 'Imperial',
  metric: 'Metric',
};

interface GenerateMissionInputParams {
  missionName?: string;
  values: FlightPlanningFormikValues;
  center: PointType;
  markerLngLat?: number[];
  safeTakeoffAltitude?: number;
  zoom: number;
  waypoints: FeatureCollection;
  polygon: Feature<Polygon>;
  flightMode: FlightModeType;
}
export const generateMissionInput = ({
  missionName,
  values,
  safeTakeoffAltitude,
  center,
  markerLngLat,
  zoom,
  waypoints,
  polygon,
  flightMode,
}: GenerateMissionInputParams): MissionInput => {
  const frontSpacing = getFieldOfViewSpacing(
    values.altitude,
    values.fieldOfViewHorizontal,
    values.frontOverlap,
  );

  const sideSpacing = getFieldOfViewSpacing(
    values.altitude,
    values.fieldOfViewVertical,
    values.sideOverlap,
  );

  const flightMap: FeatureCollectionModelInput = featureCollection([polygon]);
  const mapCenter = point([center.lng, center.lat]).geometry;
  const takeoffPoint = markerLngLat ? point(markerLngLat).geometry : null;

  return {
    missionName: missionName ? missionName : values.missionName,
    altitude: values.altitude,
    altitudeOffset: values.altitudeOffset,
    altitudeOffsetBool: values.altitudeOffsetBool,
    cameraInterval: values.cameraInterval,
    cameraAngleMode: values.cameraAngleMode as CameraAngleMode,
    cameraPitchAngle: values.cameraAngle,
    drone: DRONE_TYPE_TO_API_TYPE[values.drone],
    flightAngle: values.flightAngle,
    flightMap,
    flightSpeed: values.flightSpeed,
    frontOverlap: values.frontOverlap,
    frontSpacing: frontSpacing,
    mapCenter,
    mapZoom: zoom,
    mode: FLIGHT_MODE_TO_API_TYPE[flightMode],
    overlapMode: 'overlap',
    safeTakeoffAltitude,
    sensorFovHeight: values.fieldOfViewVertical,
    sensorFovWidth: values.fieldOfViewHorizontal,
    sensorName: CAMERA_TYPE_TO_API_TYPE[values.cameraType],
    sideOverlap: values.sideOverlap,
    sideSpacing: sideSpacing,
    speedControl: SPEED_CONTROL_MODE_TO_API_TYPE[values.speedControlMode],
    takeoffPoint,
    terrainFollow: values.terrainFollowBool,
    units: UNITS_TYPE_TO_API_TYPE[values.userUnits],
    version: VERSION,
    waypoints,
  };
};

export const parseToMissionFileType = (
  missionResponse: MissionResponse,
): MissionFileType => {
  return {
    mission: {
      missionName: missionResponse.missionName,
      flightMode:
        API_TYPE_TO_FLIGHT_MODE[missionResponse.mode as FlightModeTypeApi],
      drone: API_TYPE_TO_DRONE_TYPE[missionResponse.drone as DroneTypeApi],
      altitude: missionResponse.altitude,
      safeTakeoffAltitude: missionResponse.safeTakeoffAltitude as number | null,
      altitudeOffset: missionResponse.altitudeOffset,
      altitudeOffsetBool: missionResponse.altitudeOffsetBool,
      cameraAngle: missionResponse.cameraPitchAngle,
      // This conditional is incase we need to roll back the DB to before we supported cameraAngleMode
      cameraAngleMode: missionResponse.cameraAngleMode
        ? (missionResponse.cameraAngleMode as CameraAngleMode)
        : CameraAngleMode.Manual,
      cameraInterval: missionResponse.cameraInterval,
      flightAngle: missionResponse.flightAngle,
      flightMap: missionResponse.flightMap.features[0],
      flightSpeed: missionResponse.flightSpeed,
      frontOverlap: missionResponse.frontOverlap,
      frontSpacing: missionResponse.frontSpacing,
      mapCenter: {
        lng: missionResponse.mapCenter.coordinates[0],
        lat: missionResponse.mapCenter.coordinates[1],
      },
      mapZoom: missionResponse.mapZoom,
      overlapMode:
        API_TYPE_TO_OVERLAP_TYPE[
          missionResponse.overlapMode as OverlapModeTypeApi
        ],
      sensor: {
        fieldOfViewHeight: missionResponse.sensorFovHeight,
        fieldOfViewWidth: missionResponse.sensorFovWidth,
        name: API_TYPE_TO_CAMERA_TYPE[
          missionResponse.sensorName as CameraTypeApi
        ],
      },
      sideOverlap: missionResponse.sideOverlap,
      sideSpacing: missionResponse.sideSpacing,
      solarFarm: {
        id: missionResponse.solarFarmId,
        name: missionResponse.solarFarmId.toString(),
      },
      speedControlMode:
        API_TYPE_TO_SPEED_CONTROL_MODE[
          missionResponse.speedControl as SpeedControlModeTypeApi
        ],
      takeOffPointCenter: missionResponse.takeoffPoint
        ? {
            lng: missionResponse.takeoffPoint.coordinates[0],
            lat: missionResponse.takeoffPoint.coordinates[1],
          }
        : null,
      terrainFollowBool: missionResponse.terrainFollow,
      userUnits:
        API_TYPE_TO_UNIT_TYPE[missionResponse.units as UserUnitsTypeApi],
    },
    version: VERSION,
    waypoints: null,
  };
};
