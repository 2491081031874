import { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';
import { useUrlSearchParams } from '@/shared/hooks/useUrlParams';
import moment from 'moment';
import { debounce } from 'lodash';
import {
  useGetMissions,
  useDeleteMission,
  GET_MISSIONS_PAGINATION_LIMIT,
} from '@/shared/hooks/useMissions';
import Mixpanel from '@/shared/utils/mixpanel/utils';
import MixpanelEvents from '@/shared/utils/mixpanel/events';
import BreadcrumbNavigation from '@/shared/components/BreadCrumbs/Breadcrumbs';
import { NoResults } from '@/shared/components/EmptyTable/EmptyTable';
import { Table } from '@raptormaps/table';
import { Button } from '@raptormaps/button';
import { MissionResponseLite } from '@raptormaps/raptor-flight-client-ts';
import './MissionTable.css';
import {
  API_TYPE_TO_DRONE_TYPE,
  API_TYPE_TO_FLIGHT_MODE,
} from '../MissionPlanner/components/MissionPlanningForm/utils/missionApiUtils';
import { formatUsernameForDisplay } from './missionTable.utils';
import { COLUMNS } from './missionTableConstants';
import { FormattedMission } from './missionTable.d';
import ArchiveModal from './components/ArchiveModal/ArchiveModal';
import {
  DRONE_TYPE_TO_DISPLAY_NAME,
  FLIGHT_MODE_TO_DISPLAY_NAME,
} from '@/shared/constants/missionsConstants';
import { PageProps } from '@/shared/types/page';
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';
import RowMoreMenu from '@/shared/components/RowMoreMenu/RowMoreMenu';

const MissionTable = ({ solarFarmId }: PageProps) => {
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];

  const { data: solarFarm } = useSolarFarmById(solarFarmId);

  const solarFarmName = useUrlSearchParams<string>('solar_farm_name', 'string');
  const searchQueryParam = useUrlSearchParams<string>('search', 'string') || '';
  const [search, setSearch] = useState<string>(searchQueryParam);
  const [mission, setMission] = useState<MissionResponseLite | null>(null);
  const [offset, setOffset] = useState(0);

  const {
    data: missionsResponse,
    isFetching,
    isLoading,
  } = useGetMissions(solarFarmId, searchQueryParam, offset);

  const deleteMission = useDeleteMission(solarFarmId);

  useEffect(() => {
    Mixpanel.track(MixpanelEvents.MissionsPageLoaded);
  }, []);

  useEffect(() => {
    if (!search) return;
    handleSearch(search);
    return () => {
      handleSearch.cancel();
    };
  }, [search]);

  const formattedMissions =
    missionsResponse?.missions?.map(mission => ({
      missionName: mission.mission_name,
      inspectionType:
        FLIGHT_MODE_TO_DISPLAY_NAME[
          API_TYPE_TO_FLIGHT_MODE[mission.mode.toString()]
        ],
      droneModel:
        DRONE_TYPE_TO_DISPLAY_NAME[
          API_TYPE_TO_DRONE_TYPE[mission.drone.toString()]
        ],
      lastModifiedTime: moment(mission.updated_tsecs * 1000).format(
        'MMM D, YYYY, h:mm a',
      ),
      lastModifiedBy: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {formatUsernameForDisplay(mission.updator.name)}{' '}
          <RowMoreMenu record={mission} setRecord={setMission} />
        </div>
      ),
      id: mission.id,
    })) || [];

  const handleSearch = debounce((query: string): void => {
    setSearchParams({
      solar_farm_id: solarFarmId?.toString() || '',
      solar_farm_name: solarFarmName || '',
      search: query,
    });
    setOffset(0);
  }, 750);

  const handleSubmit = () => {
    deleteMission.mutate(mission.id, {
      onSuccess: () => {
        setOffset(0);
        setMission(null);
        Mixpanel.track(MixpanelEvents.MissionsArchive);
      },
    });
  };

  const handleClose = () => {
    setMission(null);
  };

  return (
    <div className="library">
      <div className="library-header">
        <BreadcrumbNavigation
          crumbs={[
            ['Raptor Flight', '/'],
            ['Library', '/missions'],
          ]}
        />
      </div>
      <Link to="/">
        <Button
          className="library-back"
          icon="ArrowLeft"
          iconPosition="left"
          size="small"
          variant="secondary"
          onClick={() => Mixpanel.track(MixpanelEvents.MissionsBack)}
        >
          {' '}
          Back
        </Button>
      </Link>
      <h2 className="library-title">Mission Library</h2>
      <div className="library-toolbar">
        <div className="library-typeahead-container">
          <h3>{solarFarm?.name}</h3>
        </div>
        <Link
          className="library-upload"
          to={'/upload?solar_farm_id=' + solarFarmId}
        >
          <Button
            icon="ArrowUpTray"
            iconPosition="left"
            size="small"
            variant="secondary"
            onClick={() => Mixpanel.track(MixpanelEvents.MissionsUpload)}
          >
            {' '}
            Upload Mission
          </Button>
        </Link>
      </div>

      {/* This can be enabled when we add filters */}
      <div>
        <Button
          className="library-filter"
          icon="Sliders"
          iconPosition="left"
          size="medium"
          variant="secondary"
          disabled={true}
        >
          Filters
        </Button>
      </div>
      <Table
        className="library-table"
        cols={COLUMNS}
        data={formattedMissions}
        enableSearch={true}
        fetchData={async () => {
          // Fetch data executes on initial render and when handleSearch is called.
          // This prevents the default behavior.
          if (isLoading || isFetching || search !== searchQueryParam) return;
          setOffset(offset + GET_MISSIONS_PAGINATION_LIMIT);
        }}
        handleSearch={e => setSearch(e.target.value)}
        handleRowClick={({ original }: { original: FormattedMission }) => {
          navigate({
            pathname: `/plan/${original.id}`,
            search: createSearchParams({
              solar_farm_id: solarFarmId.toString(),
            }).toString(),
          });
        }}
        noDataMessage={
          isFetching ? (
            <div className="fetching-message">Retrieving Missions...</div>
          ) : (
            <NoResults />
          )
        }
        paginationPageSize={GET_MISSIONS_PAGINATION_LIMIT}
        searchPlaceholder={'Search by mission name'}
        searchValue={search || ''}
        shadow={true}
        tableHeight={600}
        totalRowCount={
          isLoading || isFetching || !missionsResponse?.total
            ? 0
            : missionsResponse.total
        }
      />
      {mission && (
        <ArchiveModal handleSubmit={handleSubmit} handleClose={handleClose} />
      )}
    </div>
  );
};

export default MissionTable;
