import { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Mixpanel from './shared/utils/mixpanel/utils';
import { useAppContext } from './shared/context/AppContext';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { growthbookInstance } from './shared/utils/GrowthbookUtils';
import { datadogRum } from '@datadog/browser-rum';
import { useFeatureFlags } from './shared/hooks/useFeatureFlags';
import './App.css';
import '../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '../node_modules/mapbox-gl/dist/mapbox-gl.css';
import { useUrlSearchParams } from './shared/hooks/useUrlParams';

// Components
import Denied from './pages/Denied/Denied';
import SideNav from './shared/components/SideNav/SideNav';
import { ErrorBoundary } from 'react-error-boundary';
import { PageLoader } from './shared/components/PageLoader/PageLoader';
import FallbackComponent from './pages/Fallback/Fallback';
import { useRoutes } from './shared/hooks/useRoutes';

if (window.DD_RUM_ENABLED) {
  datadogRum.init({
    applicationId: '68bf4c52-8fb1-4897-807f-9dafd4f56815',
    clientToken: 'pubd326354812c4e5660f464ed376565208',
    site: 'datadoghq.com',
    service: 'raptor-flight',
    env: window.REACT_APP_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: window.DD_RUM_SAMPLE_RATE,
    sessionReplaySampleRate: window.DD_RUM_SAMPLE_RECORD_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

export const App = () => {
  const { user, error } = useAppContext();
  useEffect(() => {
    if (!user) return;
    Mixpanel.identify(user?.id?.toString());
    Mixpanel.people.set({ $email: user.email });
  }, [user]);

  if (error) {
    return <FallbackComponent />;
  } else if (!user) {
    return <PageLoader />;
  }
  return <AuthenticatedApp />;
};

export const AuthenticatedApp = () => {
  const { features, isLoadingFeatures } = useFeatureFlags();
  const [loading, setLoading] = useState(true);
  const { activeOrg, user } = useAppContext();
  const solarFarmId = useUrlSearchParams<number>('solar_farm_id', 'number');
  const routes = useRoutes({ solarFarmId });

  // Load features from the GrowthBook API and initialize the SDK
  useEffect(() => {
    growthbookInstance.loadFeatures().finally(() => {
      setLoading(false);
    });

    if (user) {
      growthbookInstance.setAttributes({
        id: user.id,
      });
    }
  }, [user]);

  if (loading || isLoadingFeatures || !activeOrg) {
    return <PageLoader />;
  }
  const isAuthorized = features ? 'RAPTOR-FLIGHT' in features : null;

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <SideNav />
        <div className="main-content">
          <Routes>
            {isAuthorized ? (
              <>
                ?
                {routes.map(route => (
                  <Route
                    path={route.path}
                    key={route.path}
                    element={route.render}
                  />
                ))}
                <Route path="/denied" element={<Denied />} />
                <Route path="/*" element={<Navigate to="/denied" />} />
              </>
            ) : (
              <>
                <Route path="/denied" element={<Denied />} />
                <Route path="/*" element={<Navigate to="/denied" />} />
              </>
            )}
          </Routes>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <PageLoader />,
});
