// Styled Components
import { Card, CardTitle } from '@raptormaps/card';
import { Stack, Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Pill, PillType } from '@raptormaps/pill';
import { Gutter } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import { IconButton } from '@raptormaps/button';
import theme from '@raptormaps/theme';
import { CardPadding } from './SiteOverview.styled';

// Router
import { Link } from 'react-router-dom';

// Mock Data
import {
  MockDevice,
  aircraftStatusPillColorMap,
  deviceStatusPillColorMap,
} from '../../shared/constants/mockDeviceData';

interface DeviceCardProps {
  device: MockDevice;
}

const DeviceCard = ({ device }: DeviceCardProps) => {
  const handleDisplayLiveStreamAvailability = () => {
    if (device.liveStreamStatus) {
      return (
        <Link to={`/live-stream/${device.id}`}>
          <Row align="center" gutter={Gutter.XXS}>
            <Text variant="paragraph_medium_bold"> View Live Stream </Text>
            <IconButton
              aria-label="enter-live-stream-button"
              icon="ChevronRight"
              variant="tertiary"
              size="small"
            />
          </Row>
        </Link>
      );
    } else {
      return (
        <Row align="center" gutter={Gutter.XXS}>
          <Text variant="paragraph_medium_bold" color={theme.colors.grey_300}>
            Not Available
          </Text>
          <IconButton
            aria-label="enter-live-stream-button"
            icon="VideoSlash"
            variant="tertiary"
            size="small"
            disabled
          />
        </Row>
      );
    }
  };

  return (
    <>
      <Card>
        <Stack gutter={Gutter.L}>
          <Row align="center" justify="space-between">
            <Row align="center" justify="flex-start" gutter={Gutter.XXS}>
              <Icon icon="HouseDrone" />
              <CardTitle variant="paragraph_medium_bold" align="left">
                {`${device.name} ID#${device.id}`}
              </CardTitle>
            </Row>
            <Pill
              iconPosition="right"
              size="small"
              variant={deviceStatusPillColorMap[device.dockStatus] as PillType}
            >
              {device.dockStatus}
            </Pill>
          </Row>
          <Row align="center" justify="space-between">
            <Text variant="paragraph_medium" align="left">
              Aircraft status
            </Text>
            <Pill
              iconPosition="right"
              size="small"
              variant={
                aircraftStatusPillColorMap[device.aircraftStatus] as PillType
              }
            >
              {device.aircraftStatus}
            </Pill>
          </Row>
          <Row align="center" justify="space-between">
            <Text variant="paragraph_medium" align="left">
              Live Stream
            </Text>
            {handleDisplayLiveStreamAvailability()}
          </Row>
        </Stack>
      </Card>
      <CardPadding />
    </>
  );
};

export default DeviceCard;
