// external dependancies
import { ChangeEvent, useState, useRef, ReactElement, useEffect } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
// raptor UI
import { Icon } from '@raptormaps/icon';
import { Button } from '@raptormaps/button';

// internal dependancies
import { useAppContext } from '@/shared/context/AppContext';
import { CameraAngleMode, MissionFileType } from '@/shared/types/missions.d';
import upload_image from '../../../../assets/upload_icon.png';
import './Uploader.css';
import { jsonValidation } from './uploaderUtils';

interface UploaderProps {
  solarFarmId?: number;
}

function Uploader({ solarFarmId }: UploaderProps) {
  const navigate = useNavigate();
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [validatedMission, setValidatedMission] =
    useState<MissionFileType | null>(null);
  const [validationMessage, setValidationMessage] = useState<ReactElement>(
    <p className="message">Supported Format: JSON</p>,
  );

  const { setFlightMission } = useAppContext();

  useEffect(() => {
    handleFileValidation();
  }, [file]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setValidationMessage(<p>Supported Format: JSON</p>);
    if (inputRef) {
      inputRef.current.value = '';
    }
  };

  // so the Browse button will trigger the input
  const inputRef = useRef(null);
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileValidation = () => {
    if (file) {
      //parse the JSON flight plan file here
      const reader = new FileReader();
      reader.onload = async event => {
        const content = event.target?.result as string;
        try {
          const jsonData: MissionFileType = JSON.parse(content);
          // TODO: Remove this as soon as the version is upgraded. This is a temporary fix to allow negative flight angles in previously downloaded JSON files.
          if (typeof jsonData.mission.flightAngle === 'number') {
            if (
              jsonData.mission.flightAngle >= -360 &&
              jsonData.mission.flightAngle < 0
            ) {
              jsonData.mission.flightAngle = jsonData.mission.flightAngle + 360;
            }
          }
          // Default to manual mode when a mission without the cameraAngleMode field is loaded
          if (!jsonData.mission.cameraAngleMode) {
            jsonData.mission.cameraAngleMode = CameraAngleMode.Manual;
          }

          const errors = await jsonValidation(jsonData, solarFarmId);
          if (errors.length > 0) {
            const errorsWithHTML = errors.map(([mykey, errorMsg]) => (
              <li key={mykey}>
                <p className="invalid">{errorMsg}</p>
              </li>
            ));

            setValidationMessage(<ul>{errorsWithHTML}</ul>);
          } else {
            setValidatedMission(jsonData);
            setValidationMessage(
              <div className="valid">
                {' '}
                <Icon icon="Check" size="medium" /> &nbsp; Ready for Upload
              </div>,
            );
          }
        } catch (error) {
          setValidationMessage(
            <p className="Error">{`TypeError: Cannot parse. Please upload a valid JSON file`}</p>,
          );
        }
      };
      reader.readAsText(file);
    }
  };

  const handleContinueClick = () => {
    // update context with the validated mission
    setFlightMission(validatedMission);
    navigate({
      pathname: '/plan',
      search: createSearchParams({
        solar_farm_id:
          validatedMission.mission?.solarFarm?.id?.toString() || '',
      }).toString(),
    });
  };

  return (
    <div className="Uploader">
      <div className="Uploader-Card">
        <div className="Uploader-Card-heading"> Upload Flight Plan</div>
        <p className="Uploader-Card-text">
          Upload exisiting flight plan from your computer
        </p>
        <form
          className="Uploader-Card-file-upload"
          onDragEnter={handleDrag}
          onSubmit={e => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            className="Uploader-Card-input"
            onChange={handleFileChange}
            multiple={false}
            accept="application/JSON"
          />
          <label
            className={
              dragActive
                ? 'Uploader-Card-label drag-active'
                : `Uploader-Card-label ${file ? 'download' : ''}`
            }
            htmlFor="input-file-upload"
          >
            {file ? (
              <div
                className={
                  validationMessage.props.className != 'valid'
                    ? 'Error'
                    : 'Valid'
                }
              >
                <div className="Uploader-file">
                  <p>{file.name}</p>
                  <Button
                    size="small"
                    variant="tertiary"
                    onClick={handleDelete}
                  >
                    <Icon icon="TrashCan" size="medium" />
                  </Button>
                </div>
                <div>{validationMessage}</div>
              </div>
            ) : (
              <div className="Uploader-Card-no-file">
                <img src={upload_image} alt="upload your JSON" />
                <p>
                  Drag & drop files or
                  <button onClick={onButtonClick} className="upload-button">
                    Browse
                  </button>
                </p>
                {validationMessage}
              </div>
            )}
          </label>
          {dragActive && (
            <div
              className="Uploader-Card-drag"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
          {file ? (
            <div className="Uploader-controls">
              <Button
                size="small"
                onClick={handleDelete}
                type={'button'}
                variant="tertiary"
              >
                Cancel
              </Button>
              <Button
                disabled={validationMessage.props.className != 'valid'}
                size="small"
                onClick={handleContinueClick}
              >
                Continue
              </Button>
            </div>
          ) : (
            ''
          )}
        </form>
      </div>
    </div>
  );
}

export default Uploader;
