// External Dependancies
import { FormikProps } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// Raptor UI
import { TextInput } from '@raptormaps/text-input';
import { Text } from '@raptormaps/text';
import { Row, Stack } from '@raptormaps/layout';
import { Tooltip } from '@raptormaps/tooltip';
import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';

// Internal Dependancies
import {
  FollowTrackerContainer,
  FollowTrackerTextInputLabel,
  FollowTrackerMinMax,
} from './FollowTracker.styles';
import { QuarterWidthTextInput } from '../../MissionParametersSection.styles';
import {
  FlightPlanningFormikValues,
  FormParameters,
} from '@/shared/types/missions';
import { NUMBER_INPUT_STEP } from '../../../../../constants';

// Add UTC functionality to dayjs
dayjs.extend(utc);

interface FollowTrackerProps {
  startTime: string;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  formik: FormikProps<FlightPlanningFormikValues>;
  formParameters: FormParameters;
  handleInputScroll: (event: React.WheelEvent<HTMLInputElement>) => void;
  handleCalculateWaypoints: () => void;
  handleEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const FollowTracker = ({
  startTime,
  setStartTime,
  formik,
  formParameters,
  handleInputScroll,
  handleCalculateWaypoints,
  handleEnterPress,
}: FollowTrackerProps) => {
  const { values, handleChange, errors } = formik;

  return (
    <>
      <Row>
        <FollowTrackerContainer>
          <Row>
            <Text htmlFor="followTrackerTime" variant="paragraph_small">
              {' '}
              Mission Start Time (UTC){' '}
            </Text>
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip={
                <Stack gutter={'none'}>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    Follow Tracker automatically aligns the camera angle with
                    the angle of the sun, ensuring it always points at the
                    trackers.
                  </Text>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    ATTN: requires setting the correct flight start time.
                  </Text>
                </Stack>
              }
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Follow Tracker Information"
                icon="CircleInfo"
                size={'medium'}
                style={{ marginLeft: '-5px' }}
              />
            </Tooltip>
          </Row>
          <TextInput
            style={{ width: '225px' }}
            placeholder="DD/MM/YYYY hh:mm"
            inputSize="small"
            inputStyle="grey-box"
            type="datetime-local"
            step={1}
            value={startTime}
            onChange={e => setStartTime(e.target.value)}
            name="followTrackerTime"
          />
          <Text variant={'paragraph_small'}>
            Current UTC time: {dayjs.utc().format('YYYY-MM-DD hh:mm:ss A')}
          </Text>
        </FollowTrackerContainer>
      </Row>
      <Row gutter="None" style={{ width: '50%' }}>
        <FollowTrackerTextInputLabel>
          <Text htmlFor="flightAngle" variant="paragraph_small">
            Course Angle (deg){' '}
          </Text>
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip="Course Angle controls the drone's pointing direction relative to north: 0° for north, 90° for east, 180° for south, and 270° for west."
            shouldWrapChildren={true}
          >
            <Icon
              aria-label="Course Angle Information"
              icon="CircleInfo"
              size={'medium'}
            />
          </Tooltip>
        </FollowTrackerTextInputLabel>
        <QuarterWidthTextInput
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={values.flightAngle}
          onWheel={handleInputScroll}
          onBlur={handleCalculateWaypoints}
          step={NUMBER_INPUT_STEP}
          min={formParameters.flightAngleInput.min}
          max={formParameters.flightAngleInput.max}
          onChange={handleChange}
          onKeyDownCapture={handleEnterPress}
          gridColumn={1}
          gridRow={2}
          style={{ width: '75%' }}
          name="flightAngle"
          helperText={errors.flightAngle}
          helperTextStyle={'top: 30px;'}
          className={`${errors.flightAngle ? 'is-invalid' : ''}`}
        />
        <FollowTrackerMinMax>
          <Text variant="paragraph_small">
            Min: {formParameters.flightAngleInput.min}
          </Text>
          <Text variant="paragraph_small">
            Max: {formParameters.flightAngleInput.max}
          </Text>
        </FollowTrackerMinMax>
      </Row>
    </>
  );
};

export default FollowTracker;
