export const DJI_DRONE_VALUES = {
  M3T: {
    droneEnumValue: 77,
    payloadEnumValue: 67,
    finishAction: 'noAction',
    imageFormat: 'wide,ir',
  },
  M30T: {
    droneEnumValue: 67,
    payloadEnumValue: 53,
    finishAction: 'noAction',
    imageFormat: 'wide,ir',
  },
  'M30T Dock': {
    droneEnumValue: 67,
    payloadEnumValue: 53,
    finishAction: 'goHome',
    imageFormat: 'wide,ir',
  },
  'M3TD Dock': {
    droneEnumValue: 91,
    payloadEnumValue: 81,
    finishAction: 'goHome',
    imageFormat: 'visable,ir',
  },
};
