import mapboxgl from 'mapbox-gl';

interface Image {
  id: string;
  location: string;
}

const images: Image[] = [
  { id: 'custom-marker', location: '/mapbox-icons/plane-marker.png' },
  { id: 'inverter-off', location: '/mapbox-icons/inverter-off.png' },
  { id: 'combiner-off', location: '/mapbox-icons/combiner-off.png' },
  { id: 'pyranometer-off', location: '/mapbox-icons/pyranometer-off.png' },
];

export const initImages = (map: mapboxgl.Map) => {
  images.forEach(img => {
    map.loadImage(img.location, (error, image) => {
      if (error) {
        throw error;
      }
      map.addImage(img.id, image);
    });
  });
};
