// External Dependancies
import { useState, useEffect, useMemo, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { useBlocker } from 'react-router-dom';
import { FeatureCollection, Feature, Polygon, Point } from '@turf/helpers';
import toast from 'react-hot-toast';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

// Internal Dependancies
import Mixpanel from '@/shared/utils/mixpanel/utils';
import MixpanelEvents from '@/shared/utils/mixpanel/events';
// components
import Map from '@/shared/components/Map/Map';
import { useAppContext } from '@/shared/context/AppContext';
import { FormikProvider, useFormik } from 'formik';
import {
  DEFAULT_FLIGHT_MISSION_FILE,
  DEFAULT_CALIBRATION,
} from './components/MissionPlanningForm/constants';
import { PageProps } from '@/shared/types/page';
import { ACCEPTED_ERRORS } from '@/shared/constants/missionsConstants';
// types
import {
  MissionFileType,
  FlightPlanningFormikValues,
  PointType,
  CalibrationObject,
} from '@/shared/types/missions.d';
import { FlightModeType, CameraAngleMode } from '@/shared/types/missions.d';
import { calculateWaypointData } from './utils/flightPlanningUtils';
import { useUrlParams } from '@/shared/hooks/useUrlParams';
import { useGetMission } from '@/shared/hooks/useMissions';

import MissionPlanningSidebar from './components/MissionPlanningForm/MissionPlanningSidebar/MissionPlanningSidebar';
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';
import {
  DroneFlightPlanSchema,
  MannedFlightPlanSchema,
} from './components/MissionPlanningForm/FlightPlanSchema';
import { ConfirmationModal } from '../../shared/components/ConfirmationModal/ConfirmationModal';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';
import { FLIGHT_MODE } from './components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/missionParametersConstants';

const MissionPlanner = ({ solarFarmId }: PageProps) => {
  const { flightMission, clearMission, activeOrg } = useAppContext();
  const missionId = useUrlParams<number>('missionId', 'number');
  const allowContinuousOperationsMode = useFeatureIsOn(
    GrowthbookFlags.DOCK_CONT_OP,
  );

  const { data: solarFarm, error: getSolarFarmError } =
    useSolarFarmById(solarFarmId);

  // TODO: render loading spinner while fetching mission
  const { data: apiMission, error: getMissionError } = useGetMission(
    missionId,
    solarFarmId,
  );

  const initialMission = useMemo<MissionFileType>(
    () => flightMission || apiMission || DEFAULT_FLIGHT_MISSION_FILE,
    [apiMission],
  );

  const [map, setMap] = useState<mapboxgl.Map>(null);
  const [markerLngLat, setMarkerLngLat] = useState<PointType>(null);
  const [flightPathLine, setFlightPathLine] = useState<Feature>(null);
  const [waypoints, setWaypoints] = useState<FeatureCollection<Point>>(null);
  const [polygon, setPolygon] = useState<Feature<Polygon>>(null);
  const [slider, setSlider] = useState<number>(25);
  const [calibration, setCalibration] =
    useState<CalibrationObject>(DEFAULT_CALIBRATION);
  const [flightMode, setFlightMode] = useState<FlightModeType>(
    initialMission.mission.flightMode,
  );
  const [activeRowId, setActiveRowId] = useState<number | null>(null);

  const formik = useFormik<FlightPlanningFormikValues>({
    initialValues: {
      missionName: initialMission.mission.missionName || '',
      drone: initialMission.mission.drone,
      cameraType: initialMission.mission.sensor.name,
      userUnits: initialMission.mission.userUnits,
      altitude: initialMission.mission.altitude,
      altitudeOffset: initialMission.mission.altitudeOffset,
      altitudeOffsetBool: initialMission.mission.altitudeOffsetBool,
      safeTakeoffAltitude: initialMission.mission.safeTakeoffAltitude || 50,
      safeTakeoffAltitudeBool: Boolean(
        initialMission.mission.safeTakeoffAltitude,
      ),
      fieldOfViewHorizontal: initialMission.mission.sensor.fieldOfViewWidth,
      fieldOfViewVertical: initialMission.mission.sensor.fieldOfViewHeight,
      frontOverlap: initialMission.mission.frontOverlap,
      sideOverlap: initialMission.mission.sideOverlap,
      cameraAngle: initialMission.mission.cameraAngle,
      flightAngle: initialMission.mission.flightAngle,
      speedControlMode: initialMission.mission.speedControlMode,
      cameraInterval: initialMission.mission.cameraInterval,
      flightSpeed: initialMission.mission.flightSpeed,
      terrainFollowBool: initialMission.mission.terrainFollowBool,
      cameraAngleMode: initialMission.mission.cameraAngleMode,
    },
    enableReinitialize: true,

    validate: values => {
      // custom validation
      if (values.terrainFollowBool && !markerLngLat) {
        return {
          terrainFollowBool:
            'Required field! Please set a relative altitude point or disable Terrain Following.',
        };
      }
    },
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }, 400);
    },
    validationSchema:
      flightMode === 'Airplane' || flightMode === FLIGHT_MODE.GROUND_ROBOT
        ? MannedFlightPlanSchema
        : DroneFlightPlanSchema,
  });

  const { values, validateForm } = formik;

  /* Resets the state for the data used to render the mission and map elements. */
  const resetState = () => {
    clearMission();
    setPolygon(null);
    setFlightPathLine(null);
    setWaypoints(null);
    setCalibration(DEFAULT_CALIBRATION);
    setMarkerLngLat(null);
  };

  /* Manage Continuous Opperations */
  const continuousOperationsEnabled =
    allowContinuousOperationsMode &&
    values.drone != 'Manned Airplane' &&
    values.drone != 'Husky Observer' &&
    (flightMode == 'Standard' || flightMode == 'Comprehensive');

  const cameraAngleModeOptions = continuousOperationsEnabled
    ? [CameraAngleMode.Manual, CameraAngleMode.FollowTracker]
    : [CameraAngleMode.Manual];

  const isContinuousOperationsActive =
    values.cameraAngleMode === CameraAngleMode.FollowTracker;

  const handleCalculateWaypoints = async () => {
    const formErrors = await validateForm(values);
    const errors = Object.keys(formErrors);
    if (!map || !errors.every(error => ACCEPTED_ERRORS.includes(error))) {
      return;
    } else if (!polygon) {
      setWaypoints(null);
      setFlightPathLine(null);
      setCalibration({ ...calibration, nonCalibratedFlightPath: null });
      return;
    }
    const result = await calculateWaypointData(
      map,
      flightMode,
      polygon,
      values.altitude,
      values.fieldOfViewHorizontal,
      values.fieldOfViewVertical,
      values.cameraAngle,
      values.flightAngle,
      values.sideOverlap,
      values.frontOverlap,
      values.safeTakeoffAltitude,
      values.safeTakeoffAltitudeBool,
      markerLngLat ? [markerLngLat.lng, markerLngLat.lat] : null,
      calibration.active,
      calibration.rhumbBearing,
      calibration.rhumbDistance,
      isContinuousOperationsActive,
      values.cameraInterval,
      values.flightSpeed,
    );

    setWaypoints(result.waypoints);
    setFlightPathLine(result.flightPath);
    setCalibration({
      ...calibration,
      nonCalibratedFlightPath: result.nonCalibratedFlightPath,
    });
  };

  // Initialize polygon and markerLngLat with mission data
  useEffect(() => {
    if (!map) return;
    const { mission } = initialMission;

    if (mission.drone === 'Manned Airplane') {
      setFlightMode('Airplane');
    } else if (mission.drone === 'Husky Observer') {
      setFlightMode(FLIGHT_MODE.GROUND_ROBOT);
    } else {
      setFlightMode(mission.flightMode);
    }

    if (mission.flightMap && polygon !== mission.flightMap) {
      setPolygon(mission.flightMap);
    }

    if (mission.takeOffPointCenter) {
      setMarkerLngLat(mission.takeOffPointCenter);
    }
  }, [solarFarm, initialMission, map]);

  // Update waypoints on values changing
  useEffect(() => {
    if (!map) return;
    handleCalculateWaypoints();
  }, [
    map,
    polygon,
    markerLngLat,
    flightMode,
    calibration.active,
    values.drone,
    values.cameraAngleMode,
  ]);

  // Clear mission from context on unmount of the page
  useEffect(() => {
    return () => {
      if (flightMission && map) {
        clearMission();
      }
    };
  }, [map]);

  // Render error if the getSolarFarmById query fails
  useEffect(() => {
    if (getSolarFarmError) {
      toast.error(`Error fetching solar farm with ID ${solarFarmId}`);
    }
  }, [getSolarFarmError]);

  useEffect(() => {
    if (getMissionError) {
      toast.error(`Error fetching mission with ID ${missionId}`);
    }
  }, [getMissionError]);

  const { takeOffPointCenter } = initialMission.mission;
  const isStateDirty =
    formik.dirty ||
    polygon !== initialMission.mission.flightMap ||
    (!markerLngLat && !!takeOffPointCenter) ||
    (!takeOffPointCenter && !!markerLngLat) ||
    markerLngLat?.lat !== takeOffPointCenter?.lat ||
    markerLngLat?.lng !== takeOffPointCenter?.lng;

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      const currentBasePathname = currentLocation.pathname.split('/')[1];

      // If we are not on the plan page, don't block
      if (currentBasePathname !== 'plan') return false;
      // If the form is not dirty, don't block
      if (!isStateDirty) return false;
      // Handles org changing. If the state contains an orgId that is different from the active org,
      // we are changing orgs. Don't block.
      if (
        nextLocation.state?.orgId &&
        nextLocation.state.orgId !== activeOrg.id
      )
        return false;

      // If the pathname is changing and state is dirty, block
      const nextBasePathname = nextLocation.pathname.split('/')[1];
      if (currentBasePathname !== nextBasePathname) return true;
    },
    [isStateDirty, flightMode, activeOrg.id],
  );

  const blocker = useBlocker(shouldBlock);
  return (
    <FormikProvider value={formik}>
      <MissionPlanningSidebar
        slider={slider}
        setSlider={setSlider}
        map={map}
        waypoints={waypoints}
        polygon={polygon}
        markerLngLat={markerLngLat}
        handleRemoveTakeoffpoint={() => {
          setMarkerLngLat(null);
        }}
        handleAddTakeoffpoint={(coordinates?: PointType) => {
          Mixpanel.track(MixpanelEvents.PlanCreateTakeoffPoint);
          setMarkerLngLat(coordinates || map?.getCenter());
        }}
        handleCalculateWaypoints={handleCalculateWaypoints}
        setCalibration={setCalibration}
        calibration={calibration}
        flightPathLine={flightPathLine}
        formik={formik}
        formModified={isStateDirty}
        solarFarmId={solarFarmId}
        flightMode={flightMode}
        setFlightMode={setFlightMode}
        cameraAngleModeOptions={cameraAngleModeOptions}
        activeRowId={activeRowId}
        isContinuousOperationsActive={isContinuousOperationsActive}
      />
      <Map
        slider={slider}
        markerLngLat={markerLngLat}
        solarFarm={solarFarm}
        map={map}
        polygon={polygon}
        center={initialMission?.mission.mapCenter}
        zoom={initialMission?.mission.mapZoom}
        flightPath={flightPathLine}
        waypoints={waypoints}
        nonCalibratedFlightPath={calibration.nonCalibratedFlightPath}
        activeRowId={activeRowId}
        setMap={setMap}
        setSlider={setSlider}
        setPolygon={setPolygon}
        setMarkerLngLat={setMarkerLngLat}
        setActiveRowId={setActiveRowId}
      />

      <ConfirmationModal
        open={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        handleCancel={() => blocker.reset()}
        handleConfirm={() => {
          resetState();
          blocker.proceed();
        }}
        title="Leave Flight Plan?"
        description="Changes that you made may not be saved."
        confirmText="Leave"
        closeText="Cancel"
      />
    </FormikProvider>
  );
};

export default MissionPlanner;
