import { useState, useEffect } from 'react';

// Components
import SiteOverviewMap from './Map/SiteOverviewMap';
import DeviceCard from './DeviceCard';
import DigitalTwinSection from '../../shared/components/DigitalTwin/DigitalTwin';
import BackgroundSection from '../../shared/components/Background/Background';

// Styled Components
import {
  SidebarBottomPadding,
  Divider,
  SiteOverviewContainer,
  CollapsibleTopPadding,
} from './SiteOverview.styled';
import { Text } from '@raptormaps/text';
import { SectionCollapsible } from '../MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/MissionParametersSection.styles';
import { ThreeCircles } from 'react-loader-spinner';
import theme from '@raptormaps/theme';
import { Stack } from '@raptormaps/layout';
import { FloatingSidebarContainer, Sidebar } from '@raptormaps/sidebar';

// Hooks
import { useSolarFarmById } from '../../shared/hooks/useSolarFarms';
import { useToast } from '@raptormaps/toast';

// Mapbox
import mapboxgl from 'mapbox-gl';

// Mock Data
import { MockDevice } from '../../shared/constants/mockDeviceData';
import { useGetDevices } from '../../shared/hooks/useLiveStreamMock';
import { PageProps } from '@/shared/types/page';

const SiteOverview = ({ solarFarmId }: PageProps) => {
  const [map, setMap] = useState<mapboxgl.Map>(null);
  const [activeRowId, setActiveRowId] = useState<number>(null);
  const [slider, setSlider] = useState<number>(25);

  const toast = useToast();

  const { data: devices, isLoading: devicesLoading } =
    useGetDevices(solarFarmId);

  const {
    data: solarFarm,
    isLoading: solarFarmLoading,
    error: solarFarmError,
  } = useSolarFarmById(solarFarmId);

  // Render error if the getSolarFarmById query fails
  useEffect(() => {
    if (solarFarmError) {
      toast.error(`Error fetching solar farm with ID ${solarFarmId}`);
    }
  }, [solarFarmError]);

  const handleRenderDeviceCards = () => {
    if (!devices.length) {
      return (
        <Text variant="paragraph_medium_bold" color="grey_300">
          No devices found for this site
        </Text>
      );
    } else {
      return devices.map((device: MockDevice) => (
        <DeviceCard device={device} key={device.id} />
      ));
    }
  };

  if (devicesLoading || solarFarmLoading) {
    return (
      <>
        <FloatingSidebarContainer>
          <Sidebar title="Site Overview" defaultOpen>
            <Divider />
            <SiteOverviewContainer>
              <Stack align="center">
                <ThreeCircles
                  height="40"
                  width="40"
                  color={theme.colors.primary_400}
                  visible={true}
                  ariaLabel="loading spinner"
                />
              </Stack>
            </SiteOverviewContainer>
          </Sidebar>
        </FloatingSidebarContainer>
        <SiteOverviewMap
          setMap={setMap}
          setActiveRowId={setActiveRowId}
          activeRowId={activeRowId}
        />
      </>
    );
  }

  if (solarFarmError) {
    return (
      <>
        <FloatingSidebarContainer>
          <Sidebar title="Site Overview" defaultOpen>
            <Divider />
            <SiteOverviewContainer>
              <Stack align="center"></Stack>
            </SiteOverviewContainer>
          </Sidebar>
        </FloatingSidebarContainer>
        <SiteOverviewMap
          setMap={setMap}
          setActiveRowId={setActiveRowId}
          activeRowId={activeRowId}
        />
      </>
    );
  }

  return (
    <>
      <FloatingSidebarContainer>
        <Sidebar title={`Site Overview: ${solarFarm.name}`} defaultOpen>
          <Divider />
          <SiteOverviewContainer>
            <CollapsibleTopPadding />
            <SectionCollapsible
              title="Online Devices"
              variant="ghost"
              {...{ defaultOpen: true }}
            >
              {handleRenderDeviceCards()}
            </SectionCollapsible>
            <DigitalTwinSection
              solarFarm={solarFarm}
              map={map}
              activeRowId={activeRowId}
            />
            <BackgroundSection
              map={map}
              solarFarm={solarFarm}
              slider={slider}
              setSlider={setSlider}
              useDroneIcon={false}
            />
            <SidebarBottomPadding />
          </SiteOverviewContainer>
        </Sidebar>
      </FloatingSidebarContainer>
      <SiteOverviewMap
        solarFarm={solarFarm}
        map={map}
        setMap={setMap}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
      />
    </>
  );
};

export default SiteOverview;
