// Global appcues declaration per appsuite documentation
declare global {
  interface Window {
    Appcues: any;
  }
}

/***
 * Initializes user in appcues
 * @param userId - Current's user id
 */
export const appcuesInitializeUser = userId => {
  window.Appcues.identify(userId);
};

/**
 * Updates the solarFarmUuid property in Appcues User object for RV-to-MfW redirect
 * @param userId - Current's user ids
 * @param solarFarmUuid - Current's selected solar farm uuid
 */
export const appcuesUpdateUserFarmUuid = (userId, solarFarmUuid) => {
  window.Appcues.identify(userId, {
    solarFarmUuid: solarFarmUuid,
  });
};
