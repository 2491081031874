import { ReactElement } from 'react';
import type { IconProps } from '@raptormaps/icon';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '../utils/GrowthbookUtils';
import Calibration from '@/pages/Calibration/Calibration';
import MissionPlanner from '@/pages/MissionPlanner/MissionPlanner';
import MissionTable from '@/pages/MissionTable/MissionTable';
import SiteList from '@/pages/SiteList/SiteList';
import FlightUploader from '@/pages/FlightUploader/FlightUploader';
import LiveStream from '@/pages/LiveStream/LiveStream';
import SiteOverview from '@/pages/Overview/SiteOverview';
import { MissionTasksTable } from '@/pages/MissionTasks/MissionTasksTable';

export interface RouteConfiguration {
  path: string;
  /**
   * Properties for how route should be displayed in left nav bar.
   * Leave undefined if route should not have an item in the nav.
   */
  tabDisplay?: {
    basePath: string;
    label: string;
    icon: IconProps['icon'];
  };
  /**
   * Whether the feature is disabled/not ready for prime time. A perfect place to Growthbook flag a route
   */
  disabled?: boolean;
  /**
   * Render function for the route.
   */
  render: ReactElement;
}

export interface UseRoutesParams {
  solarFarmId: number | null;
}

export const useRoutes = ({
  solarFarmId,
}: UseRoutesParams): Array<RouteConfiguration> => {
  /**
   * Order matters here.
   *  More specific routes should come before more generic routes.
   *  Tabs in the left nav will display in the order they are present here.
   */

  // Feature Flags
  const showLiveStream = useFeatureIsOn(GrowthbookFlags.LIVE_STREAM);
  const showMissionTask = useFeatureIsOn(GrowthbookFlags.MISSION_TASK);

  let ROOT_ROUTES: Array<RouteConfiguration> = [
    {
      path: '/',
      tabDisplay: {
        basePath: '',
        label: 'Homepage',
        icon: 'World',
      },
      render: <SiteList />,
    },
    {
      path: `/live-stream/:deviceId?`,
      disabled: !showLiveStream,
      render: <LiveStream />,
    },
  ];
  let SOLAR_SITE_SELECTED_ROUTES: Array<RouteConfiguration> = [
    {
      path: '/',
      tabDisplay: {
        basePath: '',
        label: 'Exit',
        icon: 'ArrowLeftToArc',
      },
      render: <SiteList />,
    },
    {
      path: '/overview',
      tabDisplay: {
        basePath: `overview?solar_farm_id=${solarFarmId}`,
        label: 'Overview',
        icon: 'Drone',
      },
      disabled: !showLiveStream,
      render: <SiteOverview solarFarmId={solarFarmId} />,
    },
    {
      path: '/plan/:missionId?',
      tabDisplay: {
        basePath: `plan?solar_farm_id=${solarFarmId}`,
        label: 'Flight Planner',
        icon: 'VectorPolygon',
      },
      render: <MissionPlanner solarFarmId={solarFarmId} />,
    },
    {
      path: '/calibration/:missionId?',
      tabDisplay: {
        basePath: `calibration?solar_farm_id=${solarFarmId}`,
        label: 'Calibration',
        icon: 'RulerHorizontal',
      },
      render: <Calibration solarFarmId={solarFarmId} />,
    },
    {
      path: '/missions/:missionId?',
      tabDisplay: {
        basePath: `missions/?solar_farm_id=${solarFarmId}`,
        label: 'Mission Library',
        icon: 'Book',
      },
      render: <MissionTable solarFarmId={solarFarmId} />,
    },
    {
      path: '/upload/:missionId?',
      render: <FlightUploader solarFarmId={solarFarmId} />,
    },
    {
      path: '/mission_tasks',
      tabDisplay: {
        basePath: `mission_tasks?solar_farm_id=${solarFarmId}`,
        label: 'Mission Tasks',
        icon: 'FileLines',
      },
      disabled: !showMissionTask,
      render: <MissionTasksTable solarFarmId={solarFarmId} />,
    },
  ];

  SOLAR_SITE_SELECTED_ROUTES = SOLAR_SITE_SELECTED_ROUTES.filter(
    r => !r.disabled,
  );
  ROOT_ROUTES = ROOT_ROUTES.filter(r => !r.disabled);

  return solarFarmId ? SOLAR_SITE_SELECTED_ROUTES : ROOT_ROUTES;
};
