import * as Yup from 'yup';
import { droneFormParameters } from '../../../MissionPlanner/components/MissionPlanningForm/FormParameters';
import {
  MissionFileType,
  SolarFarmType,
} from '../../../../shared/types/missions.d';
import {
  flightModes,
  SPEED_CONTROLS,
  droneTypes,
  overlapModes,
  USER_UNITS,
  missionFileVersion,
} from '../../../../shared/constants/missionsConstants';
const { safeTakeoffAltitudeInput, cameraIntervalInput } = droneFormParameters;

// Custom typing tests
const isLngLat = value => {
  return (
    value.length === 2 &&
    value[0] >= -180 &&
    value[0] <= 180 &&
    value[1] >= -90 &&
    value[1] <= 90
  );
};

const isFeaturePolygon = value => {
  return (
    typeof value === 'object' &&
    value !== null &&
    value.type === 'Feature' &&
    value.geometry &&
    value.geometry.type === 'Polygon' &&
    value.geometry.coordinates !== undefined &&
    typeof value.geometry.coordinates !== 'string' &&
    typeof value.geometry.coordinates[0] === 'object' &&
    value.geometry.coordinates[0].length > 2 &&
    value.geometry.coordinates[0].every(isLngLat)
  );
};

const isFeaturePoint = value => {
  return (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length === 3 &&
    value.properties &&
    value.type === 'Feature' &&
    value.geometry.type === 'Point' &&
    isLngLat(value.geometry.coordinates)
  );
};

const isFeatureCollection = value => {
  return (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length === 2 &&
    value.features &&
    value.features.length > 0 &&
    value.type === 'FeatureCollection' &&
    Array.isArray(value.features) &&
    value.features.every(isFeaturePoint)
  );
};

const isPointType = value => {
  return (
    typeof value === 'object' &&
    Object.keys(value).length === 2 &&
    value !== null &&
    value.lng >= -180 &&
    value.lng <= 180 &&
    value.lat >= -90 &&
    value.lat <= 90
  );
};

const isSolarFarmType = (value: SolarFarmType) => {
  return (
    typeof value === 'object' &&
    value !== null &&
    value !== undefined &&
    Object.keys(value).length === 3 &&
    Object.keys(value).includes('id') &&
    Object.keys(value).includes('name') &&
    Object.keys(value).includes('geom') &&
    typeof value.name === 'string' &&
    value.geom === null &&
    (typeof value.id === 'number' || value.id === null)
  );
};

const isValidEmptyObj = value => {
  return (
    typeof value === 'object' &&
    value !== null &&
    value !== undefined &&
    Object.keys(value).length === 0
  );
};

const validateSafeTakeoffAltitude = Yup.number()
  .nullable()
  .typeError(
    `Safe altitude must be a number between ${safeTakeoffAltitudeInput.min} and ${safeTakeoffAltitudeInput.max}`,
  )
  .min(safeTakeoffAltitudeInput.min)
  .max(safeTakeoffAltitudeInput.max);

const validateCameraInterval = Yup.number()
  .required('Camera interval is required')
  .typeError(
    `Camera interval must be a number between ${cameraIntervalInput.min} and ${cameraIntervalInput.max}`,
  )
  .min(cameraIntervalInput.min)
  .max(cameraIntervalInput.max);

const validateDrone = Yup.string()
  .required('Valid drone model is required')
  .oneOf(droneTypes);

const validateFlightMap = Yup.object()
  .test(
    'is-feature-polygon',
    'Flight map is an invalid Feature<Polygon>',
    isFeaturePolygon,
  )
  .typeError('Flight map must be a valid Feature<Polygon>')
  .required('Flight map of type Feature<Polygon> is required');

const validateFlightMode = Yup.string()
  .required('Valid flight mode is required')
  .oneOf(flightModes);

const validateFrontSpacing = Yup.number()
  .required('Front spacing is required')
  .typeError('Front spacing must be a valid number');

const validateSideSpacing = Yup.number()
  .required('Side spacing is required')
  .typeError('Side spacing must be a valid number');

const validateMapCenter = Yup.object()
  .test('is-point-type', 'Map Center is an invalid set of points', isPointType)
  .typeError('Map Center must be a valid object')
  .required('Map Center as lng lat points is required');

const validateMapZoom = Yup.number()
  .required('Map zoom between 0 and 23 is required')
  .typeError('Map zoom must be a valid number between 0 and 23')
  .min(0)
  .max(23);

const validateOverlapMode = Yup.string()
  .required('Valid overlap mode is required')
  .oneOf(overlapModes);

const validateSolarFarm = Yup.object()
  .test(
    'is-solar-farm-type',
    'Solar farm is an invalid solar farm type',
    isSolarFarmType,
  )
  .typeError('Solar Farm must contain id, name, and geom')
  .required(
    'Valid solar farm information is required, this should include solar farm name',
  );

const validateSolarFarmId = (solarFarmId: number, solarFarmName: string) =>
  Yup.number().test(
    'is-correct-solar-farm',
    'This mission is for ' + solarFarmName,
    value => value === solarFarmId,
  );

const validateSpeedControlMode = Yup.string()
  .required('Valid speed control mode is required')
  .oneOf(SPEED_CONTROLS);

// no test for take off point center because a takeoffpoint is not required

const validateTakeOffPoint = Yup.object()
  .nullable()
  .typeError('Take off point must to be a valid point object')
  .test('Take off point is an invalid set of points', value => {
    if (!value) return true;
    else if (Object.keys(value).length === 0) return isValidEmptyObj(value);
    else return isPointType(value);
  });

const validateTerrainFollowBool = Yup.boolean()
  .required('Terrain follow on or off is required')
  .typeError('Terrain follow on or off is required to be true or false');

const validateUserUnits = Yup.string()
  .required('Valid user unit (Metric / Imperial) is required')
  .oneOf(USER_UNITS);

// validation for version
const validateVersion = Yup.string()
  .required()
  .test(value => value[0] === missionFileVersion[0]);

// validation for waypoints
const validateWaypoints = Yup.object()
  .typeError('Waypoints must be a waypoint object')
  .test(
    'is-feature-polygon',
    'Waypoints is an invalid Feature Collection',
    isFeatureCollection,
  )
  .required('Waypoints of type Feature Collection is required');

const validators = {
  safeTakeoffAltitude: validateSafeTakeoffAltitude,
  cameraInterval: validateCameraInterval,
  drone: validateDrone,
  flightMap: validateFlightMap,
  flightMode: validateFlightMode,
  frontSpacing: validateFrontSpacing,
  mapCenter: validateMapCenter,
  mapZoom: validateMapZoom,
  overlapMode: validateOverlapMode,
  sideSpacing: validateSideSpacing,
  solarFarm: validateSolarFarm,
  speedControlMode: validateSpeedControlMode,
  takeOffPointCenter: validateTakeOffPoint,
  terrainFollowBool: validateTerrainFollowBool,
  userUnits: validateUserUnits,
};

export const validateAdditionalMissionParams = (
  parsedJson: MissionFileType,
  solarFarmId?: number,
) => {
  // collect all the errors
  const errorLog = [];
  // match each test with its field in the mission
  const tests = Object.keys(validators);

  tests.forEach(key => {
    // if the key is in validators then run the associated test
    try {
      validators[key].validateSync(parsedJson.mission[key]);
    } catch (err) {
      if (typeof parsedJson.mission[key] === 'object') {
        // if it is an object omit the invalid value becasue you can print [object, object] or the whole object which is non sense
        errorLog.push(`Error at ${key}: ` + err.message);
      } else {
        // if the value that failed is a string or number etc. add it to the error message
        errorLog.push(
          `Error at ${key}: "` + parsedJson.mission[key] + '" ' + err.message,
        );
      }
    }
  });
  try {
    // test for version
    validateVersion.validateSync(parsedJson.version);
  } catch (err) {
    errorLog.push(`Version ${missionFileVersion[0]}.x.x is required`);
  }
  try {
    // test for waypoints
    validateWaypoints.validateSync(parsedJson.waypoints);
  } catch (err) {
    errorLog.push('Error at waypoints: ' + err.message);
  }
  if (solarFarmId)
    try {
      // test for mission being uploaded is for the correct solar site
      validateSolarFarmId(
        solarFarmId,
        parsedJson.mission.solarFarm.name,
      ).validateSync(parsedJson.mission.solarFarm.id);
    } catch (err) {
      errorLog.push(
        `Solar farm (name: ${parsedJson.mission.solarFarm.name}, id: ${parsedJson.mission.solarFarm.id}) must match the currently selected solar farm.`,
      );
    }
  if (errorLog.length > 0) return errorLog;
  else return false;
};
