import styled from 'styled-components';

import { MinMax } from '../../MissionParametersSection.styles';

export const FollowTrackerContainer = styled.div`
  text-align: left;

  label {
    letter-spacing: 0.5px;
  }
`;

export const FollowTrackerTextInputLabel = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: -15px;
  gap: 5px;
`;

export const FollowTrackerMinMax = styled(MinMax)`
  margin-top: -2px;
  margin-left: -25px;
  grid-row: 2;
  text-align: left;
`;
