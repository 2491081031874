import { Feature, FeatureCollection, Point } from '@turf/helpers';
import {
  AltitudeOffsetObject,
  IntervalModeObject,
  DroneType,
  WaypointTurnModes,
  FlightModeType,
} from '../../types/missions';
import { DJI_DRONE_VALUES } from './djiConstants';
import { XMLBuilder } from 'fast-xml-parser';
import { FLIGHT_MODE } from '@/pages/MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/missionParametersConstants';

export function generateDJITemplateKML(
  waypoints: FeatureCollection,
  altitude: number,
  cameraHeading: number,
  flightSpeed: number,
  flightDirection: string,
  pitchAngle: number,
  flightMode: FlightModeType,
  droneType: DroneType,
  terrainFollowBool: boolean,
  altitudeOffsetObject: AltitudeOffsetObject,
  waypointTurnMode: WaypointTurnModes,
  intervalModeObject: IntervalModeObject,
  continuousOperationsEnabled: boolean,
  safeTakeoffAltitudeBool: boolean,
  safeTakeoffAltitude?: number,
): string {
  // if safe takeoff altitude is toggled, then takeoff security height is added to file
  let takeOffSecurityHeight = null;
  if (safeTakeoffAltitudeBool) {
    takeOffSecurityHeight = safeTakeoffAltitude;
  }
  const kmlContent = {
    '?xml': {
      '@_version': '1.0',
      '@_encoding': 'UTF-8',
    },
    kml: {
      '@_xmlns': 'http://www.opengis.net/kml/2.2',
      '@_xmlns:wpml': 'http://www.dji.com/wpmz/1.0.3',
      Document: {
        ...setupCreationInformation(),
        ...setupMissionInformation(takeOffSecurityHeight, droneType),
        ...setupWaypointFolder(
          flightSpeed,
          altitude,
          safeTakeoffAltitude,
          safeTakeoffAltitudeBool,
          cameraHeading,
          flightDirection,
          waypointTurnMode,
          waypoints,
          terrainFollowBool,
          altitudeOffsetObject,
          pitchAngle,
          intervalModeObject,
          continuousOperationsEnabled,
          flightMode,
          droneType,
        ),
      },
    },
  };
  const kml = convertToKML(kmlContent);
  return kml;
}

function setupCreationInformation() {
  return {
    'wpml:author': 'Raptor Maps, Inc.',
    'wpml:createTime': Date.now(),
    'wpml:updateTime': Date.now(),
  };
}

function setupMissionInformation(
  takeOffSecurityHeight: number,
  droneType: DroneType,
) {
  return {
    'wpml:missionConfig': {
      'wpml:flyToWaylineMode': 'safely',
      'wpml:finishAction': DJI_DRONE_VALUES[droneType].finishAction,
      'wpml:exitOnRCLost': 'executeLostAction',
      'wpml:executeRCLostAction': 'goBack',
      ...(takeOffSecurityHeight && {
        'wpml:takeOffSecurityHeight': takeOffSecurityHeight,
      }),
      'wpml:globalTransitionalSpeed': 15,
      'wpml:droneInfo': {
        'wpml:droneEnumValue': DJI_DRONE_VALUES[droneType].droneEnumValue,
        'wpml:droneSubEnumValue': 1,
      },
      'wpml:payloadInfo': {
        'wpml:payloadEnumValue': DJI_DRONE_VALUES[droneType].payloadEnumValue,
        'wpml:payloadSubEnumValue': 0,
        'wpml:payloadPositionIndex': 0,
      },
    },
  };
}

function setupWaypointFolder(
  flightSpeed: number,
  altitude: number,
  safeTakeoffAltitude: number,
  safeTakeoffAltitudeBool: boolean,
  cameraHeading: number,
  flightDirection: string,
  waypointTurnMode: WaypointTurnModes,
  waypoints: FeatureCollection,
  terrainFollowBool: boolean,
  altitudeOffsetObject: AltitudeOffsetObject,
  pitchAngle: number,
  intervalModeObject: IntervalModeObject,
  continuousOperationsEnabled: boolean,
  flightMode: FlightModeType,
  droneType: DroneType,
) {
  return {
    Folder: {
      ...setupWaypointConfig(
        flightSpeed,
        altitude,
        cameraHeading,
        flightDirection,
        waypointTurnMode,
        altitudeOffsetObject,
      ),
      ...setupWaypoints(
        waypoints,
        terrainFollowBool,
        altitudeOffsetObject,
        altitude,
        safeTakeoffAltitude,
        safeTakeoffAltitudeBool,
        pitchAngle,
        cameraHeading,
        intervalModeObject,
        continuousOperationsEnabled,
        flightMode,
      ),
      ...setupPayloadParam(droneType),
    },
  };
}

function setupWaypointConfig(
  flightSpeed: number,
  altitude: number,
  cameraHeading: number,
  flightDirection: string,
  waypointTurnMode: WaypointTurnModes,
  altitudeOffsetObject: AltitudeOffsetObject,
) {
  if (altitudeOffsetObject.active) {
    altitude = altitude + altitudeOffsetObject.offset;
  }
  return {
    'wpml:templateType': 'waypoint',
    'wpml:templateId': 0,
    'wpml:waylineCoordinateSysParam': {
      'wpml:coordinateMode': 'WGS84',
      'wpml:heightMode': 'relativeToStartPoint',
    },
    'wpml:autoFlightSpeed': flightSpeed,
    'wpml:globalHeight': altitude,
    'wpml:caliFlightEnable': 0,
    'wpml:gimbalPitchMode': 'manual',
    'wpml:globalWaypointHeadingParam': {
      'wpml:waypointHeadingMode': flightDirection,
      'wpml:waypointHeadingAngle': cameraHeading,
      'wpml:waypointPoiPoint': '0.000000,0.000000,0.000000',
      'wpml:waypointHeadingPathMode': 'followBadArc',
      'wpml:waypointHeadingPoiIndex': 0,
    },
    'wpml:globalWaypointTurnMode': waypointTurnMode,
    'wpml:globalUseStraightLine': 1,
  };
}

function setupWaypoints(
  waypoints: FeatureCollection,
  terrainFollowBool: boolean,
  altitudeOffsetObject: AltitudeOffsetObject,
  inspectionAltitude: number,
  safeTakeoffAltitude: number,
  safeTakeoffAltitudeBool: boolean,
  pitchAngle: number,
  cameraHeading: number,
  intervalModeObject: IntervalModeObject,
  continuousOperationsEnabled: boolean,
  flightMode: FlightModeType,
) {
  const perimeterModeBool = flightMode == FLIGHT_MODE.PERIMETER;
  const aircraftHeading: number = perimeterModeBool ? 90 : cameraHeading;
  const placemarks = [];
  const isFirstWaypointATakeoffPoint: boolean =
    waypoints.features[0].properties.name === 'Relative Altitude Point';
  waypoints.features.forEach((feature: Feature<Point>, index: number) => {
    let actionGroups;
    let flightAltitude =
      (terrainFollowBool ? feature.properties.altitude : inspectionAltitude) +
      (altitudeOffsetObject.active ? altitudeOffsetObject.offset : 0);
    const squareOrbitalRotationValue =
      flightMode === FLIGHT_MODE.SQUARE_ORBITAL
        ? feature.properties.rotation
        : null;
    if (index == 0 && flightMode != FLIGHT_MODE.ORTHO) {
      // First waypoint: sets initial camera position and starts interval mode if active or takes picture
      const actionGroupStartIndex =
        feature.properties.name === 'Relative Altitude Point' ? 1 : 0; // skips taking photo at the takeoff point for intervalometer mode
      if (safeTakeoffAltitudeBool) {
        // if safe takeoff altitude is toggled, change flightAltitude of this waypoint (only if is the takeoff point or safe takeoff altitude waypoint)
        flightAltitude =
          feature.properties.name === 'Relative Altitude Point'
            ? safeTakeoffAltitude
            : flightAltitude;
        flightAltitude =
          feature.properties.name === 'Safe Takeoff Altitude'
            ? safeTakeoffAltitude
            : flightAltitude;
      }
      actionGroups = {
        'wpml:actionGroup': [
          {
            ...actionGroupConfig(0, index),
            'wpml:action': [
              rotateYawAction(
                0,
                feature.properties.gimbalHeading ??
                  squareOrbitalRotationValue ??
                  aircraftHeading,
              ),
              gimbalRotateAction(2, {
                pitchRotateAngle:
                  feature.properties.gimbalPitchAngle ?? pitchAngle,
              }),
              ...(!intervalModeObject.active
                ? [takePhotoAction(3, `waypoint${index}`)]
                : []),
            ],
          },
          // adds extra actionGroup to control image start/stop for intervalometer mode
          ...(intervalModeObject.active
            ? [
                {
                  ...actionGroupConfig(
                    1,
                    actionGroupStartIndex,
                    waypoints.features.length - 1,
                    intervalModeObject.actionTriggerType,
                    intervalModeObject.actionTriggerParam,
                  ),
                  'wpml:action': takePhotoAction(0),
                },
              ]
            : []),
        ],
      };
      // Adds rotation action for first inspection waypoint if there exists a takeoff pt, turns drone body to correct angle
    } else if (
      index == 1 &&
      isFirstWaypointATakeoffPoint &&
      !continuousOperationsEnabled &&
      !perimeterModeBool &&
      flightMode != FLIGHT_MODE.ORTHO
    ) {
      actionGroups = {
        'wpml:actionGroup': [
          {
            ...actionGroupConfig(0, index),
            'wpml:action': [
              rotateYawAction(
                0,
                feature.properties.gimbalHeading ??
                  squareOrbitalRotationValue ??
                  aircraftHeading,
              ),
              ...(!intervalModeObject.active
                ? [takePhotoAction(2, `waypoint${index}`)]
                : []),
            ],
          },
        ],
      };
      // In case there is takeoff pt and safe takeoff alt, fix safe takeoff alt
      flightAltitude =
        feature.properties.name === 'Safe Takeoff Altitude'
          ? safeTakeoffAltitude
          : flightAltitude;
    } else if (
      index == 2 &&
      isFirstWaypointATakeoffPoint &&
      !continuousOperationsEnabled &&
      !perimeterModeBool &&
      safeTakeoffAltitudeBool
    ) {
      actionGroups = {
        'wpml:actionGroup': [
          {
            ...actionGroupConfig(0, index),
            'wpml:action': [
              rotateYawAction(
                0,
                feature.properties.gimbalHeading ?? aircraftHeading,
              ),
              ...(!intervalModeObject.active
                ? [takePhotoAction(2, `waypoint${index}`)]
                : []),
            ],
          },
        ],
      };
    } else {
      if (continuousOperationsEnabled) {
        // For continuous operation sets camera position at each waypoint
        actionGroups = {
          'wpml:actionGroup': {
            ...actionGroupConfig(0, index),
            'wpml:action': [
              rotateYawAction(0, feature.properties.gimbalHeading),
              gimbalRotateAction(2, {
                pitchRotateAngle: feature.properties.gimbalPitchAngle,
              }),
              ...(!intervalModeObject.active
                ? [takePhotoAction(3, `waypoint${index}`)]
                : []),
            ],
          },
        };
      } else if (flightMode == FLIGHT_MODE.ORTHO) {
        // if ortho mode, gimbal rotate yaw and rotate yaw actions unnecessary
        if (index == 0) {
          // first waypoint needs gimbal pitch set
          const actionGroupStartIndex =
            feature.properties.name === 'Relative Altitude Point' ? 1 : 0; // skips taking photo at the takeoff point for intervalometer mode

          if (safeTakeoffAltitudeBool) {
            // if safe takeoff altitude is toggled, change flightAltitude of this waypoint (only if is the takeoff point or safe takeoff altitude waypoint)
            flightAltitude =
              feature.properties.name === 'Relative Altitude Point'
                ? safeTakeoffAltitude
                : flightAltitude;
            flightAltitude =
              feature.properties.name === 'Safe Takeoff Altitude'
                ? safeTakeoffAltitude
                : flightAltitude;
          }

          actionGroups = {
            'wpml:actionGroup': [
              {
                ...actionGroupConfig(0, index),
                'wpml:action': [
                  gimbalRotateAction(1, {
                    pitchRotateAngle:
                      feature.properties.gimbalPitchAngle ?? pitchAngle,
                  }),
                  ...(!intervalModeObject.active
                    ? [takePhotoAction(2, `waypoint${index}`)]
                    : []),
                ],
              },
              // adds extra actionGroup to control image start/stop for intervalometer mode
              ...(intervalModeObject.active
                ? [
                    {
                      ...actionGroupConfig(
                        1,
                        actionGroupStartIndex,
                        waypoints.features.length - 1,
                        intervalModeObject.actionTriggerType,
                        intervalModeObject.actionTriggerParam,
                      ),
                      'wpml:action': takePhotoAction(0),
                    },
                  ]
                : []),
            ],
          };
        } else {
          // for all other waypoints in ortho mode, only action is take photo
          // takes image at each waypoint and rotates camera for perimeter mode if necessary
          actionGroups = {
            'wpml:actionGroup': {
              ...actionGroupConfig(0, index),
              'wpml:action': [
                takePhotoAction(0, `waypoint${index}`),
                ...(perimeterModeBool
                  ? [rotateYawAction(1, feature.properties.rotation)]
                  : []),
              ],
            },
          };
        }
      } else if (flightMode == FLIGHT_MODE.SQUARE_ORBITAL) {
        actionGroups = {
          'wpml:actionGroup': {
            ...actionGroupConfig(0, index),
            'wpml:action': [
              rotateYawAction(0, feature.properties.rotation),
              ...(feature.properties.hover
                ? [hoverAction(1, feature.properties.hover)]
                : []),
              takePhotoAction(2, `waypoint${index}`),
            ],
          },
        };
      } else {
        if (!intervalModeObject.active) {
          // takes image at each waypoint and rotates camera for perimeter mode if necessary
          actionGroups = {
            'wpml:actionGroup': {
              ...actionGroupConfig(0, index),
              'wpml:action': [
                takePhotoAction(0, `waypoint${index}`),
                ...(perimeterModeBool
                  ? [rotateYawAction(1, feature.properties.rotation)]
                  : []),
              ],
            },
          };
        }
      }
    }
    placemarks.push({
      name: feature.properties.name,
      ...waypointSetup(
        feature.geometry.coordinates,
        index,
        flightAltitude,
        pitchAngle,
        continuousOperationsEnabled,
        feature.properties.flightSpeed,
        feature.properties.hover,
      ),
      ...actionGroups,
    });
  });
  return { Placemark: placemarks };
}

function actionGroupConfig(
  id: number,
  startIndex: number,
  endIndex = startIndex,
  triggerType = 'reachPoint',
  triggerParam?,
) {
  return {
    'wpml:actionGroupId': id,
    'wpml:actionGroupStartIndex': startIndex,
    'wpml:actionGroupEndIndex': endIndex,
    'wpml:actionGroupMode': 'sequence',
    'wpml:actionTrigger': {
      'wpml:actionTriggerType': triggerType,
      'wpml:actionTriggerParam': triggerParam,
    },
  };
}

function waypointSetup(
  coordinate: number[],
  index: number,
  altitude: number,
  pitchAngle: number,
  continuousOperationsEnabled: boolean,
  flightSpeed?: number,
  hover?: number,
) {
  const waypointSetup = {
    Point: {
      coordinates: `${coordinate[0]},${coordinate[1]}`,
    },
    'wpml:index': index,
    'wpml:ellipsoidHeight': altitude,
    'wpml:height': altitude,
    'wpml:useGlobalSpeed': flightSpeed ? 0 : 1,
    ...(flightSpeed && { 'wpml:waypointSpeed': flightSpeed }),
    'wpml:useGlobalHeadingParam': 1,
    'wpml:useGlobalTurnParam': hover ? 0 : 1,
    ...(hover && {
      'wpml:waypointTurnMode': 'toPointAndStopWithDiscontinuityCurvature',
    }),
  };

  if (index == 0 || continuousOperationsEnabled) {
    waypointSetup['wpml:gimbalPitchAngle'] = pitchAngle;
  } else {
    waypointSetup['wpml:useStraightLine'] = 1;
    waypointSetup['wpml:gimbalPitchAngle'] = -80;
  }
  return waypointSetup;
}

function takePhotoAction(actionId: number, fileSuffix?: string) {
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'takePhoto',
    'wpml:actionActuatorFuncParam': {
      ...(fileSuffix && { 'wpml:fileSuffix': fileSuffix }),
      'wpml:payloadPositionIndex': 0,
      'wpml:useGlobalPayloadLensIndex': 1,
    },
  };
}

function rotateYawAction(actionId: number, yawAngle: number) {
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'rotateYaw',
    'wpml:actionActuatorFuncParam': {
      'wpml:aircraftHeading': yawAngle,
      'wpml:aircraftPathMode': 'counterClockwise',
    },
  };
}

function hoverAction(actionId: number, hoverTime: number) {
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'hover',
    'wpml:actionActuatorFuncParam': {
      'wpml:hoverTime': hoverTime,
    },
  };
}

function gimbalRotateAction(actionId: number, gimbalRotateObject) {
  // possibly restructure if one block of this can be used to do both
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'gimbalRotate',
    'wpml:actionActuatorFuncParam': {
      'wpml:gimbalHeadingYawBase': 'north',
      'wpml:gimbalRotateMode': 'absoluteAngle',
      'wpml:gimbalPitchRotateEnable': gimbalRotateObject.pitchRotateAngle
        ? 1
        : 0,
      'wpml:gimbalPitchRotateAngle': gimbalRotateObject.pitchRotateAngle ?? 0,
      'wpml:gimbalRollRotateEnable': 0,
      'wpml:gimbalRollRotateAngle': 0,
      'wpml:gimbalYawRotateEnable': gimbalRotateObject.yawRotateAngle ? 1 : 0,
      'wpml:gimbalYawRotateAngle': gimbalRotateObject.yawRotateAngle ?? 0,
      'wpml:gimbalRotateTimeEnable': 0,
      'wpml:gimbalRotateTime': 0,
      'wpml:payloadPositionIndex': 0,
    },
  };
}

function convertToKML(kmlArray) {
  const builder = new XMLBuilder({
    format: true,
    ignoreAttributes: false,
  });
  return builder.build(kmlArray);
}

function setupPayloadParam(droneType: DroneType) {
  return {
    'wpml:payloadParam': {
      'wpml:payloadPositionIndex': 0,
      'wpml:meteringMode': 'average',
      'wpml:dewarpingEnable': 0,
      'wpml:returnMode': 'singleReturnStrongest',
      'wpml:samplingRate': 240000,
      'wpml:scanningMode': 'nonRepetitive',
      'wpml:modelColoringEnable': 0,
      'wpml:imageFormat': DJI_DRONE_VALUES[droneType].imageFormat,
    },
  };
}

export function generateDJITemplateWPML(
  droneType: DroneType,
  safeTakeoffAltitude?: number,
): string {
  const wpmlContent = {
    '?xml': {
      '@_version': '1.0',
      '@_encoding': 'UTF-8',
    },
    kml: {
      '@_xmlns': 'http://www.opengis.net/kml/2.2',
      '@_xmlns:wpml': 'http://www.dji.com/wpmz/1.0.4',
      Document: {
        ...setupCreationInformation(),
        ...setupMissionInformation(safeTakeoffAltitude, droneType),
      },
    },
  };

  const wpml = convertToKML(wpmlContent);

  return wpml;
}
