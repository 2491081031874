import { ReactNode } from 'react';
import { FeatureCollection, Feature, Polygon } from '@turf/helpers';
import { FLIGHT_MODE } from '@/pages/MissionPlanner/components/MissionPlanningForm/MissionPlanningSidebar/components/MissionParameters/missionParametersConstants';

export interface Org {
  api_phone: string | null;
  class_name: string | null;
  created_tsecs: number | null;
  id: number;
  logo_file_id: number | null;
  master: object;
  master_id: number;
  name: string;
  paid: boolean;
  permittance: object;
  type: string;
  updated_tsecs: number | null;
  users: [object];
  uuid: string | null;
}

export type DroneType =
  | 'M3T'
  | 'M30T'
  | 'Custom'
  | 'Manned Airplane'
  | 'M30T Dock'
  | 'M3TD Dock'
  | 'Husky Observer';
export type CameraType = 'RGB Wide' | 'Thermal';
export type FlightModeType =
  | 'Standard'
  | 'Comprehensive'
  | 'Overview'
  | 'Oblique'
  | 'Perimeter'
  | 'Wiring'
  | 'CABLines'
  | 'IIMode'
  | 'Custom'
  | 'Airplane'
  | FLIGHT_MODE.GROUND_ROBOT
  | FLIGHT_MODE.ORTHO
  | FLIGHT_MODE.SQUARE_ORBITAL;

export type OverlapModeType = 'Overlap' | 'Spacing';

export type UserUnitType = 'Metric' | 'Imperial';

export type SpeedControlModeType = 'Camera Interval' | 'Flight Speed';

export type WaypointTurnModes =
  | 'coordinateTurn'
  | 'toPointAndStopWithDiscontinuityCurvature'
  | 'toPointAndStopWithContinuityCurvature'
  | 'toPointAndPassWithContinuityCurvature';

export type ActionTriggerType = 'multipleTiming' | 'multipleDistance';

export type User = {
  id: number;
  email: string;
  orgs: Org[];
  active: boolean;
  latest_org_id: number;
  name: string;
  role: string;
  roles: [string];
  token: string;
};

export type FileType = 'KMZ' | 'KML';

export enum CameraAngleMode {
  Manual = 'manual',
  FollowTracker = 'follow_tracker',
}

export interface MapContextValue {
  center: PointType;
  currentLayers: [];
  sliderValue: number;
  zoom: number;
  initialized: boolean;
}

export interface MapContextProviderProps {
  children: ReactNode;
}

export interface MissionFileType {
  mission: MissionType;
  version: string;
  waypoints: FeatureCollection;
}

export interface MissionType {
  // primarily the form
  // all units saved in metric / SI
  missionName?: string;
  altitude: number; // meters
  safeTakeoffAltitude?: number; // meters
  altitudeOffset?: number; // meters - added v2.1.0 optional
  altitudeOffsetBool?: boolean; // added v2.1.0 optional
  cameraAngle: number; // -180 to 180 deg (with 0 being horizon, -90 being nadir)
  cameraInterval: number; // seconds
  cameraAngleMode: CameraAngleMode; // manual or follow_tracker
  drone: DroneType;
  flightAngle: number; // 0 to 360 deg (heading)
  flightMap: Feature<Polygon>; // polygon or perimeter
  flightMode: FlightModeType;
  flightSpeed: number; // meters/second
  frontOverlap: number;
  frontSpacing: number;
  mapCenter: PointType;
  mapZoom: number; // 0 to 23?
  overlapMode: OverlapModeType;
  sensor: SensorType; // currently CameraType
  sideOverlap: number;
  sideSpacing: number;
  solarFarm: SolarFarmType;
  speedControlMode: SpeedControlModeType;
  takeOffPointCenter: PointType | null; //
  terrainFollowBool: boolean;
  userUnits: UserUnitType; //imperial or metric
}

export interface PointType {
  lat: number;
  lng: number;
}

export interface SensorType {
  fieldOfViewWidth: number; // currently fov horizontal
  fieldOfViewHeight: number; // currently fov vertical
  id?: number; // from database
  name: CameraType; // 'M3T RGB Wide'
}

export interface SolarFarmType {
  // look similar to plant-labeler solar_farm table
  geom?: PointType;
  id?: number;
  name: string;
}

export interface CalibrationObject {
  active: boolean;
  rhumbBearing: number;
  rhumbDistance: number;
  nonCalibratedFlightPath?: Feature;
}

export interface IntervalometerObject {
  intervalometerBool: boolean;
  waypointReductionBool: boolean;
  actionTriggerParam: number;
}

export interface AltitudeOffsetObject {
  offset: number;
  active: boolean;
}

export interface IntervalModeObject {
  active: boolean;
  actionTriggerType: ActionTriggerType;
  actionTriggerParam: number; // seconds or meters depending on trigger type
}

// anything not defined in SolarFarm.ts is given a type of undefined
export interface SolarFarm {
  accessCode?: string;
  address?: string;
  clientOrgId?: number;
  commissioningTsecs?: number;
  createdTsecs?: number;
  creatorId?: number;
  features?: undefined;
  id: number;
  inspections?: undefined;
  latitude: number;
  longitude: number;
  moduleLayout?: string;
  moduleMount?: string;
  name: string;
  orgId?: number;
  permittance?: undefined;
  rowChildProcessStatus?: undefined;
  siteLayout?: string;
  size?: number;
  status?: number;
  tileMaps?: undefined;
  updatedTsecs?: number;
  uuid?: string;
  version?: undefined;
}

export interface FlightPlanningFormikValues {
  missionName: string;
  drone: DroneType;
  cameraType: CameraType;
  userUnits: UserUnitType;
  altitude: number;
  altitudeOffset: number;
  altitudeOffsetBool: boolean;
  safeTakeoffAltitude?: number;
  safeTakeoffAltitudeBool: boolean;
  fieldOfViewHorizontal: number;
  fieldOfViewVertical: number;
  frontOverlap: number;
  sideOverlap: number;
  cameraAngle: number;
  flightAngle: number;
  speedControlMode: SpeedControlModeType;
  cameraInterval: number;
  flightSpeed: number;
  terrainFollowBool: boolean;
  takeOffPointCenter?: number[];
  flightMode?: FlightModeType;
  cameraAngleMode: CameraAngleMode;
}

export interface FormParameters {
  altitudeInput: {
    min: number;
    max: number;
  };
  safeTakeoffAltitudeInput: {
    min: number;
    max: number;
  };
  altitudeOffset: {
    min: number;
    max: number;
  };
  frontOverlapInput: {
    min: number;
    max: number;
  };
  sideOverlapInput: {
    min: number;
    max: number;
  };
  fieldOfViewHorizontalInput: {
    min: number;
    max: number;
  };
  fieldOfViewVerticalInput: {
    min: number;
    max: number;
  };
  pitchAngleInput: {
    min: number;
    max: number;
  };
  flightAngleInput: {
    min: number;
    max: number;
  };
  flightSpeedInput: {
    min: number;
    max: number;
  };
  cameraIntervalInput: {
    min: number;
    max: number;
  };
}

declare global {
  interface Window {
    REACT_APP_AUTH0_DOMAIN: string;
    REACT_APP_AUTH0_CLIENTID: string;
    REACT_APP_FARMBUILDER_API_ENDPOINT: string;
    REACT_APP_CUSTOMER_API_ENDPOINT: string;
    REACT_APP_FLIGHT_API_ENDPOINT: string;
    REACT_APP_MIXPANEL_API_KEY?: string;
    REACT_APP_GROWTHBOOK_API_KEY: string;
    REACT_APP_GROWTHBOOK_DECRYPT_KEY: string;
    REACT_APP_ENVIRONMENT: string;
    DD_RUM_ENABLED: boolean;
    DD_RUM_SAMPLE_RATE: number;
    DD_RUM_SAMPLE_RECORD_RATE: number;
    REACT_APP_AUTH0_CONFIG: {
      audience: string;
    };
    REACT_APP_PLANTLABELER_API_ENDPOINT: string;
    DOLBY_LIVE_STREAM_ACCOUNT_ID: string;
    DOLBY_DOCK_1_AUBIN_FARMS_STREAM_NAME: string;
    DOLBY_DOCK_1_AUBIN_FARMS_SUBSCRIBE_TOKEN: string;
  }
}
