import styled from 'styled-components';

import { Checkbox } from '@raptormaps/checkbox';
import { Text } from '@raptormaps/text';

export const LargeCheckbox = styled(Checkbox)`
  height: 20px;
  width: 20px;
`;

export const InlineCheckboxText = styled(Text)`
  height: 20px;
  line-height: 28px;
  margin: 0 0 -15px -10px;
`;
